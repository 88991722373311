import { NavLink } from "react-router-dom";
import breadCumbStyle from "./breadcumb.module.css";
const BreadCumb = ({ data }) => {
  return (
    <div className={`${breadCumbStyle.breamcumbContainer}`}>
	  <ul className={breadCumbStyle.headerBreadcrumb}>
		<li><NavLink to="/"> Home <span>{" > "}</span></NavLink></li>
		<li><NavLink to="/"> {" "}{data} </NavLink></li>
	  </ul>
    </div>
  );
};

export default BreadCumb;
