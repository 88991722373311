import React from "react";
import categoryStyle from "./shopCategory.module.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper";
import { GiJeweledChalice } from "react-icons/gi";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { NavLink } from "react-router-dom";
import { getTemplates } from "../../../../../hooks/templates";
import { useState } from "react";

const ShopCategories = ({ loading, categories, setSearchData }) => {
	const [isHover, setIsHover] = useState(false);
	const handleMouseEnter = () => {
		setIsHover(true);
	 };
	 const handleMouseLeave = () => {
		setIsHover(false);
	 };

  return (
	<div className="container">
	<div className={categoryStyle.titles}>
		<div>
		<h1>Categories</h1>
		</div>
		<div className={categoryStyle.search}>
		<input
			placeholder="Search in Shop"
			type="text"
			onChange={(e) => setSearchData(e.target.value)}
		/>{" "}
		<button  className="btn btn-outline-primary"
			style={{
				backgroundColor: isHover ? '#000' : getTemplates().primary_color,
				borderColor: isHover ? '#000' : '#fff',
				color:'#fff'
			}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			search
		</button>
		</div>
	</div>
	<section className="my-5">
		{loading ? (
		<SkeletonTheme height={50}>
			<p>
			<Skeleton count={5} />
			</p>
		</SkeletonTheme>
		) : (
		<Swiper
			spaceBetween={30}
			centeredSlides={true}
			slidesPerView={1}
			breakpoints={{
			960: {
				slidesPerView: 5,
			},
			768: {
				slidesPerView: 4,
			},
			650: {
				slidesPerView: 3,
			},
			550: {
				slidesPerView: 2,
			},
			450: {
				slidesPerView: 1,
			},
			}}
			autoplay={{
			delay: 2000,
			disableOnInteraction: false,
			}}
			// pagination={{
			//   clickable: true,
			// }}
			loop={true}
			loopFillGroupWithBlank={true}
			navigation={true}
			modules={[Autoplay, Pagination, Navigation]}
			className={`mySwiper py-3 ${categoryStyle.slideContainer}`}
		>
			{categories?.map((item, index) => {
			return (
				<SwiperSlide key={index}>
				<NavLink to={`/shop/${item.slug}`}>
					<div className={categoryStyle.slideItem}>
					<img src={item.banner} alt={item.name} />
					</div>
					<h5>{item.name}</h5>
				</NavLink>
				</SwiperSlide>
			);
			})}
		</Swiper>
		)}
	</section>
	</div>
  );
};

export default ShopCategories;
