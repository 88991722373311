import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "./miniCart.scss";
import { BsCart2, BsChevronRight } from "react-icons/bs";
import { setMiniCart } from "../../redux/slices/miniCart";
import { useCart } from "react-use-cart";
import { GrClose } from "react-icons/gr";
import ProdQty from "./ProdQty";
import Variations from "./Variations";
import CartTotal from "../../pages/customer/cart/components/cartCom/CartTotal";
import { priceFormat } from "../../hooks/helper";
import { NavLink } from "react-router-dom";

const MiniCart = () => {
  const { t,i18n } = useTranslation();
  const dispatch = useDispatch();

  const close = (e) => {
    e.stopPropagation();
    dispatch(setMiniCart({ open: false }));
  };

  const {
    isEmpty,
    totalUniqueItems,
    items,
    cartTotal,
    updateItemQuantity,
    removeItem,
    metadata,
  } = useCart();
  return (
    <div className="mini-cart">
      <div className="mini-cart-outer-container" onClick={close}>
        <div
          className="mini-cart-container"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="mini-cart-inner-container">
            <div className="cart-header">{t('my_cart')}</div>
            {items?.length ? (
              <>
                <div className="mini-cart-scrollable">
                  {items?.map((prod, key) => (
                    <div className="single-mini-cart-item" key={key}>
                      <div className="smci-img">
                        <img src={prod.thumbnail_img} alt={prod.name} />
                      </div>
                      <div className="smci-right">
                        <div className="smci-min-height">
                          <div className="smcir-top">
                            <div className="smcir-name">{prod.name}</div>
                            <div
                              className="smcir-delete"
                              onClick={() => removeItem(prod.id)}
                            >
                              <GrClose />
                            </div>
                          </div>
                          {prod.product_type == "variation" && (
                            <Variations item={prod} />
                          )}
                          {
                            process.env.REACT_APP_MARKETPLACE_TYPE == "marketplace" &&
                            <i className="shop-name">{prod.shop_name}</i>
                          }
                        </div>
                        <div className="smcir-bottom">
                          <div className="smcir-qty">
                            <ProdQty prod={prod} /> <div>x {priceFormat(prod.price,i18n.language)} </div>
                          </div>
                          <div className="smcir-item-total">
                            {priceFormat(prod.itemTotal,i18n.language)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mini-cart-prices">
                  <CartTotal proceedKey={"cart"} cartTotal={cartTotal} cartTotalTitle="miniCart" />
                </div>
              </>
            ) : (
              <div className="empty-mini-cart-container">
                <BsCart2 />
                <div className="emc-desc">{t('no_product_cart')}</div>
                <NavLink to="/shop" className="emc-btn" onClick={close}>
                  {t('return_shop')}
                </NavLink>
              </div>
            )}

            <div className="mini-cart-close" onClick={close}>
              <BsChevronRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniCart;
