import { NavLink } from "react-router-dom";
import "./../style.scss";

const Header = ({title,url}) => {
	return (
		<div className="row">
			<div className="col">
				<h2 className="content-title">{title}</h2>
			</div>
			<div className="col">
				<div className="view-all float-end">
					<NavLink to={url}>View All</NavLink>
					<i className="fa fa-angle-right" aria-hidden="true"></i>
				</div>
			</div>
		</div>
	);
};

export default Header;
