import { useTranslation } from "react-i18next";

const PageHeader = () => {
  const { t,i18n } = useTranslation();
  
  return (
    <div className="row mt-4 mb-5">
      <h1 className="text-center">{t('shopping_cart')}</h1>
    </div>
  );
};

export default PageHeader;
