import { Fragment, useEffect,useState } from "react";
import { markutosFrontendApi } from "../../../../services/Api/api";
import ProductSearch from "./components/ProductSearch";
import Categories from "./components/Categories";
import Brands from "./components/Brands";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Attributes from "./components/Attributes";

const LeftComp = ({setProductSearch,setCategoryId,setBrandId,setAttributesId,setVariations}) => {
	const [loading, setLoading] = useState(false);
	const [allData, setData] = useState([]);

	const getData=()=>{
		var item_value = sessionStorage.getItem("MARCATUEX_SHOP_LEFT_SIDE");
		if ( item_value !== null ) {
			const temp = JSON.parse(sessionStorage.getItem("MARCATUEX_SHOP_LEFT_SIDE") ) || [] ;
			setData(temp);
		} else {
			setLoading(true)
			markutosFrontendApi
			.get(`/shop/sidebar`)
			.then((response) => {
				console.log(response?.data);
				sessionStorage.setItem("MARCATUEX_SHOP_LEFT_SIDE",JSON.stringify(response?.data));
				setData(response?.data);
				setLoading(false);
			});
		}
	}
	
	useEffect(() => {
		getData();
	}, []);

	return (
		<div className="shop-sidebar">
			{/* Product Search */}
			<ProductSearch setProductSearch={setProductSearch}/>
        		{/* Over All Reviews */}
				{/* <Reviews/> */}
				{ loading ? (
				<SkeletonTheme height={50}>
					<p><Skeleton count={5} /></p>
				</SkeletonTheme>
				) : 
				(
					<Fragment>
						{/* Category Section */}
						<Categories categories={allData?.categories} setCategoryId={setCategoryId}/>
						{/* Brand section */}
						<Brands brands={allData?.brands} setBrandId={setBrandId} />
						{/* Attributes */}
						{
							allData?.choice_options &&
							(
								<Attributes 
									attributes={allData?.choice_options}
									setAttributesId={setAttributesId} 
									setVariations={setVariations} 
								/>
							)
						}
						
					</Fragment>
				)
        	}
		</div>
	);
};

export default LeftComp;
