import { useState, useEffect } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { markutosFrontendApi } from "../../../../services/Api/api";
import GridView from "../../../../../common/product/prodsLayout/GridView";
import { useTranslation } from "react-i18next";
import GridOne from "../../../../../common/product/prodsCard/GridOne";

const OrderBump = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const getData=()=>{
    var item_value = sessionStorage.getItem("MARCATUEX_ORDER_BUMP");
    if ( item_value !== null ) {
      const temp = JSON.parse(sessionStorage.getItem("MARCATUEX_ORDER_BUMP") ) || [] ;
      setProducts(temp);
    } else {
      setLoading(true);
      markutosFrontendApi.get(`/cart/you-can-also-buy`).then((response) => {
        setLoading(false);
        sessionStorage.setItem("MARCATUEX_ORDER_BUMP",JSON.stringify(response?.data));
        setProducts(response?.data);
      });
    }

  }

	useEffect(() => {
		window.onload = function() {
		sessionStorage.removeItem("MARCATUEX_ORDER_BUMP");
		};
		getData();
	}, []);

  	function displayComponent() {
	if (process.env.REACT_APP_MARKETPLACE_TEMPLATE == 1) {
		{
			return products &&
			products?.map((item,key)=>{
				return <div className="col-lg-3 col-sm-12" key={key}><GridOne product={item}/></div>
			})
		}
	}
	else{
		return loading === false ? (
			<GridView products={products} />
		  ) : (
			<SkeletonTheme height={50}>
			  <p>
				<Skeleton count={5} />
			  </p>
			</SkeletonTheme>
		)
	}
	}  

  return (
    <div className="order-bump">
      <h2 className="my-4">{t('also_buy')}</h2>
      <div className="row products-wrap">
        {displayComponent()}
      </div>
    </div>
  );
};

export default OrderBump;
