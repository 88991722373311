import React from "react";
import ReceivedRefunds from "./components/ReceivedRefunds";
const ReceivedRefundReq = () => {
  return (
    <div>
      <ReceivedRefunds />
    </div>
  );
};

export default ReceivedRefundReq;
