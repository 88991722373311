import { useEffect, useState } from "react";
import { BsArrowRightCircle } from "react-icons/bs";
import { markutosFrontendApi } from "../../../services/Api/api";
import customerAuthHeader from "../../../services/customer-auth-header";
import profileStyle from "./profile.module.css";
import { FocusError } from "focus-formik-error";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { addProfileSchema } from "../../../../schema/addProfileSchema";
import DatePicker from "react-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { getTemplates,bg_Hover } from "../../../../hooks/templates";

const Profile = () => {
  const { t } = useTranslation();
  const [profile, setProfile] = useState([]);


  const getProfile = () => {
    markutosFrontendApi
      .get(`dashboard/profile`, {
        headers: {
          Authorization: customerAuthHeader(),
        },
      })
      .then((res) => {
        setProfile(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    getProfile();
  }, []);

  const formik = useFormik({
    validationSchema: addProfileSchema,
    initialValues: {
      first_name: profile?.first_name || "",
      last_name: profile?.last_name || "",
      email: profile?.email || "",
      phone: profile?.phone || "",
      address: profile?.address || "",
      birth_date: profile?.birth_date || "",
      password: profile?.password || "",
      confirm_password: profile?.confirm_password || "",
    },
    enableReinitialize: true,
    onSubmit: (values, action) => {
      const finalValues = values;
      markutosFrontendApi
        .post("/dashboard/profile/update", finalValues, {
          headers: {
            Authorization: customerAuthHeader(),
          },
        })
        .then((res) => {
          toast.success(res.data.message);
          action.resetForm();
          getProfile();
        })
        .catch((e) => {
          toast.error(e.message);
        });
    },
  });

  const {
    values,
    setErrors,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = formik;

  return (
    <>
      <h4>Profile</h4>
      <form onSubmit={(e) => e.preventDefault()}>
        <FocusError formik={formik} />
        <div className={profileStyle.profileContainer}>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor=""> {t('first_name')}: </label>
              <input
                type="text"
                name="first_name"
                value={values.first_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.first_name && touched.first_name && (
                <small className="text-danger"> {errors.first_name} </small>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor=""> {t('last_name')}: </label>
              <input
                type="text"
                name="last_name"
                value={values.last_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.last_name && touched.last_name && (
                <small className="text-danger"> {errors.last_name} </small>
              )}
            </div>
          </div>
          <div>
            <label htmlFor="email"> {t('email')}: </label>
            <input
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.email && touched.email && (
              <small className="text-danger"> {errors.email} </small>
            )}
          </div>

          <div className="row">
            <div className="col-md-6">
              <label htmlFor=""> {t('phone')} </label>
              <input
                type="tel"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.phone && touched.phone && (
                <small className="text-danger"> {errors.phone} </small>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="birth_date"> {t('dob')}:</label>
              <DatePicker
                name={"birth_date"}
                className={profileStyle.reactDatePickerContainer}
                dateFormat="MMMM d, yyyy"
                value={values.birth_date}
                onChange={(date) => setFieldValue("birth_date", date)}
              />
            </div>
          </div>

          <div>
            <label htmlFor=""> {t('address')}: </label>
            <textarea
              cols={"5"}
              rows={"5"}
              name="address"
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
            ></textarea>

            {errors.address && touched.address && (
              <small className="text-danger"> {errors.address}: </small>
            )}
          </div>

          <div>
            <label htmlFor=""> {t('new')} {t('password')}:  </label>
            <input
              type="text"
              name="password"
              placeholder="New Password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          <label htmlFor=""> {t('conf_pass')}: </label>
          <input
            type="text"
            name="confirm_password"
            placeholder="Password Confirmation"
            value={values.confirm_password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary mt-2"
          onClick={handleSubmit}
          style={{
            "--bs-btn-bg" :  getTemplates().primary_color,
            "--bs-btn-hover-bg" :  bg_Hover(),
            "--bs-btn-border-color" :  getTemplates().primary_color,
          }}
        >
          {" "} {t('submit')} <BsArrowRightCircle />
        </button>
      </form>
    </>
  );
};

export default Profile;
