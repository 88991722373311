import { Fragment,useState,useEffect } from "react";

const Variation = ({colors,choseOptions,singleProduct,setVariantSlug,variantSlug}) => {
	const [activeClass, setActiveClass] = useState("");
	const [selectVariant, setSelectVariant] = useState( [] );
	const [variantPrice, setVariantPrice] = useState('');

	useEffect(() => {
		// combination of variation
		if ( selectVariant?.length>0 ) {
			getFinalVariation(selectVariant);
		}else{
			const firstSelect = choseOptions?.map((item,key)=>{
				return	{variation:item?.values[0],index:0,variant_index:0}	
			});
			getFinalVariation(firstSelect);

		}
	}, [selectVariant]);

	/**
	 * Sort and match the slug
	 * @param {*} selectVariant 
	 */
	const getFinalVariation=(selectVariant)=>{
		let getVariant= selectVariant.map((variant,key)=>{
			// var dash =`${selectVariant.length !== key+1 ? '-' : ''}`
			return `${variant.variation}`
		}).join("-")

		// get price
		if (getVariant !== "") {
			const found = singleProduct.variations.find(element => element.variant_slug == getVariant );
			if (found) {
				setVariantSlug(getVariant);
				singleProduct.price = found.price;
				setVariantPrice(found.price);
			}
		}
		singleProduct.selectedVariant = selectVariant;
		singleProduct.selectedVariantSlug = getVariant;
		// sort by index
		selectVariant.sort((a, b) => parseFloat(a.index) - parseFloat(b.index));
		// set for active class
		// let new_arr = [];
		// selectVariant.map((item, key) => {
		//   new_arr[item.index] = item;
		// });
		setActiveClass(selectVariant);
	}

	const getVariation=(attribute,newVariant,index,variant_index)=>{
		if (selectVariant.find((item) => item.attribute === attribute) !== undefined ) {
			// item exist
			let filteredVariant = selectVariant.filter(item => item.attribute !== attribute);
			setSelectVariant([...filteredVariant, {attribute:attribute,variation:newVariant,index:index,variant_index:variant_index}])
		} else {
			setSelectVariant([...selectVariant, {attribute:attribute,variation:newVariant,index:index,variant_index:variant_index}])
		}
	}

  return (
		<Fragment>
			{/* <div className="product-meta radio-wrap color-wrap d-inline-flex align-items-center">
				{ colors.length > 0 && (
					<Fragment>
						<span className="label">Colors : </span>
						{
							colors.map((item,key)=>{
								return(
									<div className="radio-item" key={key}>
										<input type="radio" name="color" value={item.name} id={item.name} onChange={(e)=>(getVariation('Colors',e.target.value,0))}/>
										<label htmlFor={item.name} style={{ backgroundColor: item.code }}></label>
									</div>
								)
							})
						}
					</Fragment>
				)}
			</div> */}
			<div className="product-meta radio-wrap d-inline-flex align-items-center">
				{ choseOptions?.length > 0 && (
					<Fragment>
						{
							choseOptions?.map((item,key)=>{
								return(
								<div key={key}>
									<span className="label">{item?.attribute_name} : </span>
									{
										item?.values?.map((variant,i)=>{
											return(
												<div className="radio-item" key={i}>
													<input type="radio"
														className={`${
																i === activeClass[ key ]?.variant_index ?
																"variation-active" : ""
														}`} 
														name={item?.attribute_name}
														value={variant}
														id={variant}
														onChange={(e)=>(getVariation(item,e.target.value,key,i))}
													/>
													<label 
													htmlFor={variant}
													className={`${
														i === activeClass[ key ]?.variant_index ?
														"variation-active" : ""
													}`} 
													>{variant}</label>
												</div>
											)
										})
									}
								</div>
								)
							})
						}
					</Fragment>
				)}
			</div>

			{ variantPrice !== "" && <div className=""> {variantPrice} </div>}

		</Fragment>
  );
};

export default Variation;
