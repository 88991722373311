import React, { Fragment } from "react";
import desStyle from "./productDes.module.css";
import SimpleLoading from "../../../../../common/loading/SimpleLoading";
import { useTranslation } from "react-i18next";

const ProductDescription = ({loading,singleProduct}) => {
	const { t } = useTranslation();

	return (
		<div className={desStyle.desContainer}>
		{
		loading ? <SimpleLoading/> :
		(
			<Fragment>
				<h2 className="fw-bold">{t('description')}:</h2>
				<div className={desStyle.image}>
					<img src={singleProduct.thumbnail_img} alt={singleProduct.name} />
				</div>
				<div>
					<p>{singleProduct.description}</p>
				</div>
			</Fragment>
		)
		}
		</div>
	);
};

export default ProductDescription;
