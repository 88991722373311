import "./productDescription.css";
import { useTranslation } from "react-i18next";

const ProductDescriptionDigital = ({
  values,
  handleBlur,
  handleChange,
  errors,
  touched,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  return (
    <div className="add-product-single-widget">
      <div className="widget-title">{ t('product') +` `+t('description')}</div>
      <div className="widget-content-container">
        <div className="ap-single-content-des">
          <div>
            <textarea
              name="description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              cols="30"
              rows="8"
            ></textarea>

            {errors.description && touched.description && (
              <small className="text-danger"> {errors.description} </small>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDescriptionDigital;
