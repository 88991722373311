import "./sellerOfMonth.scss";
import ProductsBlock from "./ProductsBlock";
import ShopButton from "../partialComponents/ShopButton";

const SellerOfMonth = ({featureProducts}) => {
	return (
		<section className="content-block">
			<div className="row text-center mb-20">
				<h2 className="content-title">Seller of the Month</h2>
			</div>
			<ProductsBlock featureProducts={featureProducts} />
			<ShopButton url={`/shop/${featureProducts['all']?.category_slug}`}/>
		</section>
	);
};

export default SellerOfMonth;
