import { useTranslation } from "react-i18next";
import { getTemplates } from "../../../../../../hooks/templates";
import { useState } from "react";

const Brands = ({brands,setBrandId}) => {
    const { t } = useTranslation();
	const [isHover, setIsHover] = useState(-1);
	const handleMouseEnter = (key) => {
		setIsHover(key);
	 };
	 const handleMouseLeave = () => {
		setIsHover(-1);
	 };
	return (
        <div className="sidebar-row categories-wrap">
        <h4 className="sidebar-label">{t('brands')}</h4>
        {	
             brands?.map((brand,key)=>
                <ul key={key}>
                    <li onClick={(e)=>setBrandId(brand.value)}
					style={{
						color: isHover == key ?  getTemplates().primary_color : "#000",
					}}
					onMouseEnter={(e)=>handleMouseEnter(key)}
					onMouseLeave={handleMouseLeave}
					>{brand.label}</li>
                </ul>	
            )
        }
        </div>
	);
};

export default Brands;
