const HtmlMarkup = ({iconData}) => {
    const rawMarkup=()=>{
        return { __html: iconData };
    }
    return(
        <span dangerouslySetInnerHTML={rawMarkup()}>
        </span>
    )
}
export default HtmlMarkup;
