import { NavLink } from "react-router-dom";

const SocialLink=({footerData})=>{
	return(
		<div className="social-wrapper">
		<p className="social-title">Follow Us</p>
		<ul className="social-icons">
			<li>
				<a href={`https://${footerData.facebook ? footerData.facebook : "#"}`} target="_blank" className="facebook">
					<i className="fa fa-facebook"></i>
				</a>
			</li>
			<li>
				<a href={`https://${footerData.twitter ? footerData.twitter : "#"}`} target="_blank" className="twitter">
					<i className="fa fa-twitter"></i>
				</a>
			</li>
			<li>
				<a href={`https://${footerData.instagram ? footerData.instagram : "#"}`} target="_blank" className="instagram">
					<i className="fa fa-instagram"></i>
				</a>
			</li>
			<li>
				<a href={`https://${footerData.linkedin ? footerData.linkedin : "#"}`} target="_blank" className="linkedin">
					<i className="fa fa-linkedin"></i>
				</a>
			</li>
		</ul>
	</div>
	);
}
export default SocialLink;