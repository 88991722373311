import Header from "./Header";
import "./bestSellingProducts.css";
import ProductsBlock from "./ProductsBlock";

const BestSellingProducts = ({bannerOne,popularProducts}) => {
	return (
		<section className="content-block">
			<Header title={"Best Selling Product"} url={`/shop/${popularProducts['all']?.category_slug}`}/>
			<ProductsBlock bannerOne={bannerOne} popularProducts={popularProducts} />
		</section>
	);
};

export default BestSellingProducts;
