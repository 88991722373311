
import {
	PaymentElement,
	CardElement,
	Elements,
	useStripe,
	useElements,
} from '@stripe/react-stripe-js';

const Stripe = () => {
	const options = {
		style: {
		  base: {
			color: "#32325d",
			fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			fontSmoothing: "antialiased",
			fontSize: "16px",
			"::placeholder": {
			  color: "#aab7c4"
			}
		  },
		  invalid: {
			color: "#fa755a",
			iconColor: "#fa755a"
		  }
		}
	  };

  return (
    <div className='stripe-form'>
		<CardElement options={options} />
    </div>
  );
};

export default Stripe;
