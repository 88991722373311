import { useEffect, useState } from "react";
import "./ProductVariation.css";
import Select from "react-select";
import ReactTagInput from "@pathofdev/react-tag-input";
import UploadFilesModal from "../../../../../UploadFiles/UploadFilesModal";
import { markutosSellerApi } from "../../../../../../services/Api/api";
import { useTranslation } from "react-i18next";
import { getVariation } from "../../../../../../../hooks/helper";

const ProductVariation = ({
  values,
  handleBlur,
  handleChange,
  errors,
  touched,
  setFieldValue,
  selectedAttr,
  attrValues,
  selectedVariations,
  selectedFinalVariants,
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  // set attribute options
  const [attributesOptions, setAttributeOptions] = useState([]);
  // set selected attr
  const [selectedAttribute, setSelectedAttribute] = useState(selectedAttr || []);
  // set selected variant values
  const [attributeValues, setAttributeValues] = useState(attrValues || []);
  // variant slug
  const [variations, setVariations] = useState(selectedVariations || []);
  // set all variant with price / quantity
  const [finalVariants, setFinalVariants] = useState( selectedFinalVariants || []);

  /**
   * Generate price/quantity
   * @param {*} filterAttrValues 
   */
  const generateVariations=(filterAttrValues)=>{
    var attributesArray = [];

    filterAttrValues.map((item)=>{
      var key_name = Object.keys(item)[0];
      attributesArray.push(item[key_name]);
    })

    let final = [];
    if (attributesArray[0]?.length > 0 && attributesArray[1]?.length <= 0) {
      for (let i = 0; i < attributesArray[0].length; i++) {
        final.push(attributesArray[0][i]);
      }
    }
    else if (
      attributesArray[1]?.length > 0 &&
      attributesArray[0]?.length <= 0
    ) 
    {
      for (let i = 0; i < attributesArray[1]?.length; i++) {
        final.push(attributesArray[1][i]);
      }
    } 
    else {
      final = getVariation(attributesArray);
    }

	// set choose options , choice_no
	var choice_options = [];
	selectedAttribute.map((item,key)=>{
		let obj = {
			"attribute_id":item.value,
			"attribute_name":item.label,
		};
		if ( filterAttrValues && filterAttrValues[key]) {
			obj['values'] = filterAttrValues[key][item.label]
		}
		choice_options.push(obj);
		return item.value
	});

	setFieldValue('choice_options',choice_options);
	setFieldValue('choice_no',selectedAttribute);

    var newVariations = [];
    final?.map((varr,key) => {
      newVariations.push({ sku: '',variant:varr,price:'',quantity:'',image:''})
    });

    // set price,quantity,image
    setFinalVariants(newVariations);
    // set variation slug
    setVariations(final);
    setFieldValue('variations',final);
    setFieldValue('variants',newVariations);
  }

  /**
   * On Edit for set attribute
   */
  useEffect(() => {
    markutosSellerApi()
      .get(`/get-attributes`)
      .then((response) => {
        setAttributeOptions(response?.data);
      });
  }, []);

  useEffect(() => {
    if ( attrValues ) {
      setAttributeValues(attrValues);
    }
    if (selectedAttr) {
      setSelectedAttribute(selectedAttr);
    }

    setFinalVariants(selectedFinalVariants);
    setVariations(selectedVariations);

  }, [selectedAttr,attrValues]);

  /**
   * On change attribute
   * @param {*} att 
   */

  const handleAttributesChange = (att) => {
    const filterAttrValues = [];
	if ( attrValues ) {
	    att.map((item,key) => {
			attributeValues?.filter((object) => {
				if ( Object.keys(object)[0] == item.label ) {
				filterAttrValues.push(object);
				}
				return Object.keys(object)[0] == item.label;
			});
		  });	
	}else{
		att.map((item,key) => {
			filterAttrValues.push({[item.label] : [] });
		});
	}

    setAttributeValues([...filterAttrValues]);

    setSelectedAttribute(att);

    // generate variant,variation
    generateVariations(filterAttrValues);

  };

  /**
   * On change attribute variant value
   * @param {*} newTags 
   * @param {*} item 
   */
  const setAttributeSingleValue = (newTags, item , key ) => {
    const tempAttrValues = attributeValues;
    tempAttrValues[key][item] = newTags;
    setAttributeValues([...tempAttrValues]);
    generateVariations(attributeValues);
  };

  /**
   * On price , quantity and image change
   * @param {*} e 
   * @param {*} variant 
   * @param {*} which 
   * @param {*} key 
   */
  const handleVariantValue = (e, variant, which , key ) => {
      finalVariants[key][which] = e.target.value;
      setFinalVariants([...finalVariants])
  };

  return (
    <>
      {values.product_type == "variation" && (
        <section>
          <div className="add-product-single-widget">
            <div className="widget-title">{t('product') +` `+t('variation')} </div>
            <div className="widget-content-container">
              <section>
                <div className="ap-single-content mb-3">
                  <p> {t('attributes')} </p>
                  <Select
                    onChange={(e) => handleAttributesChange(e)}
                    isMulti
                    name="colors"
                    options={attributesOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={selectedAttribute}
                  />
                </div>
              </section>

              <section>
                {/* Set Variant for attributes */}
                {selectedAttribute?.map((item,key) => {
                  return (
                    <div key={key} className="ap-single-content mb-3">
                      <p> {item?.label} </p>
                      <ReactTagInput
                        tags={(  attributeValues?.length>0 && attributeValues[key]  ) ? attributeValues[key][item?.label] : []}
                        removeOnBackspace={true}
                        onChange={
                          (newTags) => setAttributeSingleValue(newTags, item?.label , key )
                        }
                        handleDelete={
                          (newTags) => setAttributeSingleValue(newTags, item?.label , key )
                        }
                      />
                    </div>
                  );
                })}
              </section>

              <section>
                {variations?.map((varr,key) => {
                  return (
                    <div key={key}>
                      <div className="ap-single-content mb-3">
                        <p> {varr}-{t('price')}</p>
                        <input
                          onChange={(e) => handleVariantValue(e, varr, "price" , key )}
                          type="number"
                          min={1}
                          value={ (finalVariants && finalVariants[key]['price'] ) ? finalVariants[key]['price'] : ""}
                        />
                      </div>

                      <div className="ap-single-content mb-3">
                        <p> {varr}-{t('quantity')} </p>
                        <input
                          onChange={(e) =>
                            handleVariantValue(e, varr, "quantity" , key )
                          }
                          type="number"
                          min={1}
                          value={ ( finalVariants && finalVariants[key]['quantity']) ? finalVariants[key]['quantity'] : ""}
                        />
                      </div>

                      <div className="ap-single-content mb-3">
                        <div>
                          <p>{varr}-{t('image')}</p>
                        </div>
                        <div>
                          <div
                            onClick={() => setShow(!show)}
                            className="custom-browse"
                          >
                            <div>{t('browse')}</div>
                            <div>
                              {/* {values?.pdf ? values?.pdf : "Choose File"} */}
                              {t('choose_file')}
                            </div>
                          </div>

                          <UploadFilesModal
                            setFieldValue={setFieldValue}
                            format="string"
                            values={values}
                            imageFor="pdf"
                            show={show}
                            setShow={setShow}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </section>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ProductVariation;
