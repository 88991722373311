import { NavLink } from "react-router-dom";
import { priceFormat } from "../../../../../hooks/helper";
import { MdOutlineViewInAr } from "react-icons/md";
import { useTranslation } from "react-i18next";

const SearchTemplate = ({ searchResult }) => {
  const { t,i18n } = useTranslation();
  return (
    <div className="search-template">
        {searchResult?.result?.map((prod, key) => (
          <div className="single_search_prod" key={key}>
            <div className="ssp-img">
              <img
                src={prod.thumbnail_img}
                alt={prod.name}
                height="50px"
                width={"50px"}
              />
            </div>
            <div>
              <p className="ssp-title">{prod.name}</p>
              <p className="ssp-price">{priceFormat(prod.price,i18n.language)}</p>
            </div>
          </div>
        ))}
        <div style={{ gridColumn: "1 / span 2" }}>
          {searchResult?.view_more === "true" && (
            <div className="d-flex justify-content-center my-2">
              <NavLink
                to={`/shop/${searchResult.category_slug}`}
                className="btn btn-primary "
              >
                <MdOutlineViewInAr /> {t('view_more')}
              </NavLink>
            </div>
          )}
        </div>
    </div>
  );
};

export default SearchTemplate;
