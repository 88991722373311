import React, { useState } from "react";
import upCSVstyle from "./uploadCSV.module.css";
import { toast } from "react-toastify";
import { markutosSellerApi } from "../../../../services/Api/api";
import { useTranslation } from "react-i18next";
const UploadCSV = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [file, setFile] = useState({});
  const [uplading, setUploading] = useState(false);
  const handleCsvUpload = () => {
    if (!file?.name) {
      toast.error(t('select_csv_file'), {
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "dark",
      });
    } else {
      const formData = new FormData();
      formData.append("file", file);
      setUploading(true);
      markutosSellerApi()
	  .post("/products/bulk-upload/post", formData )
        .then((res) => {
          toast.success(res.data, {
            autoClose: 2000,
          });
          setUploading(false);
        })
        .catch((err) => {
          toast.error(err.message);
          setUploading(false);
        });
    }
  };
  return (
    <div className={upCSVstyle.upContainer}>
      <h5>{t('upload_csv_file')} </h5>
      <div className={`${upCSVstyle.uploadCSVfile} ap-single-content`}>
        <div>
          <p>{t('add_csv')}</p>
        </div>
        <div>
          <div
            // onClick={() => setShow(!show)}
            className={`custom-browse ${upCSVstyle.csvFileInputContainer}`}
          >
            <div>{t('browse')}</div>
            <input
              onChange={(e) => setFile(e.target.files[0])}
              className={upCSVstyle.csvFileInput}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              type="file"
            />
            <div> {file.name ? file.name : t('choose_file') } </div>
          </div>
        </div>
      </div>
      <div className={upCSVstyle.submitbutton}>
        <button
          disabled={uplading}
          onClick={handleCsvUpload}
          className="btn btn-primary"
        >
          {uplading ? (
            <div>
              <div
                className="spinner-border spinner-border-sm me-1"
                role="status"
              >
                <span className="visually-hidden">{t('loading')}...</span>
              </div>
              {t('uploading_csv')}
            </div>
          ) : (
            t('upload_csv')
          )}
        </button>
      </div>
    </div>
  );
};

export default UploadCSV;
