
import { NavLink } from "react-router-dom";
import GridView from "../../../../../common/product/prodsLayout/GridView";
import { MdOutlineViewInAr } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { getTemplates,bg_Hover } from "../../../../../hooks/templates";

const RelatedProducts = ({ loading, singleProduct }) => {
	const { t } = useTranslation();
  return (
    <div>
      <div className="related_title text-center my-5">
        <h2 className="fw-bold">{t('related_product')}</h2>
      </div>
      <div className="row mb-4">
        {!loading && (
          <GridView
            products={singleProduct?.related_products?.products}
          />
        )}
      </div>
      <div className="d-flex justify-content-center my-5">
        <NavLink
          to={`/shop/${singleProduct?.related_products?.category_slug}`}
          className="btn btn-primary"
          style={{
            "--bs-btn-bg" :  getTemplates().primary_color,
            "--bs-btn-hover-bg" :  bg_Hover(),
            "--bs-btn-border-color" :  getTemplates().primary_color,
            "--bs-btn-active-bg" :  getTemplates().primary_color,
            "--bs-btn-active-border-color" :  getTemplates().primary_color,
            }}
        >
          <MdOutlineViewInAr /> {t('view_more')}
        </NavLink>
      </div>
    </div>
  );
};
export default RelatedProducts;
