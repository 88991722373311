import {useState,useEffect} from "react";
import supportStyle from "./supports.module.css";
import { BsEyeFill } from "react-icons/bs";
import "react-loading-skeleton/dist/skeleton.css";
import { markutosFrontendApi } from "../../../services/Api/api";
import customerAuthHeader from "../../../services/customer-auth-header";
import SimpleLoading from "../../../../common/loading/SimpleLoading";
import FrontendPagination from "../../../../common/pagination/frontend/FrontendPagination";
import SupportModal from "./components/supportModal/SupportModal";
import { useTranslation } from "react-i18next";

const Supports = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [currentTicketId, setCurrentTicketId] = useState(null);
	const [show, setShow] = useState(false);

	const modalOpen = (id) => {
		setCurrentTicketId(id);
		setShow(!show);
	};

	const getData=()=>{
		var item_value = sessionStorage.getItem("MARCATUEX_CLIENTS_SUPPORTS");
		if ( item_value !== null ) {
		  const temp = JSON.parse(sessionStorage.getItem("MARCATUEX_CLIENTS_SUPPORTS") ) || [] ;
		  setCurrentItems(temp?.data.data);
		  setCurrentPage(temp?.data?.current_page);
		  setPageCount(temp?.data?.last_page);
		} else {
		  setLoading(true);
		  markutosFrontendApi
			.get(`dashboard/support-tickets?per_page=10&page=${currentPage}`,
			  {
				  headers: {
					  Authorization: customerAuthHeader(),
				  },
			  }
			)
			.then((response) => {
				  setLoading(false);
				  sessionStorage.setItem("MARCATUEX_CLIENTS_SUPPORTS",JSON.stringify(response));
				  setCurrentItems(response?.data.data);
				  setCurrentPage(response?.data?.current_page);
				  setPageCount(response?.data?.last_page);
			  });
		}
	
	}
	
	useEffect(() => {
	window.onload = function() {
		sessionStorage.removeItem("MARCATUEX_CLIENTS_SUPPORTS");
	};
	getData();
	}, [currentPage]);

  return (
    <div>
      <h4>{t('supports')}</h4>
      <section className={` ${supportStyle.tableData}`}>
        <table>
          <thead>
            <tr>
              <th><small>{t('ticket_id')}</small></th>
              <th><small>{t('subject')}</small></th>
              <th><small>{t('status')}</small></th>
              <th><small>{t('last_update')} </small></th>
              <th className="text-center"><small>{t('actions')}</small></th>
            </tr>
          </thead>

          <tbody>
          {
          loading ? (<tr  className="w-100"><td colSpan={10}><SimpleLoading /></td></tr>) :
			(
				(
					currentItems.map((item,index)=>{
					return(
						<tr key={index}>
						<td><small>{item.code}</small></td>
						<td><small>{item.subject}</small></td>
						<td><small>{item.status}</small></td>
						<td><small>{item.updated_at}</small></td>
						<td className="text-center">
							<button className={'preview'} onClick={() => modalOpen(item.code)}>
								<BsEyeFill />
							</button>
						</td>
					</tr>
					)
					})
				)
			)
          }
          </tbody>
        </table>
		{currentItems.length > 0 && (
			<FrontendPagination
				currentItem={currentItems}
				perPage={10}
				pageCount={pageCount}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
			/>
		)}
		<SupportModal
		ticketId={currentTicketId}
		page="support"
		show={show}
		setShow={setShow}
        />
      </section>
    </div>
  );
};

export default Supports;
