import {useState,useEffect} from "react";
import orderStyle from "../orders/userOrder.module.css";
import "react-loading-skeleton/dist/skeleton.css";
import { markutosFrontendApi } from "../../../services/Api/api";
import customerAuthHeader from "../../../services/customer-auth-header";
import SimpleLoading from "../../../../common/loading/SimpleLoading";
import { priceFormat } from "../../../../hooks/helper";
import FrontendPagination from "../../../../common/pagination/frontend/FrontendPagination";
import { useTranslation } from "react-i18next";

const OrderReturnList = () => {
	const { t } = useTranslation();
  	const [loading, setLoading] = useState(false);
  	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(1);
  	const [currentPage, setCurrentPage] = useState(1);

	const getData=()=>{
		var item_value = sessionStorage.getItem("MARCATUEX_CLIENTS_ORDER_RETURNS");
		if ( item_value !== null ) {
			const temp = JSON.parse(sessionStorage.getItem("MARCATUEX_CLIENTS_ORDER_RETURNS") ) || [] ;
			setCurrentItems(temp?.data.data);
			setCurrentPage(temp?.data?.current_page);
			setPageCount(temp?.data?.last_page);
		} else {
			setLoading(true);
			markutosFrontendApi
			  .get(`dashboard/my-return-requests?per_page=10&page=${currentPage}`,
				  {
					headers: {
						Authorization: customerAuthHeader(),
					},
				}
			  )
			  .then((response) => {
					setLoading(false);
					sessionStorage.setItem("MARCATUEX_CLIENTS_ORDER_RETURNS",JSON.stringify(response));
					setCurrentItems(response?.data.data);
					setCurrentPage(response?.data?.current_page);
					setPageCount(response?.data?.last_page);
				});
		}
	
	  }
	
	  useEffect(() => {
		window.onload = function() {
		  sessionStorage.removeItem("MARCATUEX_CLIENTS_ORDER_RETURNS");
		};
		getData();
	  }, [currentPage]);

  return (
    <div>
      <h4>Order Return List</h4>
      <section className={` ${orderStyle.tableData}`}>
        <table>
          <thead>
            <tr>
				<th><small>{t('order')}{t('id_no')}</small></th>
				<th><small>{t('seller_name')}</small></th>
				<th><small>{t('date')}</small> </th>
				<th><small>{t('refund')} {t('amount')}</small></th>
				<th><small>{t('refund')} {t('reason')}</small></th>
				<th><small>{t('reject')} {t('reason')}</small></th>
				<th><small>{t('status')}</small></th>
            </tr>
          </thead>

          <tbody>
          {
          loading ? (<tr  className="w-100"><td colSpan={10}><SimpleLoading /></td></tr>) :
		  (
				(
					currentItems.map((item,index)=>{
					return(
						<tr key={index}>
							<td><small>{item.order_id}</small></td>
							<td><small>{item.seller_name}</small></td>
							<td><small>{item.created_at}</small></td>
							<td><small>{priceFormat(item.refund_amount)}</small></td>
							<td><small>{item.reason}</small></td>
							<td><small>{item.reject_reason}</small></td>
							<td>
								<div> {t('admin')} {t('approval')} : {item.admin_approval == "0" ? t('no') : t('yes') } </div>
								<div> {t('seller')} {t('approval')} : {item.seller_approval == "0" ? t('no') : t('yes') } </div>
							</td>
						</tr>
					)
					})
				)
			)
          }
          </tbody>
        </table>
				{currentItems.length > 0 && (
					<FrontendPagination
						currentItem={currentItems}
						perPage={10}
						pageCount={pageCount}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
					/>
				)}
      </section>
    </div>
  );
};

export default OrderReturnList;
