import {useState,useEffect} from "react";
import orderStyle from "./userOrder.module.css";
import { BsEyeFill } from "react-icons/bs";
import "react-loading-skeleton/dist/skeleton.css";
import { markutosFrontendApi } from "../../../services/Api/api";
import customerAuthHeader from "../../../services/customer-auth-header";
import SimpleLoading from "../../../../common/loading/SimpleLoading";
import { priceFormat } from "../../../../hooks/helper";
import FrontendPagination from "../../../../common/pagination/frontend/FrontendPagination";
import OrderModal from "./components/orderModal/OrderModal";
import { useTranslation } from "react-i18next";

const UserOrder = () => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [currentOrderId, setCurrentOrderId] = useState(null);
	const [show, setShow] = useState(false);

	const modalOpen = (id) => {
		setCurrentOrderId(id);
		setShow(!show);
	};

	const getData=()=>{
		var item_value = sessionStorage.getItem("MARCATUEX_CLIENTS_ORDER_LIST");
		if ( item_value !== null ) {
		  const temp = JSON.parse(sessionStorage.getItem("MARCATUEX_CLIENTS_ORDER_LIST") ) || [] ;
		  setCurrentItems(temp?.data.data);
		  setCurrentPage(temp?.data?.current_page);
		  setPageCount(temp?.data?.last_page);
		} else {
		  setLoading(true);
		  markutosFrontendApi
		  .get(`dashboard/my-orders?per_page=10&page=${currentPage}`,
		  {
				  headers: {
					  Authorization: customerAuthHeader(),
				  },
			  }
			)
			.then((response) => {
				  setLoading(false);
				  sessionStorage.setItem("MARCATUEX_CLIENTS_ORDER_LIST",JSON.stringify(response));
				  setCurrentItems(response?.data.data);
				  setCurrentPage(response?.data?.current_page);
				  setPageCount(response?.data?.last_page);
			  });
		}
	
	}
	
	useEffect(() => {
	window.onload = function() {
		sessionStorage.removeItem("MARCATUEX_CLIENTS_ORDER_LIST");
	};
	getData();
	}, [currentPage]);


  return (
    <div>
      <h4>{t('orders')}</h4>
      <section className={` ${orderStyle.tableData}`}>
        <table>
          <thead>
            <tr>
              <th>
                <small>{t('order_code')}</small>
              </th>
              <th>
                <small>{t('date')}</small>
              </th>
              <th>
                <small>{t('total')}</small>
              </th>
              <th>
                <small>{t('status')}</small>
              </th>
              <th className="text-center">
                <small>{t('actions')}</small>
              </th>
            </tr>
          </thead>

          <tbody>
          {
          loading ? (<tr  className="w-100"><td colSpan={10}><SimpleLoading /></td></tr>) :
		  (
				(
					currentItems.map((item,index)=>{
					return(
						<tr key={index}>
						<td><small>{item.code}</small></td>
						<td><small>{item.created_at}</small></td>
						<td><small>{priceFormat(item.grand_total)}</small></td>
						<td><small className={item.order_status == "pending" ?"text-danger" : "text-success"}> {item.order_status} </small></td>
						<td className="text-center">
						<small>
							<button style={{"--preview_icon":"#000"}} className={orderStyle.preview} onClick={() => modalOpen(item.id)}>
								<BsEyeFill />
							</button>
						</small>
						</td>
					</tr>
					)
					})
				)
			)
          }
          </tbody>
        </table>
		{currentItems.length > 0 && (
			<FrontendPagination
				currentItem={currentItems}
				perPage={10}
				pageCount={pageCount}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
			/>
		)}
		<OrderModal
		orderId={currentOrderId}
		page="order"
		show={show}
		setShow={setShow}
		time={new Date()}
        />
      </section>
    </div>
  );
};

export default UserOrder;
