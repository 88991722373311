import { useEffect, useState } from "react";
import blogStyle from "./blog.module.css";
import BreadCumb from "../../../common/breadcumb/BreadCumb";
import BlogSidebar from "./components/blogSidebar/BlogSidebar";
import BlogList from "./components/blogs/BlogList";
import { markutosFrontendApi } from "../../services/Api/api";
import { useTranslation } from "react-i18next";

const Blog = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [reloading, setReloading] = useState(false);
	const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [tag, setTag] = useState("");
  const [category, setCategory] = useState("");
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [recentPosts, setRecentPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [popularTags, setPopularTags] = useState([]);

  const getData=()=>{
    var item_value = sessionStorage.getItem("MARCATUEX_BLOG_LIST");
    if ( item_value !== null && reloading == false && (
      search == "" &&
      tag == "" &&
      category == "" 
    )) {
      const temp = JSON.parse(sessionStorage.getItem("MARCATUEX_BLOG_LIST") ) || [] ;
      setCurrentItems(temp?.data?.data);
      setCurrentPage(temp?.data?.current_page);
      setPageCount(temp?.data?.last_page);
    } else {
      setLoading(true);
      markutosFrontendApi
        .get(`/all-blogs?search_value=${search}&tag=${tag}&category_id=${category}&per_page=${perPage}&page=${currentPage}`)
        .then((response) => {
          setLoading(false);
          console.log(response?.data?.data);
          sessionStorage.setItem("MARCATUEX_BLOG_LIST",JSON.stringify(response));
          setCurrentItems(response?.data?.data);
          setCurrentPage(response?.data?.current_page);
          setPageCount(response?.data?.last_page);
        });
    }
  }

  useEffect(() => {
    window.onload = function() {
      sessionStorage.removeItem("MARCATUEX_BLOG_LIST");
    };
    getData();
  }, [
		search,
		tag,
		category,
		currentPage,
    perPage,
  ]);

  const getSidebar=()=>{
    var item_value = sessionStorage.getItem("MARCATUEX_BLOG_SIDEBAR");
    if ( item_value !== null ) {
      const temp = JSON.parse(sessionStorage.getItem("MARCATUEX_BLOG_SIDEBAR") ) || [] ;
      setCategories(temp?.data?.categories); 
      setPopularTags(temp?.data?.popular_tags);
      setRecentPosts(temp?.data?.recent_posts);
    } else {
      setLoading(true);
      markutosFrontendApi
      .get(`/blogs/right-sidebar`).then((response) => {
        setLoading(false);
        sessionStorage.setItem("MARCATUEX_BLOG_SIDEBAR",JSON.stringify(response));
        setCategories(response?.data?.categories); 
				setPopularTags(response?.data?.popular_tags);
				setRecentPosts(response?.data?.recent_posts);
      });
    }

  }

  useEffect(() => {
    window.onload = function() {
      sessionStorage.removeItem("MARCATUEX_BLOG_SIDEBAR");
    };
    getSidebar();
  }, []);


  return (
    <>
      <BreadCumb data={t('blog')} />
      {/* <div className="container">
       <div className="row">
          <div className="col-md-8">
            <h1 className="text-lg-center mt-4 pb-4">{t('blog')}</h1>
          </div>
       </div>
      </div> */}
      <div className={`container ${blogStyle.blogContainer}`}>
        <div className="row">
          <div className="col-lg-8">
            <BlogList
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setPerPage={setPerPage}
            perPage={perPage}
            pageCount={pageCount}
            currentItems={currentItems}
            loading={loading}
            setReloading={setReloading}
            />
          </div>
          <div className="col-lg-4">
            <BlogSidebar
            recentPosts={recentPosts}
            categories={categories}
            popularTags={popularTags}
            setSearch={setSearch}
            setTag={setTag}
            setCategory={setCategory}
            search={search}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
