import { BsFilter, BsGrid3X3Gap } from "react-icons/bs";
import { AiOutlineBars } from "react-icons/ai";
import sortStyle from "./stores.module.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {bg_Style,color_Style,getTemplates,bg_Hover} from "../../../../../hooks/templates";

const SortFilter = ({ setLayout , totalStores , setSearch , search }) => {
  const { t } = useTranslation();

  const [sortInputShow, setSortInputShow] = useState(false);
  const [isHover, setIsHover] = useState(-1);
  const handleMouseEnter = (key) => {
	  setIsHover(key);
   };
   const handleMouseLeave = () => {
	  setIsHover(-1);
   };

  return (
    <div>
      <div className={` mt-4 ${sortStyle.sortBar}`}>
        <div className={sortStyle.sortBarLeft}>
          <h6>{t('total')} {t('stores')} : {totalStores}</h6>
        </div>
        <div className={sortStyle.sortBarRight}>
          <button
            onClick={() => setSortInputShow(!sortInputShow)}
            className={`btn btn-primary ${sortStyle.filterBtn}`}
			style={bg_Style}
          >
            <BsFilter /> {t('filter')}
          </button>
          <button
            onClick={() => setLayout("grid")}
            className={`btn btn-outline-primary ${sortStyle.layoutBtn}`}
			style={{
				background: isHover == 0 ? bg_Hover() : '#fff',
				borderColor: isHover == 0 ? bg_Hover() : getTemplates().primary_color,
				color: isHover == 0 ? '#fff' : getTemplates().primary_color,
			}}
			onMouseEnter={ (e)=>{handleMouseEnter(0)}}
			onMouseLeave={(e)=>handleMouseLeave()}
          >
            <BsGrid3X3Gap />
          </button>
          <button
            onClick={() => setLayout("list")}
            className={`btn btn-outline-primary ${sortStyle.layoutBtn}`}
			style={{
				background: isHover == 1 ? bg_Hover() : '#fff',
				borderColor: isHover == 1 ? bg_Hover() : getTemplates().primary_color,
				color: isHover == 1 ? '#fff' : getTemplates().primary_color,
			}}
			onMouseEnter={ (e)=>{handleMouseEnter(1)}}
			onMouseLeave={(e)=>handleMouseLeave()}
          >
            <AiOutlineBars />
          </button>
        </div>
      </div>

      <div
        className={` mt-2 ${
          sortInputShow ? sortStyle.sortInputShow : sortStyle.sortInput
        }`}
      >
        <div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name=""
              id=""
              aria-describedby="helpId"
              placeholder="Search Store..."
              onChange={(e)=>setSearch(e.target.value)}
              value={search}
            />
          </div>
          {/* <div className={sortStyle.sortBarRight}>
            <button className={`btn btn-primary mt-3 ${sortStyle.filterBtn}`}>
              <AiOutlineCheckCircle /> Apply
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SortFilter;
