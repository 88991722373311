import { useEffect, Fragment } from "react";
import { BsEyeFill } from "react-icons/bs";
import { useState } from "react";
import orderListStyle from "./orderList.module.css";
import PdfModal from "../../../../../common/pdfModal/PdfModal";
import OrderModal from "../orderModal/OrderModal";
import DateRangeSelector from "../../../../../common/ui/dateRangeSelector";
import SimpleLoading from "../../../../../common/loading/SimpleLoading";
import PaginationCom from "../../../../../common/pagination/PaginationCom";
import { markutosSellerApi } from "../../../../services/Api/api";
import { useDebounce } from "../../../../../hooks/useDebounce";
import Select from "react-select";
import { priceFormat } from "../../../../../hooks/helper";
import { useTranslation } from "react-i18next";

const OrderList = () => {
  const { t , i18n } = useTranslation();

  const [show, setShow] = useState(false);
  const [pdfShow, setPdfShow] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [getOrderStatus, setOrderStatus] = useState("");
  const [getDeliveryStatus, setDeliveryStatus] = useState("");
  const [currentOrderId, setCurrentOrderId] = useState(null);

  useEffect(() => {
    setLoading(true);
    markutosSellerApi()
	.get(`/orders?search_value=${search}&sort_order_status=${getOrderStatus}&sort_delivery=${getDeliveryStatus}&date_from=${startDate}&date_to=${endDate}&per_page=${perPage}&page=${currentPage}`)
      .then((response) => {
        setLoading(false);
        setCurrentItems(response?.data?.data);
        setCurrentPage(response?.data?.current_page);
        setPageCount(response?.data?.last_page);
      });
  }, [
    currentPage,
    perPage,
    search,
    getOrderStatus,
    getDeliveryStatus,
    startDate,
    endDate,
  ]);


  const orderOptions = [
    { value: "confirmed", label: t('confirmed') },
    { value: "processing", label: t('processing') },
    { value: "completed", label: t('completed') },
    { value: "cancelled", label: t('cancelled') },
    { value: "pending_payment", label: t('pending_payment') },
    { value: "failed", label: t('failed')  },
    { value: "draft", label: t('Draft') },
  ];

  const deliveryStatus = [
    { value: "Pending", label: t('pending') },
    { value: "Confirmed", label: t('confirmed') },
    { value: "Delivered", label: "Delivered" },
    { value: "On delivery", label: t('on') + t('delivery') },
    { value: "Canceled", label: t('cancelled') },
  ];

  const modalOpen = (id) => {
    setCurrentOrderId(id);
    setShow(!show);
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);

  return (
    <Fragment>
      <div className={`${orderListStyle.background}`}>
        <section>
          <div className="table-top-header d-flex justify-content-between">
            <div className="table-title">
              <h5 className="px-md-4 px-3 pt-3 py-2">{t('orders')}</h5>
            </div>
          </div>
        </section>

        <section>
          <div
            className={` px-md-4 px-3 pt-3 table-filters ${orderListStyle.filterContainer}`}
          >
            <Select
              options={orderOptions}
              className="me-0 me-md-3 mb-1 mb-md-0"
              placeholder={t('sort_order_status')}
              onChange={(e) => setOrderStatus(e.value)}
            />
            <Select
              options={deliveryStatus}
              className="me-0 me-md-3 mb-1 mb-md-0"
              placeholder={t('sort_delivery_status')}
              onChange={(e) => setDeliveryStatus(e.value)}
            />
            <input
              type="text"
              className={`table-search-input`}
              placeholder= {t('search_btn') + t('by') + t('order_code') }
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div>
              <DateRangeSelector
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </div>
          </div>
        </section>

        <section>
          <div
            className={` ${orderListStyle.orderRow} px-0 mx-0 ps-3 mt-3 ${orderListStyle.header}`}
          >
            <div>
              <p>#</p>
            </div>
            <div>
              <p>{t('order_code')}</p>
            </div>
            <div className={` ${orderListStyle.hide}`}>
              <p>{t('no_of_products')}</p>
            </div>
            <div className={` ${orderListStyle.hide}`}>
              <p>{t('amount')}</p>
            </div>
            <div className={` ${orderListStyle.hide}`}>
              <p>{t('customer')}</p>
            </div>
            <div className={` text-center ${orderListStyle.hide}`}>
              <p>{t('delivery')} {t('status')}</p>
            </div>
            <div className="text-center">
              <p> {t('order')} {t('status')}</p>
            </div>
            <div className="text-center">
              <p>{t('options')}</p>
            </div>
          </div>
        </section>
        {loading ? (
          <SimpleLoading />
        ) : (
          <Fragment>
            <section>
              {currentItems.length > 0 ? (
                currentItems.map((item, key) => {
                  return (
                    <div
                      className={` ${orderListStyle.orderRow} px-0 mx-0 ps-3 mt-4 pt-2 `}
                      key={key}
                    >
                      <div className="action-column">{item.id}</div>
                      <div onClick={() => modalOpen(item.id)}>
                        <p className={orderListStyle.code}>{item.code}</p>
                      </div>
                      <div className={` ${orderListStyle.hide}`}>
                        <p>{item.num_of_product}</p>
                      </div>
                      <div className={` ${orderListStyle.hide}`}>
                        <p>{priceFormat(item.grand_total,i18n.language)} </p>
                      </div>
                      <div className={`${orderListStyle.hide}`}>
                        <span> {item.customer_name} </span>
                      </div>
                      <div className={`text-center ${orderListStyle.hide}`}>
                        <span> {item.delivery_status} </span>
                      </div>
                      <div className="text-center">
                        <span className={orderListStyle.unpaid}>
                          {item.order_status}
                        </span>
                      </div>
                      <div className="text-center">
                        <button
                          onClick={() => modalOpen(item.id)}
                          className={orderListStyle.preview}
                        >
                          <BsEyeFill />
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="text-center">{t('no_item')}</p>
              )}
            </section>
          </Fragment>
        )}

        {currentItems.length > 0 && (
          <PaginationCom
            currentItem={currentItems}
            perPage={perPage}
            pageCount={pageCount}
            currentPage={currentPage}
            setPerPage={setPerPage}
            setCurrentPage={setCurrentPage}
          />
        )}
        <PdfModal show={pdfShow} setShow={setPdfShow} />
        <OrderModal
          orderId={currentOrderId}
          page="order"
          show={show}
          setShow={setShow}
          time={new Date()}
        />
      </div>
    </Fragment>
  );
};

export default OrderList;
