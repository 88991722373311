import "./popularCategories.scss";
import { categories_1, categories_2 } from "../../../../../../assets/index";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

const PopularCategories = ({pinnedCategories}) => {
	return (
		<section className="content-block">
			<div className="row text-center">
				<h2 className="content-title">Explore Popular Categories</h2>
			</div>
			<div className="row category-slider">
				<Swiper
					className="popular-categories"
					modules={[Navigation]}
					centeredSlides={false}
					autoplay={{
						delay: 1000,
						disableOnInteraction: false
					}}
					pagination={{
						clickable: true
					}}
					loop={true}
					loopFillGroupWithBlank={true}
					navigation
					breakpoints={{
						576: {
							spaceBetween: 0,
							slidesPerView: 1
						},
						768: {
							spaceBetween: 0,
							slidesPerView: 3
						},
						1024: {
							spaceBetween: 20,
							slidesPerView: 5
						},
						1280: {
							spaceBetween: 20,
							slidesPerView: 5
						}
					}}
				>
				{pinnedCategories &&
					pinnedCategories.map((category, index) => {
					return (
						<SwiperSlide className="category-item-wrapper" key={index}>
							<a className="category-item" href="#">
								<div className="category-slider-image">
									<img src={categories_1} alt="categories" />
								</div>
								<h3 className="category-title title_on_top">
									{category.name}
									<i className="xts-icon xts-arrow-right"></i>
								</h3>
							</a>
						</SwiperSlide>
					);
				})}
				</Swiper>
			</div>
		</section>
	);
};

export default PopularCategories;
