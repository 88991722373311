import Categories from "./Categories";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getTemplates } from "../../../../../../hooks/templates";
import "./headerMenu.scss";
const HeaderMenu = ({categories,phone}) => {
	const { t } = useTranslation();

	return (
		<section className="template_2_header">
			<div className="header_menu">
				<div className="row m-0">
					<div className="col-lg-3 col-md-4 col-sm-12">
						<div className="category-menu-area">
							<Categories categories={categories} />
						</div>
					</div>
					<div className="col-lg-9 col-md-8 col-sm-12">
						<div className="right-1-menu">
							<div className="nav-menu-2">
								<nav className="navbar navbar-expand-lg">
									<ul className="navbar-nav mr-auto" style={{
										"--bs-navbar-active-color" :  getTemplates().primary_color,
									}}>
										<li className="nav-item">
											<NavLink className="nav-link" to="/">{t('home')}</NavLink>
										</li>
										{
											process.env.REACT_APP_MARKETPLACE_TYPE == "marketplace" &&
											<li className="nav-item">
												<NavLink className="nav-link" to="/stores">{t('stores')}</NavLink>
											</li>
										}
										<li className="nav-item">
											<NavLink className="nav-link" to="/shop">{t('shop')}</NavLink>
										</li>
										<li  className="nav-item">
											<NavLink className="nav-link" to="/cart">{t('cart')}</NavLink>
										</li>
										<li  className="nav-item">
											<NavLink className="nav-link" to="/blog">{t('blog')}</NavLink>
										</li>
										<li  className="nav-item">
											<NavLink className="nav-link" to="/checkout">{t('checkout')}</NavLink>
										</li>
									</ul>
								</nav>
							</div>
							<div className="address-area">
								<div className="icon">
									<i className="fa fa-phone" aria-hidden="true"></i>
								</div>
								<div className="text">
									<span className="phone-label">Call Us:</span>
									<span className="phone">{`+${phone}`}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HeaderMenu;
