import TemplateOne from "./template1/TemplateOne";
import TemplateTwo from "./template2/TemplateTwo";


const Home = () => {

  function displayComponent() {
      if (process.env.REACT_APP_MARKETPLACE_TEMPLATE == 1) {
      return  <TemplateOne/>
      }
      else{
        return <TemplateTwo/>
      }
  }

  return (
    displayComponent()
  );
};

export default Home;
