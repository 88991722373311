import {Fragment, useEffect,useState} from "react";
import CheckoutForm from "./components/checkoutCom/CheckoutForm";
import CartDetails from "./components/checkoutCom/CartDetails";
import checkoutStyle from "./checkoutStyle.module.css";
import BreadCumb from "../../../common/breadcumb/BreadCumb";
import { useCart } from "react-use-cart";
import { getFinalPrice, storeCart } from "../../../hooks/helper";
import { NavLink } from "react-router-dom";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Checkout = () => {
	const { t } = useTranslation();
	const { shipping_methods,shipping_charge , tax , stripe_public_key } = useSelector((state) => state.additionalPrice );
	const [storesCart, setStoreCart] = useState([]);
	const [stripePublicKey, setStripePublicKey] = useState(`${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`);

	const {
	isEmpty,
	items,
	cartTotal,
	metadata,
	emptyCart,
	} = useCart();
	useEffect(() => {
		setStoreCart(storeCart(items,tax));
		if ( stripe_public_key ) {
			setStripePublicKey(stripe_public_key)
		}
	}, [tax,stripe_public_key]);

	const options = {
		// passing the client secret obtained from the server
		clientSecret: "pi_3MSNynAxOHERziJ23fVz5tXh_secret_SEsi52OyI4svQUq6K0wa3a6RN",
	};
	const finalTotal = getFinalPrice( cartTotal , shipping_charge , tax , metadata );

	const stripePromise = loadStripe( stripePublicKey ) ;

  return (
    <div className="wrapper">
      <BreadCumb data={t('checkout')} />
      <div className={`container ${ ! isEmpty && checkoutStyle.checkoutContainer}`}>
		{
			!isEmpty ?
			(
				<Fragment>
					<div className={checkoutStyle.checkoutForm}>
						<Elements stripe={stripePromise} options={options}>
							<CheckoutForm shippingMethod={shipping_methods} shippingCharge={shipping_charge} finalTotal={finalTotal} storesCart={storesCart} cartTotal={cartTotal} emptyCart={emptyCart} metadata={metadata} />
						</Elements>
					</div>
					<div className={checkoutStyle.cartDetails}>
						<CartDetails storesCart={storesCart} cartTotal={cartTotal} />
					</div>
				</Fragment>
			) :
			(
				<div className={`shadow-sm p-3 mt-5 mb-5 bg-body rounded ${checkoutStyle.cartEmpty}`}>
					{t('checkout_cart_empty')} <NavLink to={`/shop`}>{t('shop')}</NavLink>
		  		</div>
			)
		}
      </div>

    </div>
  );
};

export default Checkout;
