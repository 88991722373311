
/**
 * Price format based on country code
 * @param {*} price
 * @param {*} code
 * @returns
 */
export const priceFormat = ( price , code='en' , type ="simple"  )=>{
	var resultPrice = price;
	var country_code = "en-US";
	var currency = "USD";
	const languages = [
		{ label: "EN", value: { code: "en-US", currency: "USD" }, code: "en" },
		{ label: "FR", value: { code: "de-DE", currency: "EUR" }, code: "fr" },
	  ];
	let current_lang = languages.find(item => item.code === code);

	if (current_lang) {
		country_code = current_lang?.value?.code;
		currency = current_lang?.value?.currency;
	}
	if ( price !== null) {
		var result = new Intl.NumberFormat( country_code , {
			style: 'currency',
			currency: currency,
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});
		if ( type =="simple" ) {
			resultPrice = result.format(price);
		}
		else if( type =="variation" ){
			const price_arr = price.split("-");
			resultPrice = result.format(price_arr[0]) + `-`+  result.format(price_arr[1]);
		}

	}
	
	return resultPrice;
};

/**
 * Shop wise cart
 * @param {*} items
 * @returns
 */
export const storeCart = (items , tax = null )=>{
	const lookup = items.reduce((newObj, item) => {
		newObj[item.user_id] = item.shop_name;
		return newObj;
	}, {});

	var resultArr = []; 
	var calculateTax = 0.00;
	Object.keys(lookup).map((obj,key)=>{
		var newArr = items.filter( element => obj == element.user_id );

		const cartTotal = newArr.reduce((accumulator, object) => {
			return accumulator + object.itemTotal;
		}, 0);

		if ( tax ) {
			calculateTax = parseFloat((cartTotal * parseFloat(tax) )/100);
		}

		resultArr.push({ shopName: lookup[obj] , seller_id : obj ,
		products:newArr , subtotal : cartTotal , shipping_method : "" , tax : calculateTax , coupon_discount: 0.00 , total: cartTotal })
	});

	return resultArr;
};

/**
 * Get Frontend Language
 */
export const getFrontLang = () =>{
	var frontendLang = {"code":"en","currency":"USD","lang":"en-US"};
	if ( localStorage.getItem("frontendLang") ) {
		frontendLang = JSON.parse(localStorage.getItem("frontendLang"));
	}

	return frontendLang;
}

/**
 * Calculate shipping charge and tax
 * @param {*} cartTotal 
 * @param {*} tax 
 * @param {*} shipping_charge 
 * @returns 
 */
export const getFinalPrice=( cartTotal , shipping_charge, tax , metadata , format = false )=>{
	// tax calculation
	let calculate_tax = 0;
	if ( tax ) {
	calculate_tax = (cartTotal * parseFloat(tax) )/100;
	}

	var total_price = parseFloat(cartTotal) + parseFloat(calculate_tax);

	// shipping charge calculation
	if ( shipping_charge ) {
	total_price += parseFloat(shipping_charge);
	}

	// calculate coupon charge
	if (metadata?.coupon) {
		total_price -= parseFloat(metadata.coupon);
	}

	return format ? priceFormat(total_price) : total_price;
}

export const getVariation = ( attributesArray )=>{
	var final = [];
	if ( attributesArray.length == 1 ) {
		for (let i = 0; i < attributesArray[0]?.length; i++) {
			final.push(attributesArray[0][i])
		}
		return final;
	}
	else if ( attributesArray.length == 2 ) {
		for (let i = 0; i < attributesArray[0]?.length; i++) {
			for (let j = 0; j < attributesArray[1]?.length; j++) {
				final.push(attributesArray[0][i] + "-" + attributesArray[1][j] );
			}
		}

		return final;
	}
	else if ( attributesArray.length == 3 ) {
		for (let i = 0; i < attributesArray[0]?.length; i++) {
			for (let j = 0; j < attributesArray[1]?.length; j++) {
				for (let k = 0; k < attributesArray[2]?.length; k++) {
					final.push(attributesArray[0][i] + "-" + attributesArray[1][j] + "-" + attributesArray[2][k]);
				}
			}
		}
		return final;
	}
	else if ( attributesArray.length == 4 ) {
		for (let i = 0; i < attributesArray[0]?.length; i++) {
			for (let j = 0; j < attributesArray[1]?.length; j++) {
				for (let k = 0; k < attributesArray[2]?.length; k++) {
					for (let l = 0; l < attributesArray[3]?.length; l++) {
						final.push(attributesArray[0][i] + "-" + attributesArray[1][j] + 
						"-" + attributesArray[2][k] + "-" + attributesArray[3][l]);
					}
				}
			}
		}
		return final;
	}
	else if ( attributesArray.length == 5 ) {
		for (let i = 0; i < attributesArray[0]?.length; i++) {
			for (let j = 0; j < attributesArray[1]?.length; j++) {
				for (let k = 0; k < attributesArray[2]?.length; k++) {
					for (let l = 0; l < attributesArray[3]?.length; l++) {
						for (let m = 0; m < attributesArray[3]?.length; m++) {
							final.push(attributesArray[0][i] + "-" + attributesArray[1][j] + 
							"-" + attributesArray[2][k] + "-" + attributesArray[3][l]
							 + "-" + attributesArray[4][m]);
						}
					}
				}
			}
		}
		return final;
	}
}
