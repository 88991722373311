import "./footer.scss";
import { header_logo, logo, white_logo } from "../../../../assets";
import { apple_store } from "../../../../assets";
import { google_store } from "../../../../assets";
import { payment_methods } from "../../../../assets";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import SocialLink from "./SocialLink";
import TemplateOne from "../../newsletter/TemplateOne";

const FooterOne = ({ footerData, user }) => {
	const { t } = useTranslation();

	return (
		<section className="footer-area">
			<div className="container">
				<div className="row">
					<div className="col-lg-3 col-md-6 col-sm-12 border-right">
						<div className="footer-widget">
							<h2 className="footer-title">HELP & INFORMATION</h2>
							<ul className="footer1-menu footer-menu">
								<li>
									<a href="#">Delivery Information</a>
								</li>
								<li>
									<a href="#">Shipping & Returns</a>
								</li>
								<li>
									<a href="#">Help</a>
								</li>
								<li>
									<a href="#">Contact Us</a>
								</li>
								<li>
									<a href="#">Our Policies</a>
								</li>
								<li>
									<a href="#">FAQs</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-sm-12 border-right border-none">
						<div className="footer-widget">
							<h2 className="footer-title">SUPPER DEALS</h2>
							<ul className="footer1-menu footer-menu">
								<li>
									<a href="#">Mid-Season sale</a>
								</li>
								<li>
									<a href="#">50% Off (Up to $499)</a>
								</li>
								<li>
									<a href="#">30% Off on all kid's item</a>
								</li>
								<li>
									<a href="#">Gift and Card</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-sm-12 border-right">
						<div className="footer-widget">
							<h2 className="footer-title">USEFUL LINKS</h2>
							<ul className="footer1-menu footer-menu">
								<li>
									<a href="#">About</a>
								</li>
								<li>
									<a href="#">My Account</a>
								</li>
								<li>
									<a href="#">Best Sale</a>
								</li>
								<li>
									<a href="#">Blog</a>
								</li>
								<li>
									<a href="#">Partnership</a>
								</li>
								<li>
									<a href="#">Collection</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-sm-12 pl-60">
						<div className="footer-widget">
							<div className="logo">
								<NavLink to="/">
									<img src={footerData?.footer_logo ? footerData?.footer_logo : header_logo } alt="Logo" />
								</NavLink>
							</div>
							<div className="content-details">
								{/* NewsLetter */}
								<TemplateOne/>
								<SocialLink footerData={footerData}/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="download-payment-area">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div className="download-area">
								<h2>Download Now:</h2>
								<div className="download-list">
									<div>
										<a href="https://www.apple.com/store" target={'_blank'}>
											<img src={apple_store} alt="Apple Store" />
										</a>
									</div>
									<div>
										<a href="https://play.google.com/store/apps" target={'_blank'}>
											<img src={google_store} alt="Play Store" />
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div className="payment-area">
								<h2>Payment Method:</h2>
								<div className="payment-img">
									<img src={payment_methods} alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="copyright-area">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-12">
							<div className="copy-right">
								<p>{footerData?.copyright_text}</p>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12">
							<div className="usefull-links">
								<ul>
									<li>
										<a href="#">Privacy Policy</a>
									</li>
									<li>
										<a href="#">Terms of Service</a>
									</li>
									<li>
										<a href="#">Legal</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default FooterOne;
