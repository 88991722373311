import { useEffect,useState } from "react";
import "./shopCom.scss";
import LeftComp from "../leftComp/LeftComp";
import RightComp from "../rightComp/RightComp";
import { markutosFrontendApi } from "../../../../services/Api/api";
import "react-loading-skeleton/dist/skeleton.css";
import { useParams , useSearchParams} from "react-router-dom";

const ShopCom = () => {
	const { catId  } = useParams();
	const [searchParams] = useSearchParams();
	const [loading, setLoading] = useState(false);
	const [shopProduct, setShopProduct] = useState([]);
	const [pageCount, setPageCount] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortBy, setSortBy] = useState('price');
	const [categoryId, setCategoryId] = useState('');
	const [brandId, setBrandId] = useState('');
	const [attributesId, setAttributesId] = useState('');
	const [variations, setVariations] = useState('');
	const [productSearch, setProductSearch] = useState('');

	const getData=()=>{
		var item_value = sessionStorage.getItem("MARCATUEX_SHOP_PRODUCTS");
		if ( item_value !== null && ( currentPage == 1 && sortBy == "price"
		&& categoryId == "" && brandId == "" && attributesId == "" && variations =="" )) {
		  const temp = JSON.parse(sessionStorage.getItem("MARCATUEX_SHOP_PRODUCTS") ) || [] ;
			setShopProduct(temp?.data.data);
			setCurrentPage(temp?.data?.current_page);
			setPageCount(temp?.data?.last_page);
		} else {
			// category id
			const shop_view_more_slug = catId ? catId : '';
			let search_data = "";
			// search data
			if ( productSearch !=="") {
				search_data = productSearch;
			} else {
				search_data = searchParams.get('search') ? searchParams.get('search') : "";
			}
			setLoading(true);
			markutosFrontendApi
			.get(`/shop?per_page=12&search_value=${search_data}&category=${categoryId}
			&brand=${brandId}&rating=&color=&sort_by=${sortBy}&choice_name=${attributesId}
			&choice_value=${variations}&page=${currentPage}&shop_slug=&shop_view_more_slug=${shop_view_more_slug}`)
			.then((response) => {
				setLoading(false);
				sessionStorage.setItem("MARCATUEX_SHOP_PRODUCTS",JSON.stringify(response));
				setShopProduct(response?.data.data);
				setCurrentPage(response?.data?.current_page);
				setPageCount(response?.data?.last_page);
			});
		}
	
	  }
	
	useEffect(() => {
		window.onload = function() {
		  sessionStorage.removeItem("MARCATUEX_SHOP_PRODUCTS");
		  sessionStorage.removeItem("MARCATUEX_SHOP_LEFT_SIDE");
		};
		getData();
	}, [currentPage,sortBy,productSearch,categoryId,brandId,attributesId,variations]);

	return (
		<section className="shop-wrap my-4">
			<div className="container">
			<div className="shopContainer">
				<LeftComp 
					setProductSearch={setProductSearch}
					setCategoryId={setCategoryId}
					setBrandId={setBrandId}
					setAttributesId={setAttributesId}
					setVariations={setVariations}
				/>
				<RightComp
					loading={loading}
					shopProduct={shopProduct}
					pageCount={pageCount}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					setSortBy={setSortBy}
				/>
			</div>
			</div>
		</section>
	);
};

export default ShopCom;
