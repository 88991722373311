import { useState, useEffect } from "react";
import dashboardStyle from "./dashboard.module.css";
import { BsCurrencyDollar, BsShop } from "react-icons/bs";
import { AiOutlineShoppingCart, AiOutlineShop } from "react-icons/ai";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import { Table } from "react-bootstrap";
import SaleHistory from "./SaleHistory";
import {markutosSellerApi} from "../../../services/Api/api";
import SimpleLoading from "../../../../common/loading/SimpleLoading";
import { priceFormat } from "../../../../hooks/helper";
import { useTranslation } from "react-i18next";

const DashboardCom = () => {
	const { t , i18n } = useTranslation();
	const [allData, setAllData] = useState();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		markutosSellerApi()
		.get(`/dashboard`)
		.then((response) => {
			setLoading(false);
			setAllData(response?.data);
		})
	}, []);
	
  return (
      <div className="px-4 py-2">
			<section>
				<div className="row px-0">
					<div className="col">
						<h1 className={`mb-4 ${dashboardStyle.header_title}`}>
						{t('dashboard')}
						</h1>
					</div>
				</div>
			</section>
			{/* 1st block */}
			<section>
				<div className={`row px-0 `}>
					<div className="col-md-3">
						<div className={`${dashboardStyle.dashboard_card} `}>
							<div className="col">
								<h1>{t('total_revenue')}</h1>
								<small> ( {t('last_30_days')} ) </small>
							</div>

							<div>
								<span>
									<BsCurrencyDollar />
								</span>
							</div>
							<div>
								{loading ? <SimpleLoading /> : <h5>{priceFormat(allData?.card_items.total_revenue,i18n.language)}</h5>}
							</div>
						</div>
					</div>

					<div className="col-md-3">
						<div className={`${dashboardStyle.dashboard_card} `}>
							<div className="col">
								<h1>{t('total')} {t('order')}</h1>
								<small> ( {t('last_30_days')} ) </small>
							</div>

							<div>
								<span>
									<AiOutlineShoppingCart />
								</span>
							</div>
							<div>
							{loading ? <SimpleLoading /> : <h5> {allData?.card_items?.total_order} </h5>}
							</div>
						</div>
					</div>

					<div className="col-md-3">
						<div className={`${dashboardStyle.dashboard_card} `}>
							<div className="col">
								<h1>{t('todays')} {t('revenue')}</h1>
							</div>

							<div>
								<span>
									<BsCurrencyDollar />
								</span>
							</div>
							<div>
								<h5>{priceFormat(allData?.card_items?.todays_revenue,i18n.language)}</h5>
							</div>
						</div>
					</div>

					<div className="col-md-3">
						<div className={`${dashboardStyle.dashboard_card} `}>
							<div className="col">
								<h1>{t('todays')} {t('refund')}</h1>
							</div>

							<div>
								<span>
									<HiOutlineReceiptRefund />
								</span>
							</div>
							<div>
								<h5>{priceFormat(allData?.card_items?.todays_refund,i18n.language)}</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* 2nd block */}
			<section>
				<div className="row">
					<div className="col">
						<SaleHistory data={allData?.sales_history}/>
					</div>
				</div>
			</section>
			{/* 3rd block */}
			<section>
				<div className={` ${dashboardStyle.tables}`}>
					<div className={dashboardStyle.table}>
						<h3 className={` mb-3 ${dashboardStyle.tableHeader}`}>
						{t('recent')} {t('orders')}
						</h3>
						<div>
							<Table hover borderless responsive>
								<thead>
									<tr>
										<th>
											<small>{t('tracking_number')}</small>
										</th>
										<th>
											<small> {t('total')} {t('amount')}</small>
										</th>
										<th>
											<small> {t('order')} {t('date')} </small>
										</th>
										<th>
											<small> {t('delivery')} {t('status')}</small>
										</th>
									</tr>
								</thead>
								<tbody>
									{ allData?.recent_orders?.length > 0 && (
									allData?.recent_orders?.map((item, key) => {
											return (
												<tr key={key}>
													<td>
														<small>{item.code}</small>
													</td>
													<td>
														<small> {priceFormat(item.total_amount,i18n.language)} </small>
													</td>
													<td>
														<small> {item.created_at} </small>
													</td>
													<td>
														<small> {item.delivery_status}</small>
													</td>
												</tr>
											)
										})
									)}
								</tbody>
							</Table>
						</div>
					</div>
					<div className={dashboardStyle.table}>
						<h3 className={` mb-3 ${dashboardStyle.tableHeader}`}>
						{t('recent')} {t('withdrawals')}
						</h3>
						<div>
							<Table hover borderless responsive>
								<thead>
									<tr>
										<th>
											<small> {t('amount')} </small>
										</th>
										<th>
											<small> {t('status')} </small>
										</th>
										<th>
											<small> {t('created')}</small>
										</th>
									</tr>
								</thead>
								<tbody>
								{ allData?.recent_withdrawals?.length > 0 && (
									allData?.recent_withdrawals?.map((item, key) => {
										return (
											<tr key={key}>
												<td>
													<small>{priceFormat(item.amount,i18n.language)}</small>
												</td>
												<td>
													<small> {item.status} </small>
												</td>
												<td>
													<small> {item.created_at} </small>
												</td>
											</tr>
										)
									})
									) }
								</tbody>
							</Table>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className="row mt-3">
					<div className="col">
						<div className={dashboardStyle.table}>
							<h3 className={` mb-3 ${dashboardStyle.tableHeader}`}>
								{t('popular')} {t('products')} 
							</h3>
							<div>
								<Table hover borderless responsive>
									<thead>
										<tr>
											<th>
												<small> {t('id_no')} </small>
											</th>
											<th>
												<small>  {t('name')} </small>
											</th>
											<th>
												<small> {t('group')} </small>
											</th>

											<th>
												<small> {t('price')}/{t('unit')}</small>
											</th>
											<th>
												<small> {t('quantity')} </small>
											</th>
										</tr>
									</thead>

									<tbody>
									{ allData?.popular_products?.length > 0 && (
									allData?.popular_products?.map((item, key) => {
											return (
												<tr key={key}>
													<td>
														<small>{item.id}</small>
													</td>
													<td>
														<small> {item.name} </small>
													</td>
													<td>
														<small> {item.category} </small>
													</td>
													<td>
														<small> {priceFormat(item.unit_price,i18n.language)}</small>
													</td>
													<td>
														<small> {item.current_stock}</small>
													</td>
												</tr>
											)
										})
									)}
									</tbody>
								</Table>
							</div>
						</div>
					</div>
				</div>
			</section>
      </div>
	);
};

export default DashboardCom;
