import { BiSearch } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import {getTemplates} from "../../../../../../hooks/templates";

const ProductSearch = ({setProductSearch}) => {
    const { t } = useTranslation();
	return (
        <div className="sidebar-row search-wrap">
            {/* Product Search */}
            <form action="" className="search-form">
                <input
                    type="text"
                    onChange={(e)=>{setProductSearch(e.target.value)}}
                    className="sidebar-input"
                    placeholder={`${t('search_btn')+` `}${t('products')}`}
                />
                <button type="submit" className="search-button" style={{ background:getTemplates().primary_color }}>
                    <BiSearch />
                </button>
            </form>
        </div>
	);
};

export default ProductSearch;
