import React from "react";
import "./saleHistory.css";
import {
  ComposedChart,
  Line,
  Bar,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";

const data = [
  {
    name: "January",
    Order: 590,
    revenue: 700,
    Refund: 140,
  },
  {
    name: "February",
    Order: 868,
    revenue: 325,
    Refund: 90,
  },
  {
    name: "March",
    Order: 1397,
    revenue: 445,
    Refund: 150,
  },
  {
    name: "April",
    Order: 1480,
    revenue: 741,
    Refund: 110,
  },
  {
    name: "May",
    Order: 1520,
    revenue: 989,
    Refund: 45,
  },
  {
    name: "June",
    Order: 1400,
    revenue: 420,
    Refund: 80,
  },
  {
    name: "July",
    Order: 1520,
    revenue: 174,
    Refund: 210,
  },
  {
    name: "August",
    Order: 1400,
    revenue: 800,
    Refund: 80,
  },
  {
    name: "September",
    Order: 1520,
    revenue: 257,
    Refund: 100,
  },
  {
    name: "October",
    Order: 1400,
    revenue: 450,
    Refund: 150,
  },
  {
    name: "November",
    Order: 1400,
    revenue: 500,
    Refund: 70,
  },
  {
    name: "December",
    Order: 50000,
    revenue: 150,
    Refund: 20,
  },
];

const SaleHistory = ({data}) => {
  const { t } = useTranslation();
  return (
    <div className="saleHistory">
      <h6 className="my-3 ms-3 mb-4">{t('sale')+` `+t('history')}</h6>
      <div className="widget-container" style={{ height: "400px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#ffffff" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {/* <Bar dataKey="revenue" barSize={40} fill="#59CE8F" /> */}
            <Area type="monotone" dataKey="revenue" fill="#8884d8" stroke="#8884d8" />
            <Bar dataKey="revenue" barSize={20} fill="#413ea0" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default SaleHistory;
