import { NavLink } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { API_URL } from "../../../pages/customer/services/Api/api";
import { useState } from "react";
import BreadCumb from "../../../common/breadcumb/BreadCumb";
import authStyle from "../../auth.module.css";
import { FaRegEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { saveToLocalStorage } from "../../../utils/user/manageLocalStorage";
import { useTranslation } from "react-i18next";
import { getTemplates,bg_Hover } from "../../../hooks/templates";

const schema = yup.object().shape({
  first_name: yup.string().required("First Name is required"),
  phone: yup.string().required("Phone number is required"),
  email: yup.string().email().required("Email is required"),
  password: yup.string().min(8).max(15).required("Password is required"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const CustomerRegister = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const notify = (text) => toast(text);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submitForm = (data) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("phone", data.phone);
    formData.append("email", data.email);
    formData.append("password", data.password);
    formData.append("confirm_password", data.confirm_password);
    axios
      .post(`${API_URL}register`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        saveToLocalStorage(response?.data);
        setLoading(false);
        notify(response?.data?.message);
        navigate("/dashboard");
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.data?.code === 422) {
          if (error?.response?.data?.errors?.error) {
            notify(error?.response?.data?.errors?.error);
          }
        }
        else {
          notify(error?.response?.data?.message);
        }
        
        if (error?.response.status == 400) {
          let error_fields = error?.response?.data;
          Object.keys(error_fields)?.map((item,key)=>{
            notify(error_fields[item][0]);
          });
        }

      });
  };

  return (
    <>
      <BreadCumb data={"Register"} />
      <div className={authStyle.authContainer}>
        <div className={authStyle.auth}>
          <h4 className="text-center mb-3">{t("create_ac")}</h4>
          <hr className="mb-4" />
          <form onSubmit={handleSubmit(submitForm)}>
            <div>
              <label htmlFor="name">{t("first_name")}</label>
              <input
                type="text"
                {...register("first_name", { required: true })}
                placeholder={t("enter_first_name")}
              />
              {errors.first_name && (
                <p className="error"> {errors.first_name?.message} </p>
              )}
            </div>
            <div>
              <label htmlFor="name">{t("last_name")}</label>
              <input
                type="text"
                {...register("last_name", { required: true })}
                placeholder={t("enter_last_name")}
              />
            </div>
            <div>
              <label htmlFor="email">{t("email")}</label>
              <input
                type="email"
                id="email"
                {...register("email", { required: true })}
                placeholder={t("enter_email")}
              />
              {errors.email && (
                <p className="error"> {errors.email?.message} </p>
              )}
            </div>
            <div>
              <label htmlFor="phone">{t("phone")}</label>
              <input
                type="phone"
                {...register("phone", { required: true })}
                placeholder={t("enter_phone")}
              />
              {errors.phone && (
                <p className="error"> {errors.phone?.message} </p>
              )}
            </div>
            <div>
              <label htmlFor="password">{t("password")}</label>
              <input
                type="password"
                id="password"
                {...register("password", { required: true })}
                placeholder={t("enter_password")}
              />
              {errors.password && (
                <p className="error"> {errors.password?.message} </p>
              )}
            </div>
            <div>
              <label htmlFor="confirm-password">{t("confirm_password")}</label>
              <input
                type="password"
                id="confirm-password"
                {...register("confirm_password", { required: true })}
                placeholder={t("conf_pass")}
              />
              {errors.confirm_password && (
                <p className="error"> {errors.confirm_password?.message} </p>
              )}
            </div>
            <div className={authStyle.agree}>
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
              />
              <label className="ps-2 mt-1" htmlFor="exampleCheck1">
              {t("agree_policy")}
              </label>
            </div>
            <div>
              <button className="btn btn-primary" type="submit" disabled={loading}
              style={{
                "--bs-btn-bg" :  getTemplates().primary_color,
                "--bs-btn-hover-bg" :  bg_Hover(),
                "--bs-btn-border-color" :  getTemplates().primary_color,
                "--bs-btn-active-bg" :  getTemplates().primary_color,
                "--bs-btn-active-border-color" :  getTemplates().primary_color,
                }}
			        >
                {loading && (
                  <span className="spinner-grow spinner-grow-sm"></span>
                )}
                <FaRegEdit /> {t("register")}
              </button>
            </div>

            <div className={authStyle.alreadyAccount}>
              <p>
                {t("already_ac")}
                <NavLink to="/login"
				style={{
					color:getTemplates().primary_color
				}}
				>{t("login")}</NavLink>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CustomerRegister;
