import { useTranslation } from "react-i18next";
import { AiOutlineSearch } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { getTemplates } from "../../../../../hooks/templates";
import sidebarStyle from "./blogSidebar.module.css";
import SignleRecentPost from "./SignleRecentPost";
import {useState} from "react";

const BlogSidebar = (props) => {
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState({type:"tag",key:-1});
  const handleMouseEnter = (obj) => {
	  setIsHover({type:obj.type,key:obj.key});
   };
  return (
    <div className={sidebarStyle.BlogSidebarContainer}>
      <div className={sidebarStyle.search}>
        <input type="text" 
          placeholder={t('search')}
          name="search"
          value={props.search}
          onChange={(e)=>props.setSearch(e.target.value)} />
        <button>
          <AiOutlineSearch />
        </button>
      </div>
		{/* Categories */}
      <section>
        <div className={`${sidebarStyle.categories}${" "}${sidebarStyle.listTitle}`}>
          <h4>{t('categories')}</h4>
          {
			props.categories?.length > 0 && (
			props.categories.map((item, key) => {
				return (
						<NavLink to="#" 
						style={{
							color: (isHover.type == "category" && isHover.key == key ) && getTemplates().primary_color,
						}}
						onMouseEnter={ (e)=>{handleMouseEnter({type:"category",key:key})}}
						onMouseLeave={ (e)=>{handleMouseEnter({type:"category",key:key})}}
						onClick={(e)=>props.setCategory(item.value)} key={key}
						> {item.label} </NavLink>
					);
				})
				)
          }
        </div>
      </section>
			{/* Tags */}
			<section>
        <div className={ `${sidebarStyle.tags} ${" "} ${sidebarStyle.listTitle}` }>
          <h4>{t('Popular')} {t('tags')}</h4>
          <div className={sidebarStyle.tagList}
          >
          {
			props.popularTags.length > 0 && (
			props.popularTags.map((item, i ) => {
				return (
						<NavLink to="#" 
						style={{
							backgroundColor: (isHover.type == "tag" && isHover.key == i ) ? '#000' : getTemplates().primary_color,
							borderColor: (isHover.type == "tag" && isHover.key == i ) ? '#000' : '#fff',
							color:'#fff'
						}}
						onMouseEnter={ (e)=>{handleMouseEnter({type:"tag",key:i})}}
						onMouseLeave={ (e)=>{handleMouseEnter({type:"tag",key:i})}}
						key={i} onClick={(e)=>props.setTag(item.tag)}> {item.tag} </NavLink>
					);
				})
				)
          }
          </div>
        </div>
      </section>
      {/* Recent Posts */}
      <section>
        <div className={ `${sidebarStyle.recentPost} ${" "} ${sidebarStyle.listTitle}` }>
          <h4>{t('Recent Post')}</h4>
          <div className={sidebarStyle.recentPostWrap}>
			{
			props?.recentPosts?.length > 0 && (
			props?.recentPosts?.map((item, key) => {
					return (
							<NavLink to={`/blog/${item.id}`} key={key}
							>
								<SignleRecentPost item={item} />
							</NavLink>
						);
					})
				)
			}
          </div>
        </div>
      </section>

    </div>
  );
};

export default BlogSidebar;
