import HeaderOne from "./template/template1/HeaderOne";
import HeaderTwo from "./template/template2/HeaderTwo";

const Header = ({
  categories,
  headerLogo,
  headerIconLogo,
  languageSwitcher,
  currencySwitcher,
  user,
  language,
  filterCategories,
  phone
}) => {
  return (
	process.env.REACT_APP_MARKETPLACE_TEMPLATE == 1 ?
    <HeaderOne 
		phone={phone}
		categories={categories}
		headerLogo={headerLogo}
		headerIconLogo={headerIconLogo}
		languageSwitcher={languageSwitcher}
		currencySwitcher={currencySwitcher}
		user={user}
		language={language}
		filterCategories={filterCategories}
	 /> : 
	 <HeaderTwo 
		categories={categories}
		headerLogo={headerLogo}
		headerIconLogo={headerIconLogo}
		languageSwitcher={languageSwitcher}
		currencySwitcher={currencySwitcher}
		user={user}
		language={language}
		filterCategories={filterCategories}
	 />
  );
};

export default Header;
