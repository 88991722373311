import bulkUploadStyle from "./bulkUpload.module.css";
import DownloadCategory from "./components/downloadCategory/DownloadCategory";
import DownloadCSV from "./components/downloadCSV/DownloadCSV";
import UploadCSV from "./components/uploadCSV/UploadCSV";
import { useTranslation } from "react-i18next";

const BulkUpload = () => {
  const { t } = useTranslation();

  return (
    <div className={bulkUploadStyle.spacing}>
      <div className={bulkUploadStyle.topSection}>
        <h5>{t('bulk_pro_upload')}</h5>
      </div>
      <DownloadCSV />
      {/* <DownloadCategory /> */}
      <UploadCSV />
    </div>
  );
};

export default BulkUpload;
