import { banner_img1 } from "../../../../../../../assets";
import { NavLink } from "react-router-dom";
import "./Banner.scss";

const Banner = ({bannerOne}) => {
	const banner1Image = process.env.REACT_APP_CURRENT_ENV =="demo" ? banner_img1 : bannerOne.image;
	return (
		<div className="col-lg-4 col-sm-12 col-padding">
			<div className="banner-wrapper">
				<div className="heading-wrap">
					<h2 className="banner-title">{bannerOne?.title}</h2>
					<h2 className="banner-subtitle">{bannerOne?.first_line}</h2>
					<p className="banner-desc">
						{bannerOne?.second_line}
					</p>
				</div>
				<div className="offer-area">
					<div className="offer-wrap">
						<p>Up To</p>
						<span className="value">30</span>
						<span className="percentage">%</span>
						<span className="text">OFF</span>
					</div>
				</div>
				<div className="banner-image">
					<img src={ banner1Image } alt={bannerOne?.first_line} />
				</div>
				<div className="button-wrap text-center">
					<NavLink to={bannerOne?.button_url} className="shop-btn">
						{bannerOne?.button_text}
						<i className="fa fa-angle-right" aria-hidden="true"></i>
					</NavLink>
				</div>
			</div>
		</div>
	);
};

export default Banner;
