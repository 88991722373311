import React from "react";
import topSecStyle from "./topSec.module.css";
import { Link } from "react-router-dom";
import { loadFromLocalStorage } from "../../../../../utils/seller/manageLocalStorage";
import { useTranslation } from "react-i18next";

const TopSec = () => {
	const { t  } = useTranslation();
	const user = loadFromLocalStorage();
	return (
		<div className={topSecStyle.addBtnParent}>
			<h5>{t('products')}</h5>
			{
				user?.verified ?
				(
					<Link className="btn btn-primary" to="/seller/products/add">{t('add_pro')}</Link>
				)
				:
				(
					<p>{t('vendor_verified')}</p>
				)
			}
		</div>
	);
};

export default TopSec;
