import "./topBar.scss";
import { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../../../../pages/customer/services/auth.service";
import { NavLink } from "react-router-dom";
import { loadFromLocalStorage as loadSellerData } from "../../../../../../utils/seller/manageLocalStorage";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../../../../../../redux/slices/auth";
import { useTranslation } from "react-i18next";
import { bg_Hover,getTemplates } from "../../../../../../hooks/templates";

const TopBar = () => {
	const { t, i18n } = useTranslation();
	const { user, isLoggedin } = useSelector((state) => state?.authSlice);
	const [defaultLang, setDefaultLang] = useState(JSON.stringify({ code: "en-US", currency: "USD" }));
	const dispatch = useDispatch();
  
	const languages = [
	  { label: "EN", value: { code: "en-US", currency: "USD" }, code: "en" },
	  { label: "FR", value: { code: "de-DE", currency: "EUR" }, code: "fr" },
	];
  
	const currency = [
	  { label: "USD", value: "USD" },
	  { label: "EUR", value: "EUR" },
	];
  
	// Logout
	const navigate = useNavigate();
	const logout = () => {
	  navigate("/login");
	  AuthService.logout();
	  dispatch(setAuth({ isLoggedin: false, user: null }));
	};
  
	const seller = loadSellerData();
  
	  useEffect(() => {
	  selectedLang();
	  },[]);
  
	const changeLanguage = (lang) => {
	  let getValue = JSON.parse(lang.target.value)
	  localStorage.setItem("frontendLang", lang.target.value);
	  i18n.changeLanguage(getValue.code);
	  selectedLang();
	};
  
	const selectedLang = () =>{
	  let getLang = localStorage.getItem("frontendLang");
	  getLang = getLang == null ?  "en" : getLang;
  
	  setDefaultLang( getLang == null ?  "en" : getLang );
	}
  
	const changeCurrency = (currency) => {
	  var getCurrency = localStorage.getItem("frontendLang");
	  getCurrency = getCurrency
		? JSON.parse(getCurrency)
		: { code: "en", lang: "en-US", currency: "USD" };
	  getCurrency.currency = currency;
	  localStorage.setItem("frontendLang", JSON.stringify(getCurrency));
	};

	return (
		<section className="topbar">
			<div className="row container-fluid">
				<div className="col-lg-6 col-md-6 col-sm-12">
					<ul className="top-left-list">
						<li><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"/></svg> Free european shipping over 99€</li>
					</ul>
				</div>
				<div className="col-lg-6 col-md-6 col-sm-12">
					<ul className="list-inline float-end">
						{
						process.env.REACT_APP_MARKETPLACE_TYPE == "marketplace" &&
							<li>
							<NavLink to={seller ? "/seller" : "/seller-request"}>
								{seller ? t('seller_dash') : t('be_seller') }
							</NavLink>
							</li>
						}
						<li>
							{isLoggedin ? (
							<NavLink to="/dashboard">
								{`${user?.user?.first_name} ${user?.user?.last_name}`}
							</NavLink>
							) : (
							<NavLink to="/register">{t('register')}</NavLink>
							)}
						</li>
						<li>
							{isLoggedin ? (
							<NavLink to="/login" onClick={logout}>
								{t('logout')}
							</NavLink>
							) : (
							<NavLink to="/login">{t('login')}</NavLink>
							)}
						</li>
						<li>
							<NavLink to="/support">{t('support')}</NavLink>
						</li>
						{/* {
							process.env.REACT_APP_CURRENT_ENV == "demo" && 
							(
							<li>
								<button className="btn btn-primary"
								style={{
								"--bs-btn-bg" :  getTemplates().primary_color,
								"--bs-btn-hover-bg" :  bg_Hover(),
								"--bs-btn-border-color" :  getTemplates().primary_color,
								"--bs-btn-active-bg" :  getTemplates().primary_color,
								"--bs-btn-active-border-color" :  getTemplates().primary_color,
								"padding": "0px 15px"
								}} ><a href="https://markutos.com">{t('buy')} {t('now')}</a></button>
							</li>
							)
						} */}
					</ul>
				</div>
			</div>
		</section>
	);
};

export default TopBar;
