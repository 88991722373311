import React from "react";
import topSecStyle from "./topSec.module.css";
import { NavLink } from "react-router-dom";
import { loadFromLocalStorage } from "../../../../../utils/seller/manageLocalStorage";
import { useTranslation } from "react-i18next";

const TopSecDigital = () => {
	const { t  } = useTranslation();
	const user = loadFromLocalStorage();
	return (
		<div>
		<div className={topSecStyle.addBtnParent}>
			<h5>{t('digital_pro')}</h5>
			{
				user?.verified ?
				(
					<NavLink className="btn btn-primary" to="/seller/products/digital/add">{t('add_digi_pro')}</NavLink>
				)
				:
				(
					<p>{t('vendor_verified')}</p>
				)
			}
		</div>
		</div>
	);
};

export default TopSecDigital;
