import { useEffect, useState } from "react";
import BreadCumb from "../../../common/breadcumb/BreadCumb";
import SortFilter from "./components/storesCom/SortFilter";
import StoreList from "./components/storesCom/StoreList";
import { markutosFrontendApi } from "../../services/Api/api";
import FrontendPagination from "../../../common/pagination/frontend/FrontendPagination";

const Stores = () => {
  	const [layout, setLayout] = useState("grid");
	const [loading, setLoading] = useState(false);
	const [perPage, setPerPage] = useState(10);
	const [search, setSearch] = useState("");
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalStores, setTotalStores] = useState(1);

	const getData=({search="",currentPage=1})=>{
		var item_value = sessionStorage.getItem("MARCATUEX_STORES");
		if ( item_value && search == "" ) {
		const temp = JSON.parse(sessionStorage.getItem("MARCATUEX_STORES") )  || [];
		setCurrentItems(temp?.data);
		setCurrentPage(temp?.current_page);
		setPageCount(temp?.last_page);
		setTotalStores(temp?.total);
		} else {
		markutosFrontendApi
		.get(`/all-stores?per_page=${perPage}&search_value=${search}&per_page=${perPage}&page=${currentPage}`)
		.then((response) => {
			sessionStorage.setItem("MARCATUEX_STORES",JSON.stringify(response?.data));
			setCurrentItems(response?.data?.data);
			setCurrentPage(response?.data?.current_page);
			setPageCount(response?.data?.last_page);
			setTotalStores(response?.data?.total);
		});
		}

	}

	useEffect(() => {
		window.onload = function() {
		sessionStorage.removeItem("MARCATUEX_STORES");
		};
		getData({search:search,currentPage:currentPage});
	}, [search,currentPage]);

	return (
		<div className="wrapper">
		<BreadCumb data={"Stores"} />
		<div className="container mb-5">
			<SortFilter setLayout={setLayout} search={search} setSearch={setSearch}  totalStores={totalStores} />
			<StoreList loading={loading} layout={layout} currentItems={currentItems} />
			{currentItems.length > 0 && (
				<FrontendPagination
					currentItem={currentItems}
					perPage={perPage}
					pageCount={pageCount}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
				/>
			)}
		</div>
		</div>
	);
};

export default Stores;
