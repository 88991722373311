import {useEffect,useState} from "react";
import { useFieldArray } from "react-hook-form";
import { BsChevronUp } from "react-icons/bs";
import UploadFilesModal from "../../UploadFiles/UploadFilesModal";
import { BASE_URL } from "../../../services/Api/api";
import { useTranslation } from "react-i18next";

const Sliders = ({ control, register, setValue, getValues , reset , adding , setAdding }) => {
	const { t  } = useTranslation();
	const [show, setShow] = useState(false);
	const [imageFor, setImageFor] = useState("");
	const [format, setFormat] = useState("");
	const [currentIndex, setCurrentIndex] = useState(0);
	const openThumbnail = () => {
		setImageFor("sliders");
		setFormat("string");
		setShow(!show);
	};
	const { fields, append, remove } = useFieldArray({
		control,
		name: "sliders",
	});

	useEffect(() => {
		if (adding == false) {
		reset(getValues());
		}
	}, [show,adding]);

  return (
    <>
      <div className="table-top-header">
        <div className="table-title">{t('homepage_slider_conf')}</div>
        <button
			className="btn btn-outline-success"
			onClick={() => {
				setAdding(true);
				append({ button_text: "Shop Now" });
			}}
        >
          {t('add')+` `} {t('slider')}
        </button>
      </div>
      <div className="widget-container">
        {fields.map((item, index) => {
          return (
            <div className="hs-single-slider" key={item.id}>
              <div className="hs-slider-header">
                <div className="hs-slider-title">{t('slider')+` `} #{index + 1}</div>
                <div className="hs-slider-hide">
				          {t('hide')} <BsChevronUp />
                </div>
                {/* <div className="hs-slider-hide">
                  Hide <HiChevronDown />
                </div> */}
                <button
                  className="hs-slider-delete-btn"
                  type="button"
                  onClick={() => remove(index)}
                >
                  {t('delete')}
                </button>
              </div>
              <div className="hs-slider-preview">
                <img src={BASE_URL+item?.image} alt={item?.image} width={"100%"} height={"300px"} />
              </div>
              <div className="hs-slider-fields-container">
                <label htmlFor="">{t('chose_slider_img')}</label>
                  <div onClick={(e)=>{openThumbnail();setCurrentIndex(index)}} className="custom-browse">
                    <div>{t('browse')}</div>
                  <div>
                  {item?.image ? BASE_URL+item?.image : t('choose_file')}
                </div>
              </div>
                <label htmlFor="">{t('title')}</label>
                <input
                  type="text"
                  {...register(`sliders.${index}.title`)}
                />
                {/* <label htmlFor="">First Line</label>
                <input
                  type="text"
                  {...register(`sliders.${index}.first_line`)}
                /> */}
                {/* <label htmlFor="">First Line Color</label>
                <input
                  type="color"
                  {...register(`sliders.${index}.fl_color`)}
                />
                <label htmlFor="">Second Line</label>
                <input
                  type="text"
                  {...register(`sliders.${index}.second_line`)}
                />
                <label htmlFor="">Second Line Color</label>
                <input
                  type="color"
                  {...register(`sliders.${index}.sl_color`)}
                /> */}
                {/* <label htmlFor="">Button Text</label>
                <input
                  type="text"
                  {...register(`sliders.${index}.button_text`)}
                />
                <label htmlFor="">Button Color</label>
                <input
                  type="color"
                  {...register(`sliders.${index}.button_color`)}
                /> */}
                <label htmlFor="">{t('button_link')}</label>
                <input
                  type="text"
                  {...register(`sliders.${index}.link`)}
                />
              </div>
            </div>
          );
        })}
        <UploadFilesModal
          setFieldValue={setValue}
          format={format}
          values={fields}
          imageFor={imageFor}
          show={show}
          setShow={setShow}
          fieldsIndex={currentIndex}
        />
      </div>
    </>
  );
};

export default Sliders;
