import React, { Fragment } from "react";
import { Table } from "react-bootstrap";
import { AiOutlineDelete, AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { priceFormat } from "../../../../../hooks/helper";
import { NavLink } from "react-router-dom";
import cartStyle from "./cart.module.css";
import Variations from "../../../miniCart/components/cartCom/Variations";
import { getTemplates , getTemplatesNo } from "../../../../../hooks/templates";

const ProductTable = ({totalUniqueItems,items,updateItemQuantity,removeItem, language , t }) => {

	return (
		<div className={cartStyle.productTable}>
		<p>{t('total')} ({totalUniqueItems}) {t('items')}</p>
		<Table bordered responsive className={`${cartStyle.carttable}`}>
			<thead className="thead-light" style={{ background : getTemplatesNo == 1 ? "#d9e8fb" : "" }}  >
			<tr>
				<th scope="col">{t('product')}</th>
				<th scope="col">{t('price')}</th>
				<th scope="col">{t('quantity')}</th>
				<th scope="col">{t('total')}</th>
				<th scope="col"></th>
			</tr>
			</thead>
			<tbody>
			{items.map((item, key) => {
			return (
			<tr key={key}>
				<td className="py-3">
				<NavLink to={`/products/${item.slug}`} style={{ color:getTemplates().primary_color }}>{item.name}</NavLink>
				<Variations item={item}/>
				{
					process.env.REACT_APP_MARKETPLACE_TYPE == "marketplace" &&
					<div><span className="me-1">{t('sold_by')}: </span><span>{item.shop_name}</span></div>
				}
				</td>
				<td className="py-3">
				{
					item.product_type !== "variation" ?
					(
					<Fragment>
						<span className={ item.discount_price ? cartStyle.del : '' }>{priceFormat(item.unit_price,language)}</span>{priceFormat(item.discount_price,language)}
					</Fragment>
					) :
					( priceFormat(item.price,item.product_type,language) )
				}
				</td>
				<td className="py-3">
				<div className={cartStyle.quantity}>
					<button onClick={() => updateItemQuantity(item.id, item.quantity - 1)}>
					<AiOutlineMinus />
					</button>
					<span> {item.quantity} </span>
					<button onClick={() => updateItemQuantity(item.id, item.quantity + 1)}>
					{" "}
					<AiOutlinePlus />{" "}
					</button>
				</div>
				</td>
				<td className="py-3">{priceFormat(item.itemTotal,language)}</td>
				<td className="py-3">
				<button className={cartStyle.deleteBtn} onClick={() => removeItem(item.id) }>
					{" "}
					<AiOutlineDelete />{" "}
				</button>
				</td>
			</tr>
			);
					})}
			</tbody>
		</Table>
		</div>
	);
};

export default ProductTable;
