import React, { Fragment } from "react";
import SentRefund from "./components/SentRefund";

const SentRefundReq = () => {
  return (
    <Fragment>
      <SentRefund />
    </Fragment>
  );
};

export default SentRefundReq;
