import { NavLink } from "react-router-dom";
import { banner_img2 } from "../../../../../../../assets";
import "./Banner.scss";

const Banner = ({bannerTwo}) => {
	const banner2Image = process.env.REACT_APP_CURRENT_ENV =="demo" ? banner_img2 : bannerTwo.image;
	return (
		<div className="col-lg-4 col-sm-12 col-padding">
			<div className="banner-wrapper style-2">
				<div className="heading-wrap">
					<h2 className="banner-title">{bannerTwo?.title}</h2>
					<h2 className="banner-subtitle">{bannerTwo?.first_line}</h2>
					<p className="banner-desc">{bannerTwo?.second_line}</p>
				</div>
				<div className="button-wrap text-left">
					<NavLink to={bannerTwo?.button_url} className="shop-btn">
						{bannerTwo?.button_text}
						<i className="fa fa-angle-right" aria-hidden="true"></i>
					</NavLink>
				</div>
				<div className="banner-image">
					<img src={banner2Image} alt={bannerTwo?.first_line} />
				</div>
			</div>
		</div>
	);
};

export default Banner;
