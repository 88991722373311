import Header from "../bestSellingProducts/Header";
import "./moreProducts.scss";
import ProductsBlock from "./ProductsBlock";

const MoreProducts = ({dailyDeals,bannerTwo}) => {
	return (
		<section className="content-block">
			<Header title={"Most Viewed Product"} url={`/shop/${dailyDeals['all']?.category_slug}`}/>
			<ProductsBlock dailyDeals={dailyDeals} bannerTwo={bannerTwo} />
		</section>
	);
};

export default MoreProducts;