import { Fragment } from "react";
import { useCart } from "react-use-cart";
import { priceFormat } from "../../../../../hooks/helper";
import { useDispatch, useSelector } from "react-redux";
import { setCompare } from "../../../../../redux/slices/compare";
import { setQuickView } from "../../../../../redux/slices/quickView";
import { useTranslation } from "react-i18next";

const CompareTable = () => {
  const { t,i18n } = useTranslation();
  const { products: comparedProds } = useSelector((state) => state?.compare);
  const dispatch = useDispatch();
  const { addItem, onItemAdd } = useCart();

  const handleDelete = (id) => {
    let newProducts = comparedProds.filter((prod) => prod.id !== id);
    dispatch(setCompare({ open: false, products: newProducts }));
    localStorage.setItem("compared-prods", JSON.stringify(newProducts));
  };

  const handleAddToCart = (product) => {
    // add to cart
    if (product.product_type !== "variation") {
      addItem(product);
    } else {
      dispatch(setQuickView({ open: true, product }));
    }
  };

  return comparedProds ? (
    <Fragment>
        <h1 className="text-center my-4 mb-5">{t('compare_list')}</h1>
       <div className="comparison-table-wrap">
        <table className="compare-table">
            <tbody>
              <tr>
                <td>{t('image')}</td>
                {comparedProds?.map((p, key) => (
                  <td style={{ width: `${100 / comparedProds?.length}%` }} key={key}>
                    <img className="compare-table-img" src={p.thumbnail_img} alt="" />
                  </td>
                ))}
              </tr>
              <tr>
                <td>{t('name')}</td>
                {comparedProds?.map((p, i) => (
                  <td key={i}>{p.name}</td>
                ))}
              </tr>
              <tr>
                <td>{t('price')}</td>
                {comparedProds?.map((p, i) => (
                  <td key={i}>
                    {
                      p.product_type !== "variation" ?
                      (
                        <Fragment>
                          <bdi>{priceFormat(p.discount_price,i18n.language,p.product_type)}</bdi>
                          <del className={p?.discount_price ? " del ms-1" : "ms-1"}>
                            {priceFormat(p.unit_price,i18n.language,p.product_type)}
                          </del>
                        </Fragment>
                      ):
                      (
                        <Fragment>
                          <bdi>{priceFormat(p.discount_price_range,i18n.language,p.product_type)}</bdi>
                        </Fragment>
                      )
                    }
                  </td>
                ))}
              </tr>
              <tr>
                <td>{t('add_to_cart')}</td>
                {comparedProds?.map((p, k) => (
                  <td key={k}>
                    <div
                      className="qt-add-to-cart-btn"
                      onClick={() => handleAddToCart(p)}
                    >
                      {t('add_to_cart')}
                    </div>
                  </td>
                ))}
              </tr>
              <tr>
                <td>{t('description')}</td>
                {comparedProds?.map((p) => (
                  <td>{p.description}</td>
                ))}
              </tr>
              <tr>
                <td>{t('availability')}</td>
                {comparedProds?.map((p) => (
                  <td
                    style={{ color: p.stock_status === "In-Stock" ? "green" : "red" }}
                  >
                    {p.stock_status}
                  </td>
                ))}
              </tr>
              <tr>
                <td>{t('sku')}</td>
                {comparedProds?.map((p, i) => (
                  <td>-</td>
                ))}
              </tr>
              <tr>
                <td>{t('brands')}</td>
                {comparedProds?.map((p) => (
                  <td>-</td>
                ))}
              </tr>
              <tr>
                <td></td>
                {comparedProds?.length ? (
                  comparedProds?.map((p) => (
                    <td>
                      <button
                        className="ct-remove-btn"
                        onClick={() => handleDelete(p.id)}
                      >
                        {t('remove')}
                      </button>
                    </td>
                  ))
                ) : (
                  <id></id>
                )}
              </tr>
            </tbody>
          </table>
       </div>
    </Fragment>
  ) : (
    <div className="ct-null-container">
      <div>There is no product to compare</div>
    </div>
  );
};

export default CompareTable;
