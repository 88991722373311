import React from "react";
import VerticalCard from "../prodsCard/VerticalCard";
import "./product.scss";

const GridView = ({ products }) => {
  return (
    <div className="prods-grid-view-container">
      {products && products.map((prod,key) => <VerticalCard key={key} product={prod} />)}
    </div>
  );
};

export default GridView;
