/**
 * get current template no
 */
 export const getTemplatesNo = () =>{
	return process.env.REACT_APP_MARKETPLACE_TEMPLATE;
}
/**
 * get current templates
 */
 export const getTemplates = () =>{
	var obj = {
		primary_color :"#0c6adf",
	}
	if ( process.env.REACT_APP_MARKETPLACE_TEMPLATE == 1 ) {
		obj.primary_color = "#F84D17";
	}
	else if ( process.env.REACT_APP_MARKETPLACE_TEMPLATE == 2 ) {
		obj.primary_color = "#0c6adf";
	}

	return obj;
}
var styles = getTemplates();
var templateNo = getTemplatesNo();
/**
 * get current bg color
 */
export const bg_Style = {
	background: styles.primary_color ,
	borderColor: styles.primary_color ,
	color: '#fff' ,
};
/**
 * get current color
 */
export const color_Style = {
	color: styles.primary_color ,
};
/**
 * get current hover color
 */
export const color_Hover =()=> {
	var color = "#fff";
	if ( templateNo == 2 ) {
		color = "#fff"
	}
	return color;
};
/**
 * get current hover bg color
 */
 export const bg_Hover =()=> {
	var color = styles.primary_color;
	if ( templateNo == 2 ) {
		color = "#000"
	}
	return color;
};
/**
 * get pagination
 */
 export const pagination_bg_Color =()=> {
	var paginate = {
		color : "#fff",
	}
	if ( templateNo == 1 ) {
		paginate.color = "#fff"
	}
	else if ( templateNo == 2 ) {
		paginate.color = "#fff"
	}
	return paginate;
};
