import { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import checkoutStyle from "./checkout.module.css";
import { AiOutlineArrowLeft, AiOutlineCheckCircle } from "react-icons/ai";
import { useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import { toast } from "react-toastify";
import { markutosFrontendApi } from "../../../../services/Api/api";
import { addOrderSchema } from "../../../../../schema/addOrderSchema";
import { loadFromLocalStorage } from "../../../../../utils/user/manageLocalStorage";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { bg_Hover, getTemplates } from "../../../../../hooks/templates";

import {
	PaymentElement,
	CardElement,
	Elements,
	useStripe,
	useElements,
} from '@stripe/react-stripe-js';

import Stripe from "../paymentMethod/stripe/Stripe";
import { getFrontLang } from "../../../../../hooks/helper";

const CheckoutForm = ({storesCart, finalTotal, cartTotal,metadata,shippingCharge , shippingMethod }) => {
	const { t } = useTranslation();

	// Stripe start
	const stripe = useStripe();
	const elements = useElements();
	// Stripe end

    const { paymentMethods } = useSelector((state) => state.additionalPrice );
	const [paymentMethod, setPaymentMethod] = useState('cod');
	const user = loadFromLocalStorage();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [paymentError, setPaymentError] = useState("");
	const [paymentStripe, setPaymentStripe] = useState(false);

	const stripeMethod = ()=>{
		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}
		
		setLoading(true);

		stripe.createToken(elements.getElement(CardElement)).then(function(result) {
			// Handle result.error or result.token
			setLoading(false);
			if (result?.token) {
				let cardData = {
					fullName: values.name,
					month: result?.token?.card?.exp_month || "",
					year:result?.token?.card?.exp_year || "",
					tokenID:result?.token?.id
				};
				let data = {
					payment_method_types: ['card'],
					currency: getFrontLang().currency,
					amount: finalTotal,
					card_data: cardData,
				};
				// create payment call
				markutosFrontendApi
				.post("/stripe/create-payment", data )
				.then((res) => {
					if (res?.data?.result == "false") {
						setPaymentError(res?.data?.result?.message);
					}else{
						setPaymentError("");
					}
					setPaymentStripe(res?.data);
					return res?.data;
				});
			}else{
				setPaymentStripe(result);
			}

		});
	}

	const formik = useFormik({
			validationSchema: addOrderSchema,
			initialValues: {
			name: "",
			email: "",
			phone: "",
			address: "",
			},
			enableReinitialize: true,
			onSubmit: (values, action) => {
			const finalValues = values;

			let shipping_info = {
				"name": values.name,
				"email": values.email,
				"phone": values.phone,
				"address": values.address
			};

			finalValues.user_id = user ? user?.user?.id : 0;
			finalValues.orders = storesCart;
			finalValues.payment_method  =  paymentMethod;
			finalValues.order_notes     =  values.order_notes ? values.order_notes : '';
			finalValues.subtotal        =  cartTotal;
			finalValues.shipping_info   =  shipping_info;
		
			if (metadata?.coupon) {
				finalValues.coupon_discount =  metadata.coupon;
			}else{
				finalValues.coupon_discount   =  0;
			}

			finalValues.total           =  finalTotal;
			finalValues.tax   			=  storesCart.reduce((accumulator, object) => {
				return accumulator + object.tax;
			}, 0);

			finalValues.shipping_method =  shippingMethod;
			finalValues.shipping_charge =  shippingCharge;

			if ( paymentMethod == "stripe" ) {
				stripeMethod();
				if (paymentStripe?.result == "true") {
					finalValues.payment_status = paymentStripe?.charge?.paid == true ? "Paid" : "UnPaid";
				}else{
					finalValues.payment_status = "Unpaid";
					setPaymentError(paymentStripe?.error?.message);
					return;
				}
			}
			else if (paymentMethod == "cod" ) {
				finalValues.payment_status = "Unpaid";
			}
			
			setLoading(true);

			markutosFrontendApi
				.post("/checkout/post", finalValues )
				.then((res) => {
				setLoading(false);

				toast.success(res.data.message);
				action.resetForm();
				navigate('/thank-you',{state: {...res.data}});
				})
				.catch((e) => {
				setLoading(false)
				toast.error(e.message);
			});
		},
	});

  const {
      values,
      setErrors,
      handleChange,
      touched,
      errors,
      handleSubmit,
      handleBlur,
      setFieldValue,
  } = formik;

  return (
    <div className="">
      <h4 className="mb-4">{t('shipping_info')}</h4>
		<form onSubmit={(e) => e.preventDefault()}>
			<FocusError formik={formik} />
			{/* Billing details */}
			<div className={checkoutStyle.name}>
				<label htmlFor="customer_name"> {t('name')} : <i>*</i> </label>

				<input
					type="text"
					placeholder={t('enter_name')}
					name="name"
					id="customer_name"
					value={values.name}
					onChange={handleChange}
					onBlur={handleBlur}
				/>

				{errors.name && touched.name && (
					<small className="text-danger"> {errors.name} </small>
				)}

			</div>
			<div className={checkoutStyle.emailPhone}>
				<div className={checkoutStyle.email}>
					<label htmlFor="email"><span>{t('email')} : </span><i>*</i></label>
					<input
						type="email"
						name="email"
						id="email"
						placeholder={t('enter_email')}
						value={values.email}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
					{errors.email && touched.email && (
						<small className="text-danger"> {errors.email} </small>
					)}
				</div>

				<div className={checkoutStyle.phone}>
				<label htmlFor="phone"><span>{t('phone')} :</span><i>*</i></label>
				<input
						type="tel"
						name="phone"
						id="phone"
						placeholder={t('enter_phone')}
						value={values.phone}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
					{errors.phone && touched.phone && (
						<small className="text-danger"> {errors.phone} </small>
					)}
				</div>
			</div>
			<div className={checkoutStyle.address}>
				<label htmlFor="address"> {t('address')} : </label>
				<textarea name="address" id="address" rows={'5'} cols={'5'}
					value={values.address}
					onChange={handleChange}
					onBlur={handleBlur}
				></textarea>
				{errors.address && touched.address && (
					<small className="text-danger"> {errors.address} </small>
				)}
			</div>

			{/* Payment method */}
			<div className="my-3">
				<Fragment>
					<h4 className="mt-4">{t('payment_method')}</h4>
					{/* Payment methods */}
					{
						paymentMethods.length > 0 && (
							paymentMethods.map((item,i)=>{
								return(
									<div className="form-check" key={i}>
										<input
											className={"form-check-input my-2"}
											style={{ 
												backgroundColor : paymentMethod == item.value && getTemplates().primary_color,
												borderColor : paymentMethod == item.value && getTemplates().primary_color,
											 }}
											type="radio"
											name="flexRadioDefault"
											id={item.value}
											value={item.value}
											onChange={(e)=>setPaymentMethod(e.target.value)}
											checked={ paymentMethod == item.value ? 'checked' : ''}
										/>
										<label className="form-check-label ms-2 my-2" htmlFor={item.value}>
											{item.label}
										</label>
									</div>
								)
							})
						)
					}
					{
						paymentMethod == "stripe" && (
							<Fragment>
								{/* Stripe */}
								<Stripe/>
							</Fragment>
						)
					}

				</Fragment>
			</div>

			{/* Order Notes */}
			<div className="">
				<label htmlFor="order_notes"> {t('order_notes')} : </label>
				<textarea
					rows="5"
					cols="5"
					placeholder={t('order_notes_place')}
					id="order_notes"
				></textarea>
			</div>

			{/* Submit action */}
			<div className={checkoutStyle.submitBtn}>
				<NavLink to="/cart"
				style={{ 
					color: getTemplates().primary_color,
				}} 
				>
					<AiOutlineArrowLeft /> {t('back_cart')}
				</NavLink>
				<div className="d-flex justify-content-end">
					<button
						onClick={handleSubmit}
						className="btn btn-primary"
						type="submit"
						name="button"
						disabled={loading || ( paymentMethod == "stripe" && ( !stripe || !elements) )}
						style={{
							"--bs-btn-bg" :  getTemplates().primary_color,
							"--bs-btn-hover-bg" :  bg_Hover(),
							"--bs-btn-border-color" :  getTemplates().primary_color,
							"--bs-btn-active-bg" :  getTemplates().primary_color,
							"--bs-btn-active-border-color" :  getTemplates().primary_color,
							}}
					>
					{loading && (
						<span className="spinner-grow spinner-grow-sm"></span>
					)}
						<AiOutlineCheckCircle /> {t('checkout')}
					</button>
					{ paymentError ==! "" && (
						<small className="text-danger"> { paymentError } </small>
					)}
				</div>
			</div>

      	</form>
    </div>
  );
};

export default CheckoutForm;
