import { Fragment,useState } from "react";
import {
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineShoppingCart,
  AiOutlineMinus,
  AiOutlinePlus,
  AiFillHeart,
} from "react-icons/ai";
import { GiUnbalanced } from "react-icons/gi";
import "./singleCom.scss";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { priceFormat } from "../../../../../hooks/helper";
import { useCart } from "react-use-cart";
import Variation from "./Variation";
import Rating from "react-rating";
import { BsStar, BsStarFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerWishlist } from "../../../../../redux/slices/wishlist";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {getTemplates,bg_Hover} from "../../../../../hooks/templates"

const ProductDetails = ({ loading, singleProduct }) => {
	const { t, i18n } = useTranslation();

	const [variantSlug, setVariantSlug] = useState('');

	const { addItem, updateItemQuantity, getItem , items } = useCart();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { products: wishlistedProds } = useSelector(
		(state) => state?.customerWishlist
	);

	const getSlug = () => {
		var slug = variantSlug;
		if ( singleProduct?.product_type == "variation" ) {
			const found = items?.find(element => element?.selectedVariantSlug == variantSlug );
			if ( found ) {
				slug = variantSlug;
			} else {
				// slug
				slug = "";
			}
		}

		return slug;
	}

	const myItem = loading === false && getItem(`${singleProduct.id}${variantSlug}`) ;

	const isWishlistedProd = (prod) => {
		return wishlistedProds?.some((wp) => {
		return wp.id === prod.id;
		});
	};

	/**
	 * Add to wishlist
	 */
	const addToWishlist = () => {
		let newWishlistedProds;
		if (isWishlistedProd(singleProduct)) {
		newWishlistedProds = wishlistedProds?.filter(
			(wp) => wp.id !== singleProduct.id
		);
		} else {
		newWishlistedProds = [...wishlistedProds, singleProduct];
		}
		dispatch(
		setCustomerWishlist({
			count: newWishlistedProds.length,
			products: newWishlistedProds,
		})
		);
		localStorage.setItem("my-wishlist", JSON.stringify(newWishlistedProds));
	};

	/**
	 * Add to Cart
	 */
	const addToCart = (product) => {
		let newProduct = {
			...product
		};
		newProduct.id = `${product.id}${variantSlug}`;
		newProduct.total = parseFloat(product.price) - parseFloat(product.discount);

		addItem(newProduct);
	};

  return loading ? (
    <SkeletonTheme height={50}>
      <p>
        <Skeleton count={5} />
      </p>
    </SkeletonTheme>
  ) : (
    <Fragment>
      <div className="col-12 mb-md-2">
        <div className="product-details-container">
          <h2 className="product-title">{singleProduct.name}</h2>
          <div className="ratings d-flex">
            <Rating
              style={{ color: getTemplates().primary_color, fontSize: "14px" }}
              initialRating={singleProduct?.avg_rating}
              readonly
              emptySymbol={<BsStar className="icon" />}
              fullSymbol={<BsStarFill className="icon" />}
            />
            <span className="ms-2">({singleProduct?.total_rating} {t('reviews')})</span>
          </div>
          {singleProduct.product_type !== "variation" ? (
            <div
              style={{
                marginTop: "-10px",
              }}
              className="price"
            >
              <span className="sale">
                {priceFormat(singleProduct.discount_price,i18n.language)}
              </span>
              <span
                className={singleProduct?.discount_price ? " del ms-2" : ""}
              >
                {priceFormat(singleProduct.unit_price,i18n.language)}
              </span>
            </div>
          ) : (
            priceFormat(singleProduct.discount_price_range,i18n.language,"variation")
          )}
          <p className="excerpt">{singleProduct.short_description}</p>
          <div className="product-meta d-block">
            <span className="label me-1">{t('availability')} :</span>
            <span className="content">{singleProduct.avability}</span>
          </div>
          
          {/* Message to seller */}
          {
          process.env.REACT_APP_MARKETPLACE_TYPE == "marketplace" &&
            <div className="product-meta d-flex align-items-center">
              <div className="d-block">
                <span className="label me-1">{t('sold_by')} : </span>
                <span>{singleProduct.shop_name}</span>
              </div>
              <button
                className="btn ms-0 ms-md-5 btn-primary search-button product-button"
                style={{
                  "--backgroundColor": getTemplates().primary_color,
                  "--backgroundHover": bg_Hover() ,
                  "--bs-btn-bg" :  getTemplates().primary_color,
                }}
                onClick={() =>
                  navigate("../messege-seller", {
                    state: { shop_slug: singleProduct.shop_slug },
                  })
                }
              >
                <AiOutlineMessage className="me-1" /> {t('message')} {t('seller')} 
              </button>
            </div>
          }
          
          {singleProduct.product_type == "variation" && (
            <Variation
              choseOptions={singleProduct.choice_options}
              colors={singleProduct.colors}
              singleProduct={singleProduct}
              setVariantSlug={setVariantSlug}
              variantSlug={variantSlug}
            />
          )}
          
          <div className="quantity">
            <button
              onClick={() =>
                updateItemQuantity(
					      `${singleProduct?.id}${getSlug()}`,
                  myItem?.quantity && myItem?.quantity - 1
                )
              }
            >
              <AiOutlineMinus />
            </button>
            <span>
              {" "}
              {myItem?.quantity ? myItem?.quantity : singleProduct.min_qty}{" "}
            </span>
            <button
              onClick={() =>
                updateItemQuantity(
				        `${singleProduct?.id}${getSlug()}`,
                  myItem?.quantity
                    ? myItem?.quantity + 1
                    : singleProduct.min_qty + 1
                )
              }
            >
              {" "}
              <AiOutlinePlus />{" "}
            </button>
          </div>
          <div className="cart-buttons-wrap">
            <button
              className="btn btn-primary product-button"
              style={{
                "--bs-btn-bg" :  getTemplates().primary_color,
                "--bs-btn-hover-bg" :  bg_Hover(),
                "--bs-btn-border-color" :  getTemplates().primary_color,
              }}
              onClick={(e) => addToCart(singleProduct)}
            >
              <AiOutlineShoppingCart className="me-1" />{" "}
              <small>{t('add_to_cart')}</small>
            </button>
          </div>
          <div className="wishlist-compare-wrap" style={{'--color': bg_Hover.color}}>
            <button className="btn me-3 ">
              <GiUnbalanced />
              <span className="ms-1">{t('add_compare')}</span>
            </button>
            <button className="btn ps-2" onClick={addToWishlist}>
              {isWishlistedProd(singleProduct) ? (
                <>
                  <AiFillHeart color="red" /> {t('wishlisted')}
                </>
              ) : (
                <>
                  <AiOutlineHeart /><span className="ms-1">{t('add_wishlist')}</span> 
                </>
              )}
            </button>
          </div>
          {/* <div className="social-share-wrap">
            <p>{t('share')} : </p>
            <a href="#" target="_blank">
              <FaTwitter />
            </a>
            <a href="#" target="_blank">
              <FaLinkedin />
            </a>
            <a href="#" target="_blank">
              <FaInstagram />
            </a>
          </div> */}
        </div>
      </div>
    </Fragment>
  );
};

export default ProductDetails;
