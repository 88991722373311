
import { useParams } from "react-router-dom";

const SupportTicket = () => {
  const { ticket } = useParams();
  return (
		<section>
		"Conversations"
		</section>
  );
};

export default SupportTicket;
