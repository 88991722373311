import { Fragment } from "react";
import { AiOutlineMail, AiOutlinePhone,AiOutlineHome,AiOutlineUser } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { priceFormat } from "../../../../hooks/helper";

const ShippingInfo = ({thankStyle,shippingAddress,shippingCharges}) => {
	const { t,i18n } = useTranslation();

	return (
		<Fragment>
			<section>
				<div className="mt-4">
				<h2>{t('order_location')}</h2>
				<h6>{shippingAddress?.address}</h6>
				</div>
          	</section>
			<div className="mt-5">
				<h2>{t('billing_addr')}</h2>
				<div className={thankStyle.billingBox}>
				<div>{t('shipping_method')}</div><span>{shippingCharges.shipping_method}</span>
				<div>{t('shipping_charge')}</div><span>{priceFormat(shippingCharges.shipping_charge,i18n.language)}</span>
				<div><AiOutlineUser/> <span>{shippingAddress.name}</span></div>
				<div> <AiOutlineHome/> <span>{shippingAddress.address}</span></div>
				<h6>
					<AiOutlinePhone /> {shippingAddress.phone}{" "}
				</h6>
				<h6>
					<AiOutlineMail /> {shippingAddress.email}
				</h6>
				</div>
			</div>
		</Fragment>
	);
}
  export default ShippingInfo;