import Banner from "./banner/Banner";
import "../style.scss";
import GridOne from "../../../../../../common/product/prodsCard/GridOne";

const ProductsBlock = ({bannerOne,popularProducts}) => {
	return (
		<div className="row">
			<Banner bannerOne={bannerOne} />
			<div className="col-lg-8 col-sm-12">
				<div className="row single-product-area">
					{
						popularProducts["all"]?.products &&
						popularProducts["all"]?.products?.map((item,key)=>{
							return <div className="col-lg-4 col-sm-12" key={key}><GridOne product={item}/></div>
						})
					}
				</div>
			</div>
		</div>
	);
};

export default ProductsBlock;
