import { useEffect, useState, Fragment } from "react";
import { Table } from "react-bootstrap";
import { setReviews } from "../../../redux/slices/seller/products";
import reviewsStyle from "./shopReviews.module.css";
import { useSelector } from "react-redux";
import { getApi } from "../../../api/apiCall";
import PaginationCom from "../../../common/pagination/PaginationCom";
import SimpleLoading from "../../../common/loading/SimpleLoading";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const ShopReviews = () => {
	const { t } = useTranslation();

  	const { reviews,  per_page, last_page, current_page, loading, error } =
    useSelector((state) => state.reviewSlice);
  	const [perPage, setPerPage] = useState(per_page);
  	const [currentPage, setCurrentPage] = useState(current_page);
  	const [reviewSorting, setReviewSort] = useState('rating_high_to_low');

	useEffect(() => {
		getApi(`shop-reviews?per_page=${perPage}&sort_by=${reviewSorting}`, setReviews);
	}, [perPage, currentPage, reviewSorting]);
	
  const reviewSort = [ {label: t('rating_high_low'),value:'rating_high_to_low'} , {label:t('rating_low_high'),value:'rating_low_to_high'} ];

  return (
    <Fragment>
      <div className={`${reviewsStyle.background}`}>
        <section>
          <h5 className="px-md-4 px-3 py-2 pt-3">Shop Reviews</h5>
          <div className="tableFilters">
		  	<Select
				options={reviewSort}
				className="me-0 me-md-3 mb-1 mb-md-0"
				placeholder={"Sort by Rating"}
				onChange={(e) => setReviewSort(e.value)}
			/>
          </div>
        </section>

        {(loading || error) && (
          <section className={`px-4 ${reviewsStyle.tableData}`}>
            <Table borderless responsive>
              <thead>
                <tr>
                  <th>
                    <small>#</small>
                  </th>
                  <th>
                    <small>{t('name')}</small>
                  </th>
                  <th>
                    <small>{t('email')}</small>
                  </th>
                  <th>
                    <small>{t('rating')}</small>
                  </th>
                  <th>
                    <small>{t('comment')}</small>
                  </th>
                </tr>
              </thead>
            </Table>
          </section>
        )}
        {loading && <SimpleLoading />}
        {error ? <h1 className="text-center">{error}</h1> : ""}

        {!loading && reviews?.length > 0 && !error && (
          <section className={`px-4 ${reviewsStyle.tableData}`}>
            <Table borderless responsive>
              <thead>
                <tr>
                  <th>
                    <small>#</small>
                  </th>
                  <th>
                    <small>{t('name')}</small>
                  </th>
                  <th>
                    <small>{t('email')}</small>
                  </th>
                  <th>
                    <small>{t('rating')}</small>
                  </th>
                  <th>
                    <small>{t('comment')}</small>
                  </th>
                </tr>
              </thead>

              <tbody>
                {reviews.length > 0 &&
                  reviews.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          <small>{item.id}</small>
                        </td>
                        <td>
                          <small>{item.name}</small>
                        </td>
                        <td>
                          <small>{item.email} </small>
                        </td>
                        <td>
                          <small>{item.rating}</small>
                        </td>
                        <td>
                          <small>{item.comment}</small>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>

            <PaginationCom
              currentItem={reviews}
              perPage={per_page}
              pageCount={last_page}
              currentPage={currentPage}
              setPerPage={setPerPage}
              setCurrentPage={setCurrentPage}
            />
          </section>
        )}
      </div>
    </Fragment>
  );
};

export default ShopReviews;
