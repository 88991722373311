import { product_1 } from "../../../../../../assets";
import { product_2 } from "../../../../../../assets";
import { product_3 } from "../../../../../../assets";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import GridOne from "../../../../../../common/product/prodsCard/GridOne";
import { getTemplates } from "../../../../../../hooks/templates";

const ProductsBlock = ({featureProducts}) => {
	return (
		<div className="row">
			<Swiper
				style={{'--swiper-next-bg': "#fff",
				'--swiper-next-arrow': getTemplates().primary_color}}
				modules={[Navigation]}
				centeredSlides={false}
				autoplay={{
					delay: 1000,
					disableOnInteraction: false
				}}
				pagination={{
					clickable: true
				}}
				loop={true}
				loopFillGroupWithBlank={true}
				navigation
				breakpoints={{
					576: {
						slidesPerView: 1
					},
					768: {
						spaceBetween: 20,
						slidesPerView: 3
					},
					1024: {
						spaceBetween: 20,
						slidesPerView: 4
					},
					1280: {
						slidesPerView: 4,
						spaceBetween: 20,
					}
				}}
			>
				{
					featureProducts["all"]?.products &&
					featureProducts["all"]?.products?.map((item,key)=>{
						return <SwiperSlide className="col-lg-3 col-sm-12" key={key}><GridOne product={item}/></SwiperSlide>
					})
				}
			</Swiper>
		</div>
	);
};

export default ProductsBlock;
