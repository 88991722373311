import { useTranslation } from "react-i18next";
import { useState } from "react";
import { getTemplates } from "../../../../../../hooks/templates";

const Categories = ({ categories,setCategoryId}) => {
    const { t } = useTranslation();
	const [isHover, setIsHover] = useState(-1);
	const handleMouseEnter = (key) => {
		setIsHover(key);
	 };
	 const handleMouseLeave = () => {
		setIsHover(-1);
	 };
	return (
        <div className="sidebar-row categories-wrap">
        <h4 className="sidebar-label">{t('categories')}</h4>
        {	
        categories?.length > 0 &&
        (
            <ul>
                {
                    categories?.map((category,key)=>
                        <li onClick={(e)=>setCategoryId(category.value)} key={key}
						style={{
							color: isHover == key ?  getTemplates().primary_color : "#000",
						}}
						onMouseEnter={(e)=>handleMouseEnter(key)}
						onMouseLeave={handleMouseLeave}
						>
                            {category.label}
                        </li>	
                    )
                }
            </ul>
        )

        }
        </div>
	);
};

export default Categories;
