import Banner from "./banner/Banner";
import {product_1} from "../../../../../../assets";
import {product_2} from "../../../../../../assets";
import {product_3} from "../../../../../../assets";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import GridOne from "../../../../../../common/product/prodsCard/GridOne";
import { getTemplates } from "../../../../../../hooks/templates";

const ProductsBlock = ({dailyDeals,bannerTwo}) => {
	return (
		<div className="row">
			<Banner bannerTwo={bannerTwo} />
			<div className="col-lg-8 col-sm-12">
				<Swiper
				style={{'--swiper-next-bg': getTemplates().primary_color,
				'--swiper-next-arrow': "#fff"}}
				className="daily-deals"
				modules={[Navigation]}
				centeredSlides={false}
				autoplay={{
					delay: 100,
					disableOnInteraction: false
				}}
				pagination={{
					clickable: true
				}}
				loop={true}
				loopFillGroupWithBlank={true}
				navigation
				spaceBetween={20}
				slidesPerView={3}
				breakpoints={{
					// when window width is >= 576px
					576: {
						slidesPerView: 1
					},
					// when window width is >= 768px
					768: {
						spaceBetween: 20,
						slidesPerView: 3
					},
					// when window width is >= 1024px
					1024: {
						spaceBetween: 0,
						slidesPerView: 3
					},
					1280: {
						slidesPerView: 3
					}
				}}
				>
					{
						dailyDeals["all"]?.products &&
						dailyDeals["all"]?.products?.map((item,key)=>{
							return <SwiperSlide id="daily_deals" className="col-lg-3 col-sm-12" key={key}><GridOne product={item}/></SwiperSlide>
						})
					}
				</Swiper>
			</div>
		</div>
	);
};

export default ProductsBlock;
