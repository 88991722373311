import React, { useState } from "react";
import UploadFilesModal from "../../../../UploadFiles/UploadFilesModal";
import { useTranslation } from "react-i18next";

const GeneralSettings = ({
  handleChange,
  values,
  errors,
  touched,
  setFieldValue,
}) => {
	const { t  } = useTranslation();
  	const [show, setShow] = useState(false);

  return (
    <div className="add-product-single-widget">
      <div className="widget-title">{t('basic')} {t('information')} </div>
      <div className="widget-content-container">
        <div className="product-informaiton-form">
          <label htmlFor="name">
            <span>{t('shop')+` `} {t('name')}</span>
            <i>*</i>
          </label>
          <div>
            <input
              name="name"
              value={values?.name}
              onChange={handleChange}
              type="text"
            />
            {errors?.name && touched?.name ? (
              <small className="text-danger">{errors?.name} </small>
            ) : (
              ""
            )}
          </div>
          <label>
            <span> {t('shop')} {t('logo')} </span>
          </label>
          <div onClick={() => setShow(!show)} className="custom-browse">
            <div>{t('browse')}</div>
            <div>{values?.logo ? values?.logo : t('choose_file') }</div>
          </div>
          <UploadFilesModal
            format="string"
            setFieldValue={setFieldValue}
            imageFor="logo"
            values={values}
            show={show}
            setShow={setShow}
          />

          <label htmlFor="address">
            <span> {t('shop')} {t('address')} </span>
            <i>*</i>
          </label>
          <div>
            <input
              name="address"
              onChange={handleChange}
              value={values?.address}
              id="address"
              type="text"
            />
            {errors?.address && touched?.address ? (
              <small className="text-danger"> {errors?.address} </small>
            ) : (
              ""
            )}
          </div>
          <label htmlFor="about_shop">
            <span>{t('about')+` `} {t('shop')}</span>
          </label>
          <div>
            <textarea
              name="about"
              value={values?.about}
              onChange={handleChange}
              id="about"
              rows={5}
            />
          </div>
        </div>
      </div>

      <div className="widget-title">
        {t('social_m_link')} (
        <small>
          <i>{t('insert_link_http')}</i>
        </small>
        )
      </div>
      <div className="widget-content-container">
        <div className="ap-single-content">
          <p> {t('facebook')} </p>
          <div>
            <input
              name="facebook"
              onChange={handleChange}
              value={values?.facebook}
              type="text"
              placeholder="www.facebook.com"
            />
          </div>
        </div>
        <div className="ap-single-content">
          <p> {t('twitter')} </p>
          <div>
            <input
              name="twitter"
              onChange={handleChange}
              value={values?.twitter}
              type="text"
              placeholder="www.twitter.com"
            />
          </div>
        </div>
        <div className="ap-single-content">
          <p> {t('google')} </p>
          <div>
            <input
              name="google"
              onChange={handleChange}
              value={values?.google}
              type="text"
              placeholder="www.google.com"
            />
          </div>
        </div>
        <div className="ap-single-content">
          <p> {t('youtube')} </p>
          <div>
            <input
              name="youtube"
              onChange={handleChange}
              value={values?.youtube}
              type="text"
              placeholder="www.youtube.com"
            />
          </div>
        </div>
        <div className="ap-single-content">
          <p> {t('instagram')} </p>
          <div>
            <input
              name="instagram"
              onChange={handleChange}
              value={values?.instagram}
              type="text"
              placeholder="www.instagram.com"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralSettings;
