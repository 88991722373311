import { useFormik } from "formik";
import { shopSettingSchema } from "../../../../schema";
import { getApi } from "../../../../api/apiCall";
import { setShopSetting } from "../../../../redux/slices/seller/shopSetting";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import SimpleLoading from "../../../../common/loading/SimpleLoading";
import { useState } from "react";
import { markutosSellerApi } from "../../../services/Api/api";
import { toast } from "react-toastify";
import ShopPageSettings from "./componetns/shopPageSettings/ShopPageSettings";
import GeneralSettings from "./componetns/generalSettings/GeneralSettings";
import { useTranslation } from "react-i18next";

const ShopSetting = () => {
	const { t  } = useTranslation();
	const [submiting, setSubmitting] = useState(false);
	const { setting, loading, error } = useSelector(
		(state) => state.shopSettingReducer
	);

	const { values, handleChange, touched, errors, handleSubmit, setFieldValue } =
		useFormik({
		validationSchema: shopSettingSchema,
		initialValues: {
			name: setting?.name || "",
			address: setting?.address || "",
			logo: setting?.logo || "",
			sliders: setting?.sliders || "",
			facebook: setting?.facebook || "",
			twitter: setting?.twitter || "",
			google: setting?.google || "",
			youtube: setting?.youtube || "",
			instagram: setting?.instagram || "",
			categories: setting?.categories || [],
			about: setting?.about || "",
		},

		enableReinitialize: true,
		onSubmit: (values, action) => {
			setSubmitting(true);
			markutosSellerApi()
			.post(`/update-shop-setting`, values )
			.then((res) => {
				toast.success(res.data.message);
				getApi("shop-setting", setShopSetting);
				setSubmitting(false);
			})
			.catch((err) => {
				toast.error(err.message);
				setSubmitting(false);
			});
			action.resetForm();
		},
		});

	useEffect(() => {
		getApi("shop-setting", setShopSetting);
	}, []);

  return (
    <>
      {loading ? (
        <SimpleLoading />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="add-product mx-3 mb-5">
            <div className=" d-flex justify-content-between me-md-5 pe-md-4 me-0 pe-0 mt-3 mb-3">
              <h4> {t('shop')+` `} {t('settings')} </h4>
            </div>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="general-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#general-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="general-tab-pane"
                  aria-selected="true"
                  style={{ color: "black" }}
                >
                  {t('general')} {t('settings')}
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="shop-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#shop-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="shop-tab-pane"
                  aria-selected="false"
                  style={{ color: "black" }}
                >
                  {t('shop')} {t('page')} {t('settings')}
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="general-tab-pane"
                role="tabpanel"
                aria-labelledby="general-tab"
                tabIndex="0"
              >
                <GeneralSettings
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  errors={errors}
                />
              </div>
              <div
                className="tab-pane fade"
                id="shop-tab-pane"
                role="tabpanel"
                aria-labelledby="shop-tab"
                tabIndex="0"
              >
                <ShopPageSettings
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  errors={errors}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end pt-3">
              <button
                disabled={submiting}
                type="submit"
                className="btn btn-primary"
              >
                {submiting ? (
                  <div>
                    <div
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    >
                      <span className="visually-hidden">{t('loading')}...</span>
                    </div>
                    {t('save')}
                  </div>
                ) : (
					          t('save')
                )}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default ShopSetting;
