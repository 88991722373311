import { AiOutlineDeliveredProcedure } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { priceFormat } from "../../../../../hooks/helper";
import cartStyle from "./cart.module.css";
import { useCart } from "react-use-cart";
import { Fragment,useState } from "react";
import { useDispatch } from "react-redux";
import { setMiniCart } from "../../../../../redux/slices/miniCart";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getTemplates,bg_Hover } from "../../../../../hooks/templates";

const CartTotal = ({ proceedKey , cartTotal , cartTotalTitle }) => {
    const { t,i18n } = useTranslation();

  	const dispatch = useDispatch();
  	const { shipping_charge , tax } = useSelector((state) => state.additionalPrice );
	  const {
		metadata
		} = useCart();

	const addAdditionalPrice=()=>{
		// tax calculation
		let calculate_tax = 0;
		if ( tax ) {
		calculate_tax = (cartTotal * parseFloat(tax) )/100;
		}
		var total_price = parseFloat(cartTotal) + parseFloat(calculate_tax);

		// shipping charge calculation
		if ( shipping_charge ) {
		total_price += parseFloat(shipping_charge);
		}

		if (( cartTotalTitle != "checkout" && metadata?.coupon )) {
			total_price -= parseFloat(metadata.coupon)
		}
		return priceFormat( total_price , i18n.language );
	}

  return (
    <div>
      <div className={cartStyle.cart_totals}>
        {cartTotalTitle != "checkout" && (
          <h5 className="mb-3">{t('cart_total')}</h5>
        ) }
        <div className={cartStyle.cartTotalsRow}>
          <div>
            <span>{t('subtotal')}:</span>
          </div>
          <div className="d-flex justify-content-end">
            <span>{priceFormat(cartTotal,i18n.language )}</span>
          </div>
        </div>
		{/* Add tax calculation */}
		{
			( cartTotalTitle == "GrandTotal" || cartTotalTitle == "miniCart") && 
      <Fragment>
        {
          tax && (
			  <div className={cartStyle.cartTotalsRow}>
				  {/* Tax calcultion */}
					<div>
					  <span>{t('tax')} ({tax}%):</span>
				  </div>

          <div className="d-flex justify-content-end">
          <span>{priceFormat( (cartTotal * parseFloat(tax) )/100 , i18n.language  ) } </span>
          </div>
			  </div>
          )
        }
        { shipping_charge && (
          <div className={cartStyle.cartTotalsRow}>
              {/* Shipping charge calcultion */}

                  <div>
                  <span>{t('shipping_charge')} :</span>
                </div>
              <div className="d-flex justify-content-end">
                <span>{ shipping_charge && priceFormat(shipping_charge , i18n.language  ) } </span>
              </div>
          </div>
        ) }
      </Fragment>
		}
		{/* Add coupon calculation */}
		{
		( cartTotalTitle != "checkout" && metadata?.coupon ) && 
			<div className={cartStyle.cartTotalsRow}>
				<div>
				<span>{t('coupon_discount')}:</span>
				</div>
				<div className="d-flex justify-content-end">
				<span>{priceFormat(metadata?.coupon , i18n.language )  } </span>
				</div>
		  	</div>
		}
        <div className={cartStyle.cartTotalsRowLast}>
          <div>
            <span>{t('total')}:</span>
            { !shipping_charge && <small>({t('shipping_charge_not_inc')})</small>}
          </div>
          <div className="d-flex justify-content-end">
            <span>{addAdditionalPrice()}</span>
          </div>
        </div>

		{/* Choose shipping method */}
        {/* {proceedKey == "checkout" && (
          <div className="form-group mt-3 ">
            <h6
              className="pb-1"
              style={{
                fontWeight: "bold",
              }}
              htmlFor=""
            >
			Shipping Method:
            </h6>
            <select className="form-control" name="" id="">
              <option>Free Shipping</option>
              <option>Economy</option>
              <option>Ultra Fast</option>
            </select>
          </div>
        ) } */}

        {proceedKey == "cart" && (
          <div className={`my-4  ${cartStyle.proceedBtn}`}>
            <NavLink to={`/checkout`}>
              <button className="btn btn-primary"
                    onClick={() => {
                      cartTotalTitle == "miniCart" && dispatch(setMiniCart({ open: false }));
                    }}
                    style={{
                      "--bs-btn-bg" :  getTemplates().primary_color,
                      "--bs-btn-hover-bg" :  bg_Hover(),
                      "--bs-btn-border-color" :  getTemplates().primary_color,
                      "--bs-btn-active-bg" :  getTemplates().primary_color,
                      "--bs-btn-active-border-color" :  getTemplates().primary_color,
                      }}
              >
                {" "}
                <AiOutlineDeliveredProcedure /> {t('process_checkout')}
              </button>
            </NavLink>
          </div>
        ) }
      </div>
    </div>
  );
};

export default CartTotal;
