import { useState } from "react";
import "./stores.scss";
import { store1 } from "../../../../../../assets";
import { store2 } from "../../../../../../assets";
import { store3 } from "../../../../../../assets";
import { store_avatar1 } from "../../../../../../assets";
import { store_avatar2 } from "../../../../../../assets";
import { store_avatar3 } from "../../../../../../assets";
import { NavLink } from "react-router-dom";
import ShopButton from "../partialComponents/ShopButton";

const Stores = ({shops}) => {
	return (
		<section className="content-block">
			<div className="row text-center mb-20">
				<h2 className="content-title">Our Store List</h2>
			</div>
			<div className="row">
				{shops && shops.map((shop, index) => {
					return (
						<div className="col-lg-4 col-sm-12" key={index}>
							<div className="store-wrapper">
								<div className="store-header">
									<div className="store-banner">
										<img src={store1} alt="Store Image" />
									</div>
									<div className="store-avatar">
										<a href="#">
											<img src={shop.logo} alt={shop.name} />
										</a>
									</div>
								</div>
								<div className="store-content ">
									<div className="store-data">
										<h2 className="vendor-title">
											<NavLink to={`/seller-shop/${shop.slug}`}>{shop.name}</NavLink>
										</h2>
										<p className="store-address">
											<i
												className="fa fa-map-marker"
												aria-hidden="true"
											></i>
											United Kingdom (UK)
										</p>
									</div>
									<div className="store-btn">
										<NavLink to={`/seller-shop/${shop.slug}`}>
											<i
												className="fa fa-angle-right"
												aria-hidden="true"
											></i>
										</NavLink>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
			<ShopButton url={`/shop`}/>
		</section>
	);
};

export default Stores;
