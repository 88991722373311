
import { getTemplates } from "../../../../../../hooks/templates";
import { useState } from "react";

const Attributes = ({attributes,setAttributesId,setVariations}) => {
	const [isHover, setIsHover] = useState(-1);
	const handleMouseEnter = (key) => {
		setIsHover(key);
	 };
	 const handleMouseLeave = () => {
		setIsHover(-1);
	 };
	return (
        <div className="sidebar-row radio-wrap">
			{ 
				( typeof attributes !=="undefined" && Object.keys(attributes)?.length !== 0 ) &&  (
					Object.keys(attributes)?.map((attribute, index) => {
					return (
						<div className="mt-3" key={index}>
							<h4 className="sidebar-label" onClick={(e)=>setAttributesId(attribute)}>{attribute}</h4>
							<div className="font_14">
							{
								attributes?.[attribute]?.map((variant,i)=>{
									return(
										<div className="radio-item" key={i}
										onMouseEnter={(e)=>handleMouseEnter({attr:index,variant:i})}
										onMouseLeave={handleMouseLeave}
										>
											<input type="radio" 
												name={variant}
											 	value={variant}
											  	id={variant}
												onChange={(e)=>setVariations(variant)}
											/>
											<label htmlFor={variant}
											style={ { 
												outline: (isHover.attr == index && isHover.variant == i ) && `1px outset ${getTemplates().primary_color}`,
												color: (isHover.attr == index && isHover.variant == i ) && `${getTemplates().primary_color}`,
												}}
											>{variant}</label>
										</div> 
									)
								})
							}
							</div>
						</div>
					);
					})
				)
			}
        </div>
	);
};

export default Attributes;
