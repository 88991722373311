import React from "react";
import { AiOutlineArrowLeft, AiOutlineHome } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import cartStyle from "./cart.module.css";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { getTemplates,bg_Hover } from "../../../../../hooks/templates";

const ActionButton = () => {
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState(-1);
  const handleMouseEnter = (key) => {
	  setIsHover(key);
   };
   const handleMouseLeave = () => {
	  setIsHover(false);
   };
  return (
    <div className={`my-5 ${cartStyle.actionButton}`}>
		<NavLink to={`/shop`}>
			<button className="btn mb-2 md-md-0 btn-outline-primary mr-3" 
			style={{
				backgroundColor: isHover == 0 ? bg_Hover() : getTemplates().primary_color,
				borderColor: isHover == 0 ? bg_Hover() : getTemplates().primary_color,
				color:'#fff'
			}}
			onMouseEnter={(e)=>handleMouseEnter(0)}
			onMouseLeave={handleMouseLeave}
			>
				{" "}
				<AiOutlineArrowLeft /> {t('continue_shopping')}
			</button>
		</NavLink>
		<NavLink to={`/`}>
			<button className="btn mb-2 md-md-0 btn-outline-primary"
			style={{
				backgroundColor: isHover == 1 ? bg_Hover() : getTemplates().primary_color,
				borderColor: isHover == 1 ? bg_Hover() : getTemplates().primary_color,
				color:'#fff'
			}}
			onMouseEnter={(e)=>handleMouseEnter(1)}
			onMouseLeave={handleMouseLeave}
			>
			{" "}
			<AiOutlineHome /> {t('back_home')}
		</button>
		</NavLink>
    </div>
  );
};

export default ActionButton;
