
import { Fragment, useEffect } from "react";
import { useState } from "react";
import { markutosSellerApi } from "../../../services/Api/api";
import { toast } from "react-toastify";
import { Table } from "react-bootstrap";
import PaginationCom from "../../../../common/pagination/PaginationCom";

const ProductSetup = () => {
  const [productId, setProductId] = useState('');
  const [type, setType] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
	const [search, setSearch] = useState("");
  const allTypes = ["most_popular","daily_deals","featured_products"];

  useEffect(() => {
    if (type !== false ) {
      markutosSellerApi()
	  .get(`/products/index-state?product_id=${productId}&type=${type}`)
      .then((res) => {
        setLoading(true);
        toast.success(res.data.message);
      })
      .catch((error) => {
        console.log(error.message);
      });
    }
  }, [type]);

  // get categories
  useEffect(() => {
    markutosSellerApi()
	.get(`/products?search_value=${search}&sort_seller=&sort_by=price_high_to_low&per_page=${perPage}&page=${currentPage}`)
    .then((response) => {
			setProducts(response?.data?.data);
			setCurrentPage(response?.data?.current_page);
			setPerPage(response?.data?.per_page);
			setPageCount(response?.data?.last_page);
    })
    .catch((error) => {
      console.log(error.message);
    });
  }, [perPage, currentPage, search , loading ]);

  const updateType = ( productId , key , checked ) =>{
    setType(allTypes[key]);
    setProductId(productId);
  }
  return (
	  <Fragment>
      <div className="table-top-header">
				<div className="table-title">Manage Products</div>
				<div className="tableFilters">
					<input
						type="text"
						className="table-search-input"
						placeholder="Search prudct by name"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
					/>
				</div>
			</div>
        <Table bordered responsive>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Popular</th>
                  <th>Daily Deals</th>
                  <th>Featured</th>
                </tr>
              </thead>
              <tbody>
                {
                  products?.map((item,key)=>{
                    return(
                      <tr key={key}>
                        <td>{item.name}</td>
                        <td>
                          <label className="confi-switch">
                            <input type="checkbox" 
                              onChange={(e)=> updateType( item.id , key , e.target.checked )}
                              defaultChecked={parseInt(item.most_popular)} />
                            <span className="slider round"></span>
                          </label>
                        </td>
                        <td>
                          <label className="confi-switch">
                            <input type="checkbox" 
                              onChange={(e)=> updateType( item.id , key , e.target.checked )}
                              defaultChecked={parseInt(item.daily_deals)} />
                            <span className="slider round"></span>
                          </label>
                        </td>
                        <td>
                          <label className="confi-switch">
                            <input type="checkbox" 
                              onChange={(e)=> updateType( item.id , key , e.target.checked )}
                              defaultChecked={parseInt(item.featured_products)} />
                            <span className="slider round"></span>
                          </label>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
        </Table>
        {products.length > 0 && (
            <PaginationCom
              currentItem={products}
              perPage={perPage}
              pageCount={pageCount}
              currentPage={currentPage}
              setPerPage={setPerPage}
              setCurrentPage={setCurrentPage}
            />
          )}
    </Fragment>
  );
};

export default ProductSetup;
