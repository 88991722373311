import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BreadCumb from "../../../../../common/breadcumb/BreadCumb";
import BlogSidebar from "../blogSidebar/BlogSidebar";
import blogDetailsStyle from "./blogDetails.module.css";
import BlogInfo from "./BlogInfo";
import SimpleLoading from "../../../../../common/loading/SimpleLoading";
import { markutosFrontendApi } from "../../../../services/Api/api";

const BlogDetails = (props) => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
	const [currentItems, setCurrentItems] = useState([]);
	const [recentPosts, setRecentPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [popularTags, setPopularTags] = useState([]);

  const getData=()=>{
    var item_value = sessionStorage.getItem("MARCATUEX_BLOG_DETAILS");
    if ( item_value !== null ) {
      const temp = JSON.parse(sessionStorage.getItem("MARCATUEX_BLOG_DETAILS") ) || [] ;
      setCurrentItems(temp);
    } else {
      setLoading(true);
      markutosFrontendApi
      .get(`/blog-details?blog_id=${id}`)
      .then((response) => {
        setLoading(false);
        sessionStorage.setItem("MARCATUEX_BLOG_DETAILS",JSON.stringify(response?.data));
				setCurrentItems(response?.data);
      });
    }

  }

  useEffect(() => {
    window.onload = function() {
      sessionStorage.removeItem("MARCATUEX_BLOG_DETAILS");
    };
    getData();
  }, []);

  const getSidebar=()=>{
    var item_value = sessionStorage.getItem("MARCATUEX_BLOG_SIDEBAR");
    if ( item_value !== null ) {
      const temp = JSON.parse(sessionStorage.getItem("MARCATUEX_BLOG_SIDEBAR") ) || [] ;
      setCategories(temp?.data?.categories); 
      setPopularTags(temp?.data?.popular_tags);
      setRecentPosts(temp?.data?.recent_posts);
    } else {
      setLoading(true);
      markutosFrontendApi
      .get(`/blogs/right-sidebar`).then((response) => {
        setLoading(false);
        sessionStorage.setItem("MARCATUEX_BLOG_SIDEBAR",JSON.stringify(response));
        setCategories(response?.data?.categories); 
		setPopularTags(response?.data?.popular_tags);
		setRecentPosts(response?.data?.recent_posts);
      });
    }

  }

  useEffect(() => {
    window.onload = function() {
      sessionStorage.removeItem("MARCATUEX_BLOG_SIDEBAR");
    };
    getSidebar();
  }, []);

  return (
		loading ? <SimpleLoading/> :
		(
			<div>
				<BreadCumb data={currentItems?.title} />
				<div className={`container ${blogDetailsStyle.blogDetailsContainer}`}>
					<div className="row">
						<div className="col-lg-8">
							<BlogInfo currentItems={currentItems}/>
						</div>
						<div className="col-lg-4 mt-5 mt-md-0">
							<BlogSidebar
							recentPosts={recentPosts}
							categories={categories}
							popularTags={popularTags}
							/>
						</div>
					</div>
				</div>
			</div>
		)
  );
};

export default BlogDetails;
