import "./Ads.scss";
import { NavLink } from "react-router-dom";

const Ads = ({bannerThree,bannerFour}) => {
	return (
		<div className="dual-banner-wrapper">
			<div className="row">
				<div className="col-lg-6 col-md-6 col-sm-12">
					<div className="banner-area">
						<p className="subtitle">{bannerThree?.title}</p>
						<h2 className="title">{bannerThree?.first_line}</h2>
						<p className="offer">{bannerThree?.second_line}</p>
						<NavLink to={bannerThree?.button_url} className="shop-btn">
							{bannerThree?.button_text}
							<i className="fa fa-angle-right" aria-hidden="true"></i>
						</NavLink>
					</div>
				</div>
				<div className="col-lg-6 col-md-6 col-sm-12">
					<div className="banner-area2">
						<h2 className="bold-text">{bannerFour?.title}</h2>
						<h3>{bannerFour?.first_line}</h3>
						<p>{bannerFour?.second_line}</p>
						<NavLink to={bannerFour?.button_url} className="shop-btn">
							{bannerFour?.button_text}
							<i className="fa fa-angle-right" aria-hidden="true"></i>
						</NavLink>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Ads;
