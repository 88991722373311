import { MdOutlineViewInAr } from "react-icons/md";
import "./viewMore.css";
import { NavLink } from "react-router-dom";
import { getTemplates,bg_Hover } from "../../../../hooks/templates";

const ViewMore = ({ slug }) => {
  return (
      <div className={`d-flex justify-content-center mb_55`}>
        <NavLink to={`/shop/${slug}`} 
			style={{
				"--bs-btn-bg" :  getTemplates().primary_color,
				"--bs-btn-hover-bg" :  bg_Hover(),
				"--bs-btn-border-color" :  getTemplates().primary_color,
				"--bs-btn-active-bg" :  getTemplates().primary_color,
				"--bs-btn-active-border-color" :  getTemplates().primary_color,
				}}
			className="btn btn-primary"
		>
          <MdOutlineViewInAr /> View More
        </NavLink>
      </div>
  );
};

export default ViewMore;
