import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { getTemplates } from "../../../../../hooks/templates";
import blogInfoStyle from "./blogDetails.module.css";

const BlogInfo = (props) => {
  const { t } = useTranslation();
  return (
    <div className={blogInfoStyle.BlogInfoContainer}>
      <section>
        <div className={blogInfoStyle.title}>
          <h2>{props.currentItems?.title}</h2>
          <small> {t('by')} 
		  <span
			style={{
				color:getTemplates().primary_color,
				margin:"0 5px",
			}}>{props.currentItems?.author}</span> {t('in')}  </small>
          <small
            style={{
              fontWeight: "600",
            }}
          >
            {props.currentItems?.category}
          </small>
          <small> {t('on')} {props.currentItems?.created_date}</small>
        </div>
      </section>

      <section>
        <div className="mt-5">
		  <div dangerouslySetInnerHTML={{__html: props.currentItems?.content }}></div>
          <img src={props.currentItems?.banner} alt={props.currentItems?.title} />
        </div>
      </section>

      <section>
        <div className="mt-5">
          <span> {t('tags')}: </span>
			{
				props.currentItems?.tags?.map((item, key) => {
					return (
						<span key={key}>
							<NavLink to={""}
							style={{
								color: getTemplates().primary_color
							}}>
								{item}
							</NavLink>
						</span>
						)
					})
			}
        </div>
      </section>
    </div>
  );
};

export default BlogInfo;
