import arrivalStyle from "./arrival.module.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import GridView from "../../../../../common/product/prodsLayout/GridView";
import ViewMore from "../../viewMore/ViewMore";

const NewArrivals = ({ loading, newArrival }) => {
  return (
    <div className="container">
      <div className={arrivalStyle.titles}>
        <h1 className="text-center mb-5 pt-5">New Arrivals</h1>
      </div>
      <section className={arrivalStyle.mb_55}>
        {loading ? (
          <SkeletonTheme height={50}>
            <p>
              <Skeleton count={5} />
            </p>
          </SkeletonTheme>
        ) : (
          newArrival?.products && (
            <GridView products={newArrival?.products} />
          )
        )}
      </section>

      <ViewMore slug={newArrival?.slug}/>
    </div>
  );
};

export default NewArrivals;
