import { useState } from "react";
import { useTranslation } from "react-i18next";
import BreadCumb from "../../../../../common/breadcumb/BreadCumb";
import { AiOutlineDelete, AiOutlineShoppingCart } from "react-icons/ai";
import wisthlistStyle from "./wishlist.module.css";
import { priceFormat } from "../../../../../hooks/helper";
import SimpleLoading from "../../../../../common/loading/SimpleLoading";
import { useCart } from "react-use-cart";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerWishlist } from "../../../../../redux/slices/wishlist";
import { NavLink } from "react-router-dom";
import { setQuickView } from "../../../../../redux/slices/quickView";
import { bg_Hover, getTemplates } from "../../../../../hooks/templates";

const WishListTable = () => {
  const { t,i18n } = useTranslation();
  const [openQuickView, setOpenQuickView] = useState(false);
  const dispatch = useDispatch();
  const { addItem, onItemAdd } = useCart();

  // add to wishlist
  const { products: wishListedProds } = useSelector(
    (state) => state?.customerWishlist
  );
  // remove from wishlist
  const removeFromWishlist = (prod) => {
    const preProds = wishListedProds;
    const newWishlistedProds = preProds?.filter((pp) => pp.id !== prod.id);
    dispatch(
      setCustomerWishlist({
        count: newWishlistedProds.length,
        products: newWishlistedProds,
      })
    );
    localStorage.setItem("my-wishlist", JSON.stringify(newWishlistedProds));
  };

  const handleAddToCart = ( product ) => {
    // add to cart
    if (product.product_type !== "variation") {
      addItem(product);
    } else {
      dispatch(setQuickView({ open: true, product }));
    }
  };

  return (
    <div>
      <BreadCumb data={t('wishlist')} />
      <div className="container">
        <h1 className="text-center my-4 mb-5">{t('wishlist')}</h1>
        <div style={{ overflowX: "auto" }} className={wisthlistStyle.wishTable}>
          <table className="table-res responsive">
            <thead>
              <tr>
                <th></th>
                <th>{t('product')}</th>
                <th>{t('price')}</th>
                <th>{t('availability')}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {wishListedProds?.length == 0 ? (
                <tr><td></td><td></td><td className="text-center">{t('no_item_found')}</td></tr>
              ) : (
                wishListedProds?.map((product, key) => {
                  return (
                    <tr key={key}>
                      <td className={wisthlistStyle.image}>
                        <img src={product.thumbnail_img} alt={product.name} />
                      </td>
                      <td>
                        <h6><NavLink to={`/products/${product.slug}`}>{product.name}</NavLink></h6>
                        <small>Shop Name : {product.shop_name} </small>
                      </td>
                      <td>
                        {product.product_type == "variation"
                          ? priceFormat(
                              product.discount_price_range,i18n.language,"variation")
                          : priceFormat(product.price,i18n.language)}
                      </td>
                      <td>
                        <span
                          style={{ fontWeight: "600" }}
                          className="text-success "
                        >
                          {product.stock_status}
                        </span>
                      </td>
                      <td>
                        {" "}
                        <button
                          className="btn btn-primary"
                          onClick={(e)=>handleAddToCart(product)}
                          style={{
                            "--bs-btn-bg" :  getTemplates().primary_color,
                            "--bs-btn-hover-bg" :  bg_Hover(),
                            "--bs-btn-border-color" :  getTemplates().primary_color,
                            "--bs-btn-active-bg" :  getTemplates().primary_color,
                            "--bs-btn-active-border-color" :  getTemplates().primary_color,
                            }}
                        >
                          {" "}
                          <AiOutlineShoppingCart /> {t('add_to_cart')}
                        </button>{" "}
                      </td>
                      <td>
                        {" "}
                        <button
                          className={`btn ${wisthlistStyle.deletebtn}`}
                          onClick={() => removeFromWishlist(product)}
                        >
                          <AiOutlineDelete />
                        </button>{" "}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default WishListTable;
