import React from "react";
import BreadCumb from "../../../common/breadcumb/BreadCumb";
import SupportForm from "./components/supportCom/SupportForm";
import { useTranslation } from "react-i18next";

const Support = () => {
	const { t } = useTranslation();
	return (
		<section>
			<BreadCumb data={t('support')}/>
			<SupportForm/>
		</section>
	);
};

export default Support;
