import { useTranslation } from "react-i18next";
import PageHeader from "./components/cartCom/PageHeader";
import ProductTable from "./components/cartCom/ProductTable";
import ActionButton from "./components/cartCom/ActionButton";
import Coupon from "./components/cartCom/Coupon";
import CartTotal from "./components/cartCom/CartTotal";
import OrderBump from "./components/cartCom/OrderBump";
import BreadCumb from "../../../common/breadcumb/BreadCumb";
import cartStyle from "./cartStyle.module.css";
import { useCart } from "react-use-cart";
import { Fragment } from "react";

const Cart = () => {
  const { t,i18n } = useTranslation();
  const {
    isEmpty,
    totalUniqueItems,
    items,
    cartTotal,
    updateItemQuantity,
    removeItem,
    metadata,
  } = useCart();

  return (
    <div>
      <BreadCumb data={t('shopping_cart')} />
      <div className="container">
        <PageHeader />
        {isEmpty ? (
			<div className={`shadow-sm p-3 mb-5 bg-body rounded ${cartStyle.cartEmpty}`}>
          		{t('cart_empty')}
			</div>
        ) : (
          <Fragment>
            <ProductTable
              totalUniqueItems={totalUniqueItems}
              items={items}
              updateItemQuantity={updateItemQuantity}
              removeItem={removeItem}
              language={ i18n?.language }
              t={ t }
            />
            <ActionButton />
            <hr />
            <div className={`mt-4 ${cartStyle.cuponContainer}`}>
              <Coupon />
              <CartTotal proceedKey="cart"
               cartTotal={cartTotal}
               cartTotalTitle="GrandTotal"
              />
            </div>
          </Fragment>
        )}
        {/* You May Buy */}
        <OrderBump />
      </div>
    </div>
  );
};

export default Cart;
