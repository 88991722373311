import { useState, useEffect } from "react";
import "./mainHeader.scss";
import { header_logo } from "../../../../../../assets";
import {
	AiOutlineHeart,
	AiOutlineSearch,
	AiOutlineShoppingCart,
  } from "react-icons/ai";
import { IoIosGitCompare } from "react-icons/io";
import { NavLink, useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";
import { markutosFrontendApi} from "../../../../../../pages/services/Api/api";
import SearchTemplate from "../../../template2/component/SearchTemplate";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerWishlist } from "../../../../../../redux/slices/wishlist";
import { setCompare } from "../../../../../../redux/slices/compare";
import { setMiniCart } from "../../../../../../redux/slices/miniCart";
import HtmlMarkup from "../../../../../htmlMarkup/HtmlMarkup";
import { useTranslation } from "react-i18next";

const MainHeader = ({headerLogo,filterCategories}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { totalItems } = useCart();
	const [searchValue, setSearchValue] = useState("");
	const [searchResult, setSearchResult] = useState([]);
	const [catId, setCatId] = useState("");
	const wishlistedProds = JSON.parse(localStorage.getItem("my-wishlist")) || [];
	const comparedProds =
	  JSON.parse(localStorage.getItem("compared-prods")) || [];
	const [showSearchTemp, setShowSearchTemp] = useState(false);
	const dispatch = useDispatch();
  
	const { count: wishlishtCount } = useSelector(
	  (state) => state?.customerWishlist
	);
	const { products: comparedProdsOnState } = useSelector(
	  (state) => state?.compare
	);
  
	useEffect(() => {
	  dispatch(
		setCustomerWishlist({
		  count: wishlistedProds.length,
		  products: wishlistedProds,
		})
	  );
	  dispatch(
		setCompare({
		  products: comparedProds,
		})
	  );
	}, []);
  
	const getResultData = () => {
	  markutosFrontendApi
		.get(`search?search_value=${searchValue}&category_id=${catId}`)
		.then((response) => {
		  setSearchResult(response?.data);
		})
		.catch((e) => {});
	};
  
	document.onclick = function (h) {
	  if (h.target.className !== "search-text") {
		setShowSearchTemp(false);
	  }
	};
	return (
		<section className="main-header">
			<div className="container-fluid">
				<div className="row main_header_section">
					<div className="col-lg-4 col-md-12 col-sm-12">
						<div className="logo-and-delivery-area">
							<div className="logo">
								<NavLink to="/">
									<img
										src={ headerLogo ? headerLogo : header_logo }
										alt="header logo"
										className="header_logo"
									/>
								</NavLink>
							</div>
							<div className="delivery-area">
								<div className="icon">
									<i
										className="fa fa-map-marker"
										aria-hidden="true"
									></i>
								</div>
								<div className="content">
									<p>Delivery to</p>
									<p className="location">Sacramento, 95829</p>
								</div>
							</div>
						</div>
					</div>
					
					<div className="col-lg-5 col-md-6 col-sm-12">
						<form onSubmit={(e) => e.preventDefault()}>
							<div className="search-input-group">
								<input
									type="text"
									className="search-text"
									placeholder={t('looking_for')}
									value={searchValue}
									name={"search_value"}
									onFocus={() => setShowSearchTemp(true)}
									onChange={(e) => {
									setSearchValue(e.target.value);
									getResultData();
									}}
								/>
								<select
								value={catId}
								onChange={(e) =>{
								setCatId(e.target.value);
								getResultData();
								}}
								>
								{filterCategories.map((o,key) => (
									<option key={key} value={o.value}>{o.label}</option>
								))}
								</select>
								<button className="search-btn">
									<i className="fa fa-search" aria-hidden="true"></i>
								</button>
								{showSearchTemp && (
									<SearchTemplate searchResult={searchResult} />
								)}
							</div>
						</form>
					</div>
					<div className="col-lg-3 col-md-6 col-sm-12">
						<ul className="list-inline float-end padding-none right-1-menu-area">
							{/* <li className="currency">
								<select name="euro" id="">
									<option value="euro"> € EURO</option>
									<option value="USD">$ USD</option>
								</select>
							</li> */}
							<li className="comparison">
								<div className="compare"
									onClick={() =>
									dispatch(
										setCompare({ open: true, products: comparedProds })
									)
									}
								>
									<IoIosGitCompare className="compare-icon" />
									<p>Comparison</p>
									<span className="count">{comparedProdsOnState && comparedProdsOnState.length}</span>
								</div>
							</li>
							<li className="wishlist">
								<div className="wishList" onClick={() => navigate("./wishlist")}>
									<i className="fa fa-heart-o" aria-hidden="true"></i>
									<p>Favorite</p>
									<span className="count">{wishlishtCount}</span>
								</div>
							</li>
							<li className="cart">
								<div onClick={() => dispatch(setMiniCart({ open: true }))}>
									<i
										className="fa fa-cart-plus"
										aria-hidden="true"
									></i>
									<p>Cart</p>
								</div>
								<span className="count">{totalItems}</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
};

export default MainHeader;
