import storeStyle from "./stores.module.css";
import { FaStar } from "react-icons/fa";
import { MdOutlineLocationCity } from "react-icons/md";
import { IoStorefrontOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import SimpleLoading from "../../../../../common/loading/SimpleLoading";
import { useTranslation } from "react-i18next";
import {getTemplates,bg_Hover} from "../../../../../hooks/templates";
import { useState } from "react";

const StoreList = ({ layout , currentItems , loading }) => {
	const { t } = useTranslation();
	const [isHover, setIsHover] = useState(-1);
	const handleMouseEnter = (key) => {
		setIsHover(key);
	 };

	return (
		<div className="wrapper">
		<div className={layout == "grid" ? storeStyle.sotresContainer : storeStyle.sotresContainerList } >
		{ loading ? <SimpleLoading/> :(
			currentItems?.map((item,key)=>{
			return(
				<div className={storeStyle.card} key={key}>
					<NavLink to={`/seller-shop/${item.slug}`} className="text-decoration-none">
						<div className={storeStyle.image}>
							<img
								className="card-img-top"
								src={item.logo}
								alt={item.name}
							/>
						</div>
					</NavLink>
					<div className={storeStyle.cardBody}>
						<div>
							<NavLink to={`/seller-shop/${item.slug}`} className="text-decoration-none">
								<h5 className="card-title">{item.name}</h5>
							</NavLink>

							<p className={`d-lg-flex ${storeStyle.rattings}`}>
								<span className="me-2">
									<FaStar /> <FaStar /> <FaStar />
									<FaStar /> <FaStar />
								</span>
								<span>( {item.total_review} {t('customers')} {t('review')})</span>
							</p>
							<p className={`d-lg-flex ${storeStyle.location}`}>
								<span>
									<MdOutlineLocationCity />
								</span>
								{item.address}
							</p>
						</div>
						<div className={storeStyle.visitBtn} >
							<NavLink 
							to={`/seller-shop/${item.slug}`}
							style={{
								backgroundColor: isHover == key ? bg_Hover() : getTemplates().primary_color,
								borderColor: isHover == key ? bg_Hover() : '#fff',
								color:'#fff'
							}}
							onMouseEnter={ (e)=>{handleMouseEnter(key)}}
							onMouseLeave={(e)=>handleMouseEnter(key)}
							  className="btn btn-primary mb-4">
								<IoStorefrontOutline /> {t('visit')} {t('store')} 
							</NavLink>
						</div>
					</div>
				</div>
				)
			})
		)}
		</div>
		</div>
	);
};

export default StoreList;
