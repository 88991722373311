import TopBar from "./component/topBar/TopBar";
import MainHeader from "./component/mainHeader/MainHeader";
import HeaderMenu from "./component/headerMenu/HeaderMenu";
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';

const HeaderOne = ({
  categories,
  headerLogo,
  headerIconLogo,
  languageSwitcher,
  currencySwitcher,
  user,
  language,
  filterCategories,
  phone
}) => {
  return (
	<div className="header-wrapper">
		<TopBar/>
		<MainHeader headerLogo={headerLogo} filterCategories={filterCategories}/>
		<HeaderMenu phone={phone} categories={categories}/>
	</div>
  );
};

export default HeaderOne;
