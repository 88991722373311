import { NavLink } from "react-router-dom";
import { priceFormat } from "../../../../hooks/helper";
import { useTranslation } from "react-i18next";

const ProductTable = ({products,thankStyle,order,coupon}) => {
	const { t,i18n } = useTranslation();

  return (
	<div className={thankStyle.orderDetails}>
		<h1>{t('order')+` `+ t('details') }</h1>
		<table>
		<thead>
			<tr>
			<th>{t('product')}</th>
			<th>{t('total')}</th>
			</tr>
		</thead>
		<tbody>
      {
        products.length >0 &&
        products.map((product,key)=>{
          return(
            <tr key={key}>
              <td>
			  	<NavLink to={`/products/${product.product_slug}`}>
					<span className="text-danger">{product.product_name} </span>{" "}
					<span> x {product.quantity} </span>
					{/* Variation */}
					{ product.variation !=="" && <div>{product.variation}</div> }
				</NavLink>
              </td>
              <td>{priceFormat(product.price,i18n.language)}</td>
          </tr>
          )
        })
      }

			<tr>
				<td>
				<h6
				style={{
					fontWeight: "600",
				}}
				>
				{t('subtotal')}:
				</h6>
				</td>
				<td>{priceFormat(order.subtotal,i18n.language)}</td>
			</tr>
			<tr>
				<td>
					<h6
					style={{
					fontWeight: "600",
					}}
					>
					{t('tax')}
					</h6>
				</td>

				<td>
				<h6
				style={{
					fontWeight: "600",
				}}
				>
				{priceFormat(order.tax)}
				</h6>
				</td>
			</tr>
			{
				coupon !== "0.00" &&
				<tr>
					<td>
						<h6
						style={{
						fontWeight: "600",
						}}
						>
						{t('coupon_discount')}
						</h6>
					</td>

					<td>
					<h6
					style={{
						fontWeight: "600",
					}}
					>
					{priceFormat(coupon,i18n.language)}
					</h6>
					</td>
				</tr>

			}

			<tr>
				<td>
					<h6
					style={{
					fontWeight: "600",
					}}
					>
					{t('total')}
					</h6>
				</td>

				<td>
				<h6
				style={{
					fontWeight: "600",
				}}
				>
				{priceFormat(order.grand_total,i18n.language)}
				</h6>
				</td>
			</tr>
		</tbody>
		</table>
	</div>
  );
};

export default ProductTable;