import "./heroArea.css";
import { heroImage_1 } from "../../../../../../assets";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import './slider-animations.css';
import { NavLink } from "react-router-dom";

const HeroArea = ({sliders}) => {
	function slideStyle(index) {

		let $style={bg:"",title:"",first_line:"",second_line:""};
		if (index == 0 ) {
			$style.bg0 = {'--slider1-bg': '#7800F1'}
			$style.title0 = {'--slider1-title': '#FFC601'}
			$style.first_line0 = {'--slider1-first_line': '#fff'}
			$style.second_line0 = {'--slider1-second_line': '#fff'}
		} 
		if (index == 1 ) {
			$style.bg1 = {'--slider1-bg': '#F8F8F8'}
			$style.title1 = {'--slider1-title': '#4CAF50'}
			$style.first_line1 = {'--slider1-first_line': '#092636'}
			$style.second_line1 = {'--slider1-second_line': '#092636'}
		} 
		else {
			$style.bg2 = {'--slider1-bg': '#FFF8F6'}
			$style.title2 = {'--slider1-title': '#4CAF50'}
			$style.first_line2 = {'--slider1-first_line': '#092636'}
			$style.second_line2 = {'--slider1-second_line': '#092636'}
		}

		return $style;
	}

	return (
		<Slider autoplay={1000}>
			{ sliders && sliders.map((item, index) => (
				<div className="hero-area-wrapper"  key={index} style={slideStyle(index)[`bg${index}`]}>
					<div className="row">
						<div className="col-lg-6 col-sm-12">
							<div className="hero-content-area inner">
								<h4 className="hero-subtitle" style={slideStyle(index)[`title${index}`]}>{item?.title}</h4>
								<h2 className="title" style={slideStyle(index)[`first_line${index}`]} >{item?.first_line}</h2>
								<p className="description" style={slideStyle(index)[`second_line${index}`]} >{item?.second_line}</p>
								<NavLink to={item?.button_url} className="shop-btn">
									{item?.button_text}
									<i
										className="fa fa-angle-right"
										aria-hidden="true"
									></i>
								</NavLink>
							</div>
						</div>
						<div className="col-lg-6 col-sm-12">
							<div className="hero-image-area">
								<img src={item.image} alt={item?.title} />
							</div>
						</div>
					</div>
				</div>
			))}
		</Slider>
	);
};

export default HeroArea;
