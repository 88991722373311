import React from "react";
import ReactPaginate from "react-paginate";
import {getTemplates,pagination_bg_Color} from "../../../hooks/templates"
import {
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
} from "react-icons/ai";
import paginationStyle from "./frontendPagination.module.css";

const FrontendPagination = ({
  currentItem,
  perPage,
  pageCount,
  currentPage,
  setCurrentPage,
  setReloading,
}) => {
	
  return (
    <>
      {currentItem.length > 0 && (
        <div className={paginationStyle.paginationContainer} style={{ '--active_bg_color': `${getTemplates().primary_color}`,
		 '--bg_color': `${pagination_bg_Color().color}`, '--btn_color': `${pagination_bg_Color().color}` }}>
          <ReactPaginate
            previousLinkClassName={`${paginationStyle.prevBtn}`  }
            nextLinkClassName={paginationStyle.nextBtn}
            activeClassName={paginationStyle.activeBtn}
            breakLabel="..."
            nextLabel={<AiOutlineDoubleRight />}
            previousLabel={<AiOutlineDoubleLeft />}
            marginPagesDisplayed={2}
            onPageChange={(e) => {
              setCurrentPage(e.selected + 1);
              setReloading && setReloading((pre) => !pre);
            }}
            pageRangeDisplayed={perPage}
            pageCount={Math.ceil(pageCount)}
            containerClassName="pagination"
            pageClassName="page__count"
            activeLinkClassName="active"
            forcePage={currentPage - 1}
          />
        </div>
      )}
    </>
  );
};

export default FrontendPagination;
