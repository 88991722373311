import { useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import FrontendPagination from "../../../../../common/pagination/frontend/FrontendPagination";
import SortBar from "./components/SortBar";
import GridView from "../../../../../common/product/prodsLayout/GridView";
import ListView from "../../../../../common/product/prodsLayout/ListView";
import GridOne from "../../../../../common/product/prodsCard/GridOne";

const RightComp = ({
  loading,
  shopProduct,
  pageCount,
  currentPage,
  setCurrentPage,
  setSortBy,
}) => {
  const [layout, setLayout] = useState("grid");

  function displayComponent() {
	if (process.env.REACT_APP_MARKETPLACE_TEMPLATE == 1) {
		{
			return shopProduct &&
			shopProduct?.map((item,key)=>{
				return <div className="col-lg-4 col-sm-12 mb-3" key={key}><GridOne product={item}/></div>
			})
		}
	}
	else{
		return layout === "grid" ? ( <GridView products={shopProduct}/> ) : ( <ListView products={shopProduct}/> )
	}
}

  return (
    <div>
      <SortBar setLayout={setLayout} setSortBy={setSortBy} />
      <div className="row products-wrap">
        {loading && (
          <SkeletonTheme height={50}>
            <p>
              <Skeleton count={5} />
            </p>
          </SkeletonTheme>
        )}
        
		{displayComponent()}

        {shopProduct?.length > 0 && (
          <FrontendPagination
            currentItem={shopProduct}
            perPage={12}
            pageCount={pageCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </div>
  );
};

export default RightComp;
