import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import modalStyle from "./modal.module.css";
import Timeline from "../timeline/Timeline";
import { useState } from "react";
import { priceFormat } from "../../../../../../hooks/helper";
import PdfModal from "../../../../../../common/pdfModal/PdfModal";
import { printDiv } from "../../../../../../common/print/printDiv";
import customerAuthHeader from "../../../../../services/customer-auth-header";
import { markutosFrontendApi } from "../../../../../services/Api/api";
import { useTranslation } from "react-i18next";
import { getTemplates,bg_Hover } from "../../../../../../hooks/templates";

const OrderModal = ({ page, show, setShow, orderId, time }) => {
  const { t , i18n } = useTranslation();

  const orderOptions = [
    { value: "confirmed", label: t('confirmed') },
    { value: "processing", label: t('processing') },
    { value: "completed", label: t('completed') },
    { value: "cancelled", label: t('cancelled') },
    { value: "pending_payment", label: t('pending_payment') },
    { value: "failed", label: t('failed')  },
    { value: "draft", label: t('Draft') },
  ];

  const [orderDetails, setOrderDetails] = useState({});
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [pdfShow, setPdfShow] = useState(false);


  useEffect(() => {
    if (page == "order" && orderId) {
      markutosFrontendApi
        .get(`dashboard/my-orders/details?order_id=${orderId}`, {
          headers: {
            Authorization: customerAuthHeader(),
          },
        })
        .then((res) => {
          setOrderDetails(res.data);
          setDeliveryStatus(res.data.order.delivery_status);
          setOrderStatus(res.data.order.order_status);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, [orderId, time]);

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName={modalStyle.orderModalWidth}
        centered
        scrollable={true}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {t('order_id')}: {orderDetails?.order?.id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section>
            <Timeline />
            {page == "order" ? (
              <div className={modalStyle.statusDropdown}>
                <div>
                  <label>{t('order')} {t('status')}</label>
                  {orderStatus}
                </div>
								<div>
                  <label>{t('delivery')} {t('status')}</label>
                  {deliveryStatus}
                </div>
                <button
                  onClick={() => printDiv("print_area")}
                  className="btn btn-outline-primary"
                  style={{
                    "--bs-btn-hover-bg" :  bg_Hover(),
                    "--bs-btn-border-color" :  getTemplates().primary_color,
                    "--bs-btn-color" :  getTemplates().primary_color,
                    "--bs-btn-hover-border-color" :  getTemplates().primary_color,
                    }}
                >
                  {t('print')} {t('invoice')} 
                </button>
                <PdfModal show={pdfShow} setShow={setPdfShow} />
              </div>
            ) : (
              ""
            )}
          </section>
          <div id="print_area">
            <section>
              <div className={modalStyle.orderSummary}>
                <h6>{t('order')} {t('status')}</h6>

                <div className={modalStyle.orderBody}>
                  <Row>
                    <Col xs="5" md="2">
                      <h6>{t('order')} {t('id_no')}:</h6>
                      <h6>{t('customer')}:</h6>
                      <h6>{t('email')}:</h6>
                    </Col>
                    <Col xs="7" md="5">
                      <h5>{orderDetails?.order?.id} </h5>
                      <h5>{orderDetails?.order?.customer_name}</h5>
                      <h5>{orderDetails?.order?.customer_email}</h5>
                    </Col>
										<h6>{t('ship_address')}:</h6>
										<Col xs="5" md="2">
                      <h6>{t('name')}:</h6>
                      <h6>{t('address')}:</h6>
                      <h6>{t('phone')}:</h6>
                      <h6>{t('email')}:</h6>
                    </Col>
										<Col xs="7" md="5">
                      <h5>{orderDetails?.order?.shipping_address?.name}</h5>
                      <h5>{orderDetails?.order?.shipping_address?.address}</h5>
                      <h5>{orderDetails?.order?.shipping_address?.phone}</h5>
                      <h5>{orderDetails?.order?.shipping_address?.email}</h5>
                    </Col>
                    <Col xs="7" md="2">
                      <h6>{t('order')} {t('date')}:</h6>
                      <h6>{t('order')} {t('status')}:</h6>
                      <h6>{t('total_ord_amn')}:</h6>
                      <h6>{t('shipping_type')}:</h6>
                      <h6>{t('shipping_method')}:</h6>
                      <h6>{t('payment_method')}:</h6>
                    </Col>
                    <Col xs="5" md="3">
                      <h5>{orderDetails?.order?.created_at}</h5>
                      <h5> {orderDetails?.order?.order_status} </h5>
                      <h5>{orderDetails?.order?.grand_total} </h5>
                      <h5>{orderDetails?.order?.shipping_type}</h5>
                      <h5>{orderDetails?.order?.shipping_method}</h5>
                      <h5>{orderDetails?.order?.payment_type}</h5>
                    </Col>
                  </Row>
                </div>
              </div>
            </section>
            <section>
              <div className="row mt-5">
                <div className="col-12 col-md-9">
                  <div className={modalStyle.orderSummary}>
                    <h6>{t('order')} {t('details')} </h6>

                    <div className={modalStyle.orderBody}>
                      <Table borderless responsive>
                        <thead>
                          <tr className="mb-5">
                            <th>#</th>
                            <th>{t('product')}</th>
                            <th>{t('quantity')}</th>
                            <th>{t('price')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderDetails?.order_details?.length > 0 &&
                            orderDetails.order_details.map((item) => {
                              return (
                                <tr key={item.id}>
                                  <td> {item.id} </td>
                                  <td>{item.product_name} </td>
                                  <td>{item.quantity} </td>
                                  <td>{item.price} </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3 mt-4 mt-md-0">
                  <div className={modalStyle.orderSummary}>
                    <h6>{t('order')} {t('ammount')}</h6>

                    <div className={modalStyle.orderBody}>
                      <Row>
                        <Col>
                          <h6>{t('subtotal')}</h6>
                        </Col>
                        <Col>
                          <h5> {priceFormat(orderDetails?.sub_total,i18n.language)}</h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h6>{t('shipping')}</h6>
                        </Col>
                        <Col>
                          <h5> {priceFormat(orderDetails?.shipping)} </h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h6>{t('tax')}</h6>
                        </Col>
                        <Col>
                          <h5> {priceFormat(orderDetails?.tax,i18n.language)} </h5>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <h6>{t('coupon')}</h6>
                        </Col>
                        <Col>
                          <h5> {priceFormat(orderDetails?.coupon,i18n.language)} </h5>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <h6>{t('total')}</h6>
                        </Col>
                        <Col>
                          <h5> {priceFormat(orderDetails?.total,i18n.language)} </h5>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OrderModal;
