
import { BsGrid3X3Gap } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import {useState} from "react";
import { AiOutlineBars } from "react-icons/ai";
import { getTemplates,bg_Hover } from "../../../../../../hooks/templates";

const SortBar = ({setLayout,setSortBy}) => {
	const { t } = useTranslation();
	const [isHover, setIsHover] = useState(-1);
	const handleMouseEnter = (key) => {
		setIsHover(key);
	 };
	 const handleMouseLeave = () => {
		setIsHover(-1);
	 };
	return (
			<div className="sort-bar">
				<div className="sort-bar-left">
					<div>
						<button onClick={() => setLayout("grid")}
							style={{
								background: isHover == 0 ? bg_Hover() : '#fff',
								borderColor: isHover == 0 ? bg_Hover() : getTemplates().primary_color,
								color: isHover == 0 ? '#fff' : getTemplates().primary_color,
							}}
							onMouseEnter={ (e)=>{handleMouseEnter(0)}}
							onMouseLeave={(e)=>handleMouseLeave()}
						>
							<BsGrid3X3Gap />
						</button>
					</div>
					<div>
						<button onClick={() => setLayout("list")}
							style={{
								background: isHover == 1 ? bg_Hover() : '#fff',
								borderColor: isHover == 1 ? bg_Hover() : getTemplates().primary_color,
								color: isHover == 1 ? '#fff' : getTemplates().primary_color,
							}}
							onMouseEnter={ (e)=>{handleMouseEnter(1)}}
							onMouseLeave={(e)=>handleMouseLeave()}
						>
							<AiOutlineBars />
						</button>
					</div>
				</div>
				<div className="sort-bar-right">
					<div className="form-group">
						<label htmlFor="">{t('sort_by')}:</label>
						<select className="form-control" onChange={(e)=>setSortBy(e.target.value)} name="sort">
							<option>{t('default')}</option>
							<option>{t('sale')}</option>
							<option>{t('price')}</option>
						</select>
					</div>
				</div>
			</div>
	);
};

export default SortBar;
