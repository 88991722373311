import React, { useState } from "react";
import { BsCart2, BsStar, BsStarFill } from "react-icons/bs";
import { GiUnbalanced } from "react-icons/gi";
import { IoMdHeartEmpty, IoMdHeart } from "react-icons/io";
import { IoEyeOutline } from "react-icons/io5";
import Rating from "react-rating";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCompare } from "../../../redux/slices/compare";
import { setCustomerWishlist } from "../../../redux/slices/wishlist";
import { priceFormat } from "../../../hooks/helper";
import QuickView from "../../quickView/QuickView";
import { setQuickView } from "../../../redux/slices/quickView";
import { useCart } from "react-use-cart";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const GridOne = ({ product }) => {

	const { t,i18n } = useTranslation();
	const dispatch = useDispatch();
	const { products: wishlistedProds } = useSelector(
	  (state) => state?.customerWishlist
	);
	const [openQuickView, setOpenQuickView] = useState(false);
  
	const { products: comparedProds } = useSelector((state) => state?.compare );
	const { addItem } = useCart();
	const handleAddToCart = () => {
	  // add to cart
	  if (product.product_type !== "variation") {
		addItem(product);
	  } else {
		dispatch(setQuickView({ open: true, product }));
	  }
	};
  
	const handleQuickView = () => {
	  dispatch(setQuickView({ open: true, product }));
	};
  
	const isWishlistedProd = () => {
	  return wishlistedProds?.some((wp) => {
		return wp.id === product.id;
	  });
	};
  
	const addToWishlist = () => {
	  let newWishlistedProds = [];
	  if (isWishlistedProd(product)) {
		newWishlistedProds = wishlistedProds?.filter(
		  (wp) => wp.id !== product.id
		);
	  } else {
		newWishlistedProds = [...wishlistedProds, product];
	  }
	  dispatch(
		setCustomerWishlist({
		  count: newWishlistedProds.length,
		  products: newWishlistedProds,
		})
	  );
	  localStorage.setItem("my-wishlist", JSON.stringify(newWishlistedProds));
	};
  
	const isComparedProd = () => {
	  return comparedProds?.some((cp) => {
		return cp.id === product.id;
	  });
	};
  
	const addToCompare = () => {
	  if (isComparedProd()) {
		dispatch(setCompare({ open: true, products: comparedProds }));
	  } else {
		let newCompareProds = [...comparedProds];
		if (comparedProds.length === 4) {
		  newCompareProds.shift();
		}
		dispatch(
		  setCompare({ open: true, products: [...newCompareProds, product] })
		);
		localStorage.setItem(
		  "compared-prods",
		  JSON.stringify([...newCompareProds, product])
		);
	  }
	};

	return (
        <div className="single-product-block" key={product?.id}>
            <div className="product-thumbnail">
                <div className="product-img">
					<NavLink to={`/products/${product.slug}`}>
                        <img style={{ width:"148px" }} src={product.thumbnail_img} alt={product?.name} />
                    </NavLink>
                </div>
                <div className="sale-and-wishlist">
                    <span className="offer-badge sale">sale</span>
                    <div className="wishlist" onClick={addToWishlist}>
						{isWishlistedProd() ? <IoMdHeart /> : <IoMdHeartEmpty />}
                    </div>
                </div>
            </div>
            <div className="product-content">
                <div className="product-cat">
                    <NavLink to={product?.category ? `/shop/${product.category[0]}` : `#`}>{product?.category[0]}</NavLink>
                </div>
                <h3 className="product-title">
                    <NavLink to={`/products/${product.slug}`}>{product?.name}</NavLink>
                </h3>
                <div className="price-and-rating">
                    <div className="price">
                        {product.product_type !== "variation" ? (
                            <div>
                                <p className="offer-price">{priceFormat(product.discount_price,'en')}</p>
                                <span className="main-price">
                                    <del>
                                        {priceFormat(product.unit_price,'en')}
                                    </del>
                                </span>
                            </div>
                        ) : (
                            <span className="main-price">{priceFormat(product.discount_price_range, 'en' ,"variation")}</span>
                        )}
                    </div>
                    <Rating
                        fullSymbol={<BsStarFill className="icon" color="#ffc601" />}
                        emptySymbol={<BsStar className="icon" />}
                        initialRating={product?.avg_rating}
                        readonly
                    />
                </div>
				{/* Footer Section */}
				<ul className="hover-item">
					<li className="quick-display"  onClick={handleQuickView}>
						<a><IoEyeOutline /></a>
					</li>
					<li className="cart-button"  onClick={handleAddToCart}>
						<a className="add-to-cart"><span>{t('add_to_cart')}</span></a>
					</li>
					<li className="comparison" onClick={addToCompare}>
						<a><GiUnbalanced /></a>
					</li>
				</ul>
            </div>
			{openQuickView && (
				<QuickView product={product} onClose={() => setOpenQuickView(false)} />
			)}
        </div>
    );
}
export default GridOne;
