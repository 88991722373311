import React from "react";
import { getTemplates } from "../../../../../hooks/templates";
import blogStyle from "./blogs.module.css";
import { useTranslation } from "react-i18next";

const BlogCard = (props) => {
	const { t } = useTranslation();

  return (
    <div className={blogStyle.BlogCardContainer}>
      <div>
        <img src={props.item?.banner} alt={props.item?.title} />
      </div>
      <div>
        <h3>{props.item?.title}</h3>
        <div className={blogStyle.blogMeta}>
          <span
            style={{
              opacity: "0.6",
            }}
          >
            {t('by')}
          </span>
          <span
			style={{
				color:getTemplates().primary_color
			}}
		  > {props.item?.author} </span>
          <span
            style={{
              fontWeight: "600",
            }}
          >
            in {props.item?.category?.map((item,key)=>{ return( <span key={key}>{item}</span>) }) }
          </span>
          <span
            style={{
              opacity: "0.6",
              marginLeft: "5px",
            }}
          >
            on { props.item?.created_date }
          </span>
        </div>
        <p className="blog-desc" style={{
            fontSize: "1rem",
            textAlign: "justify",
          }}
        >
		  <div dangerouslySetInnerHTML={{__html: props.item?.short_description }}></div>
        </p>
      </div>
    </div>
  );
};

export default BlogCard;
