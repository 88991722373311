import FooterOne from "./template/template1/FooterOne";
import FooterTwo from "./template/template2/FooterTwo";

const Footer = ({ footerData, user }) => {
  return (
	process.env.REACT_APP_MARKETPLACE_TEMPLATE == 1 ?
    <FooterOne footerData={footerData} user={user} /> : <FooterTwo footerData={footerData} user={user}/>
  );
};

export default Footer;
