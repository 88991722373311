import { Fragment, useEffect,useState } from "react";
import PdfModal from "../../../common/pdfModal/PdfModal";
import thankStyle from "./thanks.module.css";
import { useCart } from "react-use-cart";
import { useLocation } from 'react-router-dom';
import SimpleLoading from "../../../common/loading/SimpleLoading";
import { priceFormat } from "../../../hooks/helper";
import ProductTable from "./components/ProductTable";
import ShippingInfo from "./components/ShippingInfo";
import { useTranslation } from "react-i18next";

const ThankYou = () => {
  const { t,i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [shippingAddress, setShippingAddress] = useState("");
  const [shippingCharges, setShippingCharges] = useState({shipping_charge:"",shipping_method:""});
  const [coupon, setCoupon] = useState("0.00");
  const {emptyCart,clearCartMetadata} = useCart();
  const { state } = useLocation();

  useEffect(() => {
      emptyCart();
      clearCartMetadata();
      setOrderData(state.packages);
      setShippingAddress(state.shipping_address);
      setShippingCharges({ shipping_charge:state?.shipping_charge , shipping_method : state?.shipping_method });
      setCoupon(state.coupon_discount);
  }, [state]);
  
  return (
      <div className={`container ${thankStyle.thankContainer} `}>
          <h6>{t('thank_ord')}</h6>
          <section>
          {
              Object.keys(orderData).length !== 0  ?
              (
                  Object.keys(orderData).map((item,key)=>{
                      return (
                          <Fragment key={key}>
                            <Fragment>
                              <div className={thankStyle.topData}>
                                <div className={thankStyle.topDataItem}>
                                  <h6>{t('shop_name')}</h6>
                                  <h5> {orderData[item].shop_name}</h5>
                                </div>
                                <div className={thankStyle.topDataItem}>
                                  <h6>{t('order_no')}</h6>
                                  <h5> {orderData[item].id} </h5>
                                </div>

                                <div className={thankStyle.topDataItem}>
                                  <h6>{t('date')}</h6>
                                  <h5> {orderData[item].created_at}</h5>
                                </div>
                                <div className={thankStyle.topDataItem}>
                                  <h6>{t('total')}</h6>
                                  <h5> {priceFormat(orderData[item].grand_total,i18n.language)}</h5>
                                </div>

                                <div className={thankStyle.topDataItem}>
                                  <h6>{t('payment_method')}</h6>
                                  <h5> {orderData[item].payment_type}</h5>
                                </div>
                                <div className={thankStyle.topDataItem}>
                                  <h6>{t('order_status')}</h6>
                                  <h5> {orderData[item].order_status}</h5>
                                </div>
                                <div className={thankStyle.topDataItem}>
                                  <h6>{t('payment_status')}</h6>
                                  <h5> {orderData[item].payment_status}</h5>
                                </div>
                                <div className={thankStyle.topDataItem}>
                                  <h6>{t('delivery_status')}</h6>
                                  <h5> {orderData[item].delivery_status}</h5>
                                </div>
                                {/* <div className={thankStyle.topDataItem}>
                                  <h6>Shipping Type</h6>
                                  <h5> {orderData[item].shipping_type}</h5>
                                </div> */}
                                {/* <div className={thankStyle.topDataItem}>
                                  <h6>Shipping Method</h6>
                                  <h5> {orderData[item].shipping_method}</h5>
                                </div> */}
                              </div>
                              { orderData[item].payment_type == "cod" && <h6 className="mt-4">Pay with cash upon delivery.</h6> }
                            </Fragment>
                            <Fragment>
                              {/* Product Table */}
                              <ProductTable products={orderData[item].order_details} order={orderData[item]} coupon={coupon} thankStyle={thankStyle}/>
                            </Fragment>
                          </Fragment>
                      )
                  })
              ) : (<SimpleLoading/>)
          }
          </section>

          {/* Shipping information */}
          <ShippingInfo 
           thankStyle={thankStyle}
           shippingAddress={shippingAddress}
           shippingCharges={shippingCharges}
          />

          <section>
            <button
                onClick={() => setShow(!show)}
                className="btn btn-primary mb-5">{t('print')}</button>

            <PdfModal com={<ThankYou />} show={show} setShow={setShow} />
          </section>
      </div>
  );
};

export default ThankYou;
