import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HtmlMarkup from "../../../../../htmlMarkup/HtmlMarkup";

const Categories = ({categories}) => {
	const { t } = useTranslation();

	return (
		<div className="category-menu">
			<nav className="navbar navbar-expand-lg">
				<ul className="navbar-nav mr-auto">
					<li className="nav-item dropdown">
						<NavLink className="nav-link" to="/">
							<i className="fa fa-bars" aria-hidden="true"></i>
							<span className="pl-2">{t('all') +" "+ t('categories')}</span>
						</NavLink>
						<ul className="dropdown-menu">
							{categories.length > 0 &&
							categories.map((item,key)=>{
							return(
								<li  className="temp-1-cat-list" key={key}>
									<NavLink className="nav-link" to={`/shop/${item.slug}`}>
										<HtmlMarkup iconData={item.category_icon}/><span className="cat_name">{item.name}</span>
									</NavLink>
									{/* { item?.sub_category.map((sub,i)=>{
										return(
											<ul className="dropdown-menu" key={i}>
												<li className="nav-item">
													<NavLink className="nav-link" to={`/shop/${sub.slug}`}>{sub.name}</NavLink>
												</li>
											</ul>
										)
									})} */}
								</li>
							)
							}) }
						</ul>
					</li>
				</ul>
			</nav>
		</div>
	);
};

export default Categories;
