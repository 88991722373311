import { useEffect, useState } from "react";
import { markutosFrontendApi } from "../../../services/Api/api";
import "react-loading-skeleton/dist/skeleton.css";
import Ads from "./components/ads/Ads";
import HeroArea from "./components/heroArea/heroArea";
import BestSellingProducts from "./components/bestSellingProducts/BestSellingProducts";
import SellerOfMonth from "./components/sellerOfMonth/SellerOfMonth";
import MoreProducts from "./components/moreProducts/MoreProducts";
import PopularCategories from "./components/popularCategories/PopularCategories";
import Stores from "./components/stores/stores";


const TemplateOne = () => {
  const [loading, setLoading] = useState(false);
  const [sliders, setSliders] = useState([]);
  const [categories, setCategories] = useState([]);
  const [pinnedCategories, setPinnedCategories] = useState([]);
  const [popularProducts, setPopularProducts] = useState([]);
  const [dailyDeals, setDailyDeals] = useState([]);
  const [featureProducts, setFeatureProducts] = useState([]);
  const [shops, setShops] = useState([]);
  const [brands, setBrands] = useState([]);
  const [banners, setBanners] = useState([]);

  const getData=()=>{
    var item_value = sessionStorage.getItem("MARCATUEX_HOME");
    if ( item_value !== null ) {
      const temp = JSON.parse(sessionStorage.getItem("MARCATUEX_HOME") ) || [] ;
        setCategories(temp?.categories);
        setPinnedCategories(temp?.pinned_categories);
        setPopularProducts(temp?.popular_products);
        setDailyDeals(temp?.daily_deal_products);
        setFeatureProducts(temp?.featured_products);
        setShops(temp?.shops);
        setBrands(temp?.brands);
        setBanners(temp?.banners);
        setSliders(temp?.sliders);
    } else {
      setLoading(true);
      markutosFrontendApi.get(`/index`).then((response) => {
        sessionStorage.setItem("MARCATUEX_HOME",JSON.stringify(response?.data));
        setLoading(false);
        setCategories(response?.data.categories);
        setPinnedCategories(response?.data.pinned_categories);
        setPopularProducts(response?.data.popular_products);
        setDailyDeals(response?.data.daily_deal_products);
        setFeatureProducts(response?.data.featured_products);
        setShops(response?.data.shops);
        setBrands(response?.data.brands);
        setBanners(response?.data.banners);
        setSliders(response?.data.sliders);
      });
    }

  }

  useEffect(() => {
    window.onload = function() {
      sessionStorage.removeItem("MARCATUEX_HOME");
    };
    getData();
  }, []);

  return (
		<section className="container main-content">
        <HeroArea sliders={sliders} />
        <PopularCategories pinnedCategories={pinnedCategories}/>
        <BestSellingProducts 
          bannerOne={ banners && banners[0]}
          popularProducts={popularProducts}
        />
        <Stores shops={shops}/>
        <MoreProducts 
          bannerTwo={ banners && banners[1]}
          dailyDeals={dailyDeals}
        />
        <Ads
        bannerThree={ banners && banners[2]}
        bannerFour={ banners && banners[3]}
        />
        <SellerOfMonth featureProducts={featureProducts}/>
		</section>
  );
};

export default TemplateOne;
