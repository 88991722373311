import React from "react";
import csvStyle from "./downloadCSV.module.css";
import { useTranslation } from "react-i18next";

const DownloadCSV = () => {
  const { t } = useTranslation();

  return (
    <div className={csvStyle.csvContainer}>
      <p>{t('csv_1')}</p>
      <p>{t('csv_2')}</p>
      <p>{t('csv_3')}</p>
      <p>{t('csv_4')}</p>
      {/* <button className="btn btn-primary">{t('download_csv')}</button> */}
    </div>
  );
};

export default DownloadCSV;
