import { useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import Sliders from "./Sliders";
import "./homepageSettings.css";
import { toast } from "react-toastify";
import { markutosSellerApi } from "../../../services/Api/api";
import { useTranslation } from "react-i18next";

const HomepageSettings = () => {
	const { t  } = useTranslation();
  const initialSlider= {
    "sliders": [
        {
          "title": "Sequi voluptatibus soluta nobis doloribus.",
          "link": "/",
          "image": "",
        }
    ]
  }
  const [loading, setLoading] = useState(false);
  const [adding, setAdding] = useState(false);
  const [defaultValues, setDefaultValues] = useState(initialSlider);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    errors,
    reset,
    append,
    setValue,
  } = useForm({
    defaultValues,
  });


// effect runs when user state is updated
useEffect(() => {
  if ( adding == false) {
    // reset form with user data
    reset(defaultValues);
  }
}, [defaultValues,adding]);

 const getSliders = ()=>{
    markutosSellerApi()
	.get(`/sliders`)
    .then((response) => {
      setLoading(false);
      if ( response?.data.length > 0 ) {
        setDefaultValues({sliders: [...response?.data]});
      } else {
        setDefaultValues(initialSlider);
      }
    });
  }

  useEffect(() => {
    if ( adding == false) {
      setLoading(true);
      getSliders();
    }
  }, [adding]);


  const onSubmit = (data) =>{
    setLoading(true)
    markutosSellerApi()
	.post(`/sliders/update-slider`, {sliders: data?.sliders} )
    .then((res) => {
      getSliders();
      toast.success(res.data.message);
    })
    .catch((e) => {
      setLoading(false)
        toast.error(e.message);
    });
  }


  return (
    <div className="single-widget m-3">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Sliders
          {...{
            control,
            register,
            defaultValues,
            append,
            getValues,
            setValue,
            errors,
			      reset,
            setAdding,
            adding
          }}
        />
        <input type="submit" value={t('save_changes')} />
      </form>
    </div>
  );
};

export default HomepageSettings;
