import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import Footer from "../../../../common/footer/Footer";
import Header from "../../../../common/header/Header";
import { markutosFrontendApi } from "../../../../pages/services/Api/api";
import { setAdditionalPrice } from "../../../../redux/slices/additionalPrice";
import { setAuth } from "../../../../redux/slices/auth";
import { store } from "../../../../redux/store/store";
import { loadFromLocalStorage } from "../../../../utils/user/manageLocalStorage";

const CustomerLayout = () => {
  const [currentItems, setCurrentItems] = useState([]);
  const user = loadFromLocalStorage();
  const dispatch = useDispatch();

  useEffect(() => {
    markutosFrontendApi.get(`/settings`).then((response) => {
      store.dispatch(
        setAdditionalPrice(
        { 
          paymentMethods: response?.data?.payment_methods || [] ,
          tax: response?.data?.tax || 0 ,
          shipping_methods: response?.data?.shipping_methods || 'free_shipping' ,
          shipping_charge: response?.data?.shipping_charge || 0 ,
          stripe_public_key: response?.data?.stripe_public_key || 0 ,
        })
      );
      setCurrentItems(response?.data);
    });
    dispatch(
      setAuth({
        isLoggedin: user ? true : false,
        user,
      })
    );
  }, []);

  return (
    <div className="wrapper">
      <Header
        user={user}
        categories={currentItems?.categories ? currentItems.categories : []}
        languageSwitcher={
          currentItems?.language_switcher ? currentItems.language_switcher : 1
        }
        currencySwitcher={
          currentItems?.currency_switcher ? currentItems.currency_switcher : 1
        }
        headerLogo={currentItems?.header_logo ? currentItems.header_logo : ""}
        headerIconLogo={currentItems?.header_icon_logo ? currentItems.header_icon_logo : ""}
        language={currentItems?.language || "en"}
        filterCategories={currentItems?.filter_categories || []}
        marketplaceName={currentItems?.marketplace_name || ""}
        phone={currentItems?.phone || ""}
      />
      <Outlet/>
      <Footer user={user} footerData={currentItems} />
    </div>
  );
};

export default CustomerLayout;
