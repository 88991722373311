import { AiOutlineSearch } from "react-icons/ai";
import { useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import { toast } from "react-toastify";
import { emailSchema } from "../../../schema/emailSchema";
import { markutosFrontendApi } from "../../../pages/services/Api/api";
import { useTranslation } from "react-i18next";

const TemplateOne = () => {
  const { t } = useTranslation();

  const formik = useFormik({
    validationSchema: emailSchema,
    initialValues: {
      email: "",
    },
    enableReinitialize: true,
    onSubmit: (values, action) => {
      markutosFrontendApi
        .post("/subscribe", values)
        .then((res) => {
          toast.success(res.data.message);
          action.resetForm();
        })
        .catch((e) => {
          toast.error(e.response?.data?.email?.[0]);
        });
    },
  });

  const {
    values,
    setErrors,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = formik;

  return (
    <div>
		<p className="content">Register now to get updates on promotions & coupons.</p>
		<form onSubmit={(e) => e.preventDefault()} className="subscribe-form">
        <FocusError formik={formik} />
        <input
          type="email"
          placeholder={`${t('email')} ${t('address')}`}
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.email && touched.email && (
          <small className="text-danger"> {errors.email} </small>
        )}
        <button
            onClick={handleSubmit}
            type="button"
            className="btn btn-primary"
          >
          <i
            className="fa fa-chevron-right"
            aria-hidden="true"
          ></i>
          </button>

		</form>
	</div>
  );
};

export default TemplateOne;
