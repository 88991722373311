import { NavLink } from "react-router-dom";

const ShopButton = ({url}) => {
	return (
		<div className="button-area">
				<NavLink to={url} className="shop-btn">
					Shop Now
					<i className="fa fa-angle-right" aria-hidden="true"></i>
				</NavLink>
			</div>
	);
}

export default ShopButton;