import { useTranslation } from "react-i18next";
import CartTotal from "../../../cart/components/cartCom/CartTotal";
import cartDeailsStyle from "./checkout.module.css";
import ShopCart from "./ShopCart";

const CartDetails = ({storesCart,cartTotal}) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className={cartDeailsStyle.cartDetails}>
        <h4 className="mb-4">{t('products')}</h4>
        {
        storesCart.length > 0 &&
			storesCart.map((items,key)=>{
				return <ShopCart items={items} key={key}/>
			})
        }
        <section>
          <div className="mb-4">
			      <h4 className="mb-4">{t('grand_total')}</h4>
            <CartTotal cartTotalTitle="GrandTotal" cartTotal={cartTotal}  />
          </div>
        </section>
      </div>
    </div>
  );
};

export default CartDetails;
