import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { markutosSellerApi } from "../../../../../services/Api/api";

const ShopPageSettings = ({
  handleChange,
  values,
  errors,
  touched,
  setFieldValue,
}) => {

	const { t  } = useTranslation();
	const [categories, setCategories] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState([]);
	
	useEffect(() => {
		markutosSellerApi()
		.get(`/get-categories`)
		.then((response) => {
			const formattedData = response.data.map((singleData) => {
			return { value: singleData.id, label: singleData.name };
			});
			formattedData[0] ? setCategories(formattedData) : setCategories([]);
			setSelectedCategories(
			formattedData.filter(
				(fd) => values?.categories?.includes(fd.value) && fd
			)
			);
		});
	}, [values?.categories?.length]);

  const handleCategories = (item) => {
    setSelectedCategories(item);
    setFieldValue(
      "categories",
      item.map((i) => i.value)
    );
  };

  return (
    <div className="add-product-single-widget">
      <div className="widget-title">{t('category')} {t('sliders')}</div>
      <div className="widget-content-container">
        <div className="product-informaiton-form">
          <label htmlFor="name">
            <span>{t('categories')}</span>
            <i>*</i>
          </label>
          <div>
            <Select
              isMulti
              value={selectedCategories}
              onChange={(item) => handleCategories(item)}
              options={categories}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopPageSettings;
