import React from "react";
import WishlistCom from "./components/WishlistCom";

const Wishlist = () => {
  return (
    <>
      <WishlistCom />
    </>
  );
};

export default Wishlist;
