
import { createSlice } from "@reduxjs/toolkit";

export const additionalPriceSlice = createSlice({
  name: "additionalPrice",
  initialState: {
    shipping_charge: null,
    tax: null,
    paymentMethods: [],
  },

  reducers: {
    setAdditionalPrice: (state, action) => {
      state.shipping_methods = action.payload.shipping_methods;
      state.shipping_charge = action.payload.shipping_charge;
      state.tax = action.payload.tax;
      state.paymentMethods = action.payload.paymentMethods;
      state.stripe_public_key = action.payload.stripe_public_key;
    },
  },
});

export const { setAdditionalPrice } = additionalPriceSlice.actions;

  